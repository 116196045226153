import {
StyledModal,
ModalTitle,
ModalContent,
ExitButton,
ButtonDiv,
ConfirmButton,
CancelButton
} from './styled';
import ExitIcon from '@material-ui/icons/Close';

const SimpleModal = (props) => {
  const handleClose = () => {
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <div className="exitModalSection">
      <StyledModal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="exit-modal-title"
        aria-describedby="exit-modal-description"
        id={props.id}
      >
              <ModalContent>
                     {props.children}
              </ModalContent>
      </StyledModal>
    </div>
  );
};

const ExitModal = ({ isOpen, onConfirm, onCancel }) => {
       return isOpen ? (
              <SimpleModal id="exitModal" open={isOpen} onClose={onCancel}>
                     <ModalTitle>
                            <h2>Leaving GM Incentive Dashboard</h2>
                            <ExitButton onClick={onCancel}>
                                   <ExitIcon />
                            </ExitButton>
                     </ModalTitle>
                     <p>Are you sure you want to navigate away from this website?<br/> GM holds no liability for content outside its website.</p>
                     <ButtonDiv>
                            <CancelButton onClick={onCancel}>Cancel</CancelButton>
                            <ConfirmButton onClick={onConfirm}>Continue</ConfirmButton>
                     </ButtonDiv>
              </SimpleModal>
       ) : null;
};
export default ExitModal;
