import styled, { css } from 'styled-components';
import { resolveStyles } from '../../../utils/styled/resolveStyles';
import { defaultButtonStyles } from '../Button/styled';

const fallback = css`
  ${defaultButtonStyles}
`;

export const StyledLink = styled.a`
  ${resolveStyles({ fallback })}
`;

export const IconWrapper = styled.div`
  margin-left: 18px;
`;
