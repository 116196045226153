import React from 'react';
import isEmpty from 'lodash.isempty';
import i from '../../../assets/icons/i.svg';
import { AnimatePresence } from 'framer-motion';
import {
  StyledTooltip,
  StyledModal,
  StyledButton,
  StyledTooltipContentItemContainer,
  StyledTooltipTitleContainer,
  StyledParagraphsContainer,
} from './styled';
import { DesignKey, BaseDesignConfig, Design } from '../../../contexts';
import { useExtendedConfig } from '../../../hooks/useExtendedConfig';
import { I18nStructure } from '../../../types';
import { useToggle } from '../../../hooks';
import { TooltipTitle } from '../../atoms/TooltipTitle';
import { TooltipParagraph } from '../../atoms/TooltipParagraph';
import { ColorMarker, ColorMarkerConfig } from '../../atoms/ColorMarker';
import { Icon } from '../../atoms/Icon';
import { InlineImage } from '../../atoms/InlineImage/InlineImage';
import { getImageSrc } from '../../../utils/images';

const DEFAULT_ICON = {
  src: i,
};

export type TooltipOwnProps = {
  modalXPosition?: number;
};
export type TooltipProps = TooltipOwnProps & {
  content: Array<[string, DesignKey]>;
  toggleOnHover?: boolean;
  icon?: {
    src?: string;
  };
};

export interface TooltipConfig extends BaseDesignConfig {
  title?: I18nStructure & { withColorMarker?: DesignKey };
  paragraphs?: {
    copy: Array<I18nStructure>;
    image?: { src?: string };
  };
}

export type TooltipContentItemProps = { source: DesignKey };
export const TooltipContentItem: React.FC<TooltipContentItemProps> = ({ source: designKey }) => {
  const { config } = useExtendedConfig<TooltipConfig>(designKey);
  const design = config as Required<Design<TooltipConfig>>;
  const title = design?.config?.title || null;
  const colorMarkerKey = design?.config?.title?.withColorMarker || '';
  const paragraphs = (design?.config?.paragraphs?.copy as Array<I18nStructure>) || [];
  const image = design?.config?.paragraphs?.image;
  const { config: colorMarkerConfig } = useExtendedConfig<ColorMarkerConfig>(colorMarkerKey);

  if (!isEmpty(colorMarkerConfig) && isEmpty(colorMarkerConfig?.config?.color)) {
    console.warn(
      `The color is required for a color marker configuration. The config of the design by the key "${colorMarkerKey}" does not have a color property`
    );
  }

  return (
    <StyledTooltipContentItemContainer>
      {title && (
        <StyledTooltipTitleContainer>
          {colorMarkerConfig?.config?.color && (
            <ColorMarker color={colorMarkerConfig?.config?.color} />
          )}
          <TooltipTitle i18n={title.i18n} />
        </StyledTooltipTitleContainer>
      )}
      <StyledParagraphsContainer>
        {image && getImageSrc(image?.src) && (
          <InlineImage
            height="2.5rem"
            width="3.5rem"
            mr="0.5rem"
            src={getImageSrc(image?.src) as string}
            alt={title?.i18n.fallback as string}
          />
        )}
        {paragraphs?.map(({ i18n }) => (
          <TooltipParagraph key={i18n.id} i18n={i18n} />
        ))}
      </StyledParagraphsContainer>
    </StyledTooltipContentItemContainer>
  );
};
TooltipContentItem.displayName = 'TooltipContentItem';

const TRANSITION_PROPS = {
  transition: { duration: 0.4 },
};

const ANIMATION_PROPS = {
  initial: { opacity: 0 },
  exit: { opacity: 0 },
  animate: { opacity: 1 },
  ...TRANSITION_PROPS,
};

export const Tooltip: React.FC<TooltipProps> = ({
  content,
  toggleOnHover = false,
  icon = DEFAULT_ICON,
  modalXPosition,
}) => {
  const { value: isOpen, toggle } = useToggle();
  const handleMouseEnter = () => (toggleOnHover ? toggle() : () => null);
  const handleMouseLeave = () => (toggleOnHover ? toggle() : () => null);

  return (
    <StyledTooltip onMouseLeave={handleMouseLeave}>
      <StyledButton type="button" onClick={toggle} onMouseEnter={handleMouseEnter}>
        <Icon src={icon.src} />
      </StyledButton>
      <AnimatePresence>
        {isOpen && (
          <StyledModal
            {...ANIMATION_PROPS}
            style={modalXPosition ? { marginLeft: `${modalXPosition}px` } : undefined}
          >
            {content.map(([, key]) => {
              return <TooltipContentItem key={key} source={key} />;
            })}
          </StyledModal>
        )}
      </AnimatePresence>
    </StyledTooltip>
  );
};

Tooltip.displayName = 'Tooltip';
