import React from 'react';
import { IncentiveDetailsBoxProps } from '../IncentiveDetailsBox';
import { BoxBody, BoxHighlightedText } from '../../molecules/Box';

export type IncentiveDetailsBoxGrantorNameProps = Pick<IncentiveDetailsBoxProps, 'data'>;

export const IncentiveDetailsBoxGrantorName: React.FC<IncentiveDetailsBoxGrantorNameProps> = ({
  data,
}) => {
  return (
    <BoxBody>
      <BoxHighlightedText>{data?.grantor_name}</BoxHighlightedText>
    </BoxBody>
  );
};

IncentiveDetailsBoxGrantorName.displayName = 'IncentiveDetailsBoxGrantorName';
