import axios from 'axios';

const TIMEOUT = 15000;

export class Api {
  public static instance: ReturnType<typeof axios.create>;

  public static getInstance(baseURL: string, authorization: string, timeout = 1000) {
    if (Api.instance !== undefined) {
      return new Api();
    }

    Api.instance = axios.create({
      baseURL,
      timeout,
      headers: { authorization: `Bearer ${authorization}` },
    });

    return new Api();
  }

  get(path: string, params: { name: string; value: string }[] = []) {
    const paramsMap = params.reduce((acc, { name, value }) => {
      acc[name] = decodeURIComponent(value);

      return acc;
    }, {} as { [key: string]: string });

    return Api.instance.get(path, {
      params: paramsMap,
      paramsSerializer: function (params) {
        const paramsString = `${Object.entries(params)
          .map(([name, value]) => `${name}=${value}`)
          .join('&')}`;

        return paramsString;
      },
      timeout: TIMEOUT,
    });
  }
}
