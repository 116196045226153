import styled, { css } from 'styled-components';
import { ContentWrapperOwnProps } from './ContentWrapper';

export const Styled = styled.div<ContentWrapperOwnProps>`
  width: ${(11 / 12) * 100}%;
  margin: 0 auto;

  ${({ flex }) =>
    flex &&
    css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `}
`;
