import React from 'react';
import { SelectOverlay, SelectOverlayProps } from '../../molecules/SelectOverlay';
import { WithConfig } from '../../HOCs/WithConfig';
import { useTranslations } from '../../../contexts/Translations';
import { LANGUAGES_CONFIG_KEY } from '../../../constants';

export type LanguageSelectProps = SelectOverlayProps;

export const LANGUAGES_DEFAULT_CONFIG = {
  config: {
    options: [
      { id: 'en', i18n: { id: 'i18n.language.en', fallback: 'English' } },
      { id: 'fr', i18n: { id: 'i18n.language.fr', fallback: 'French' } },
    ],
  },
};

export const LanguageSelectBase: React.FC<LanguageSelectProps> = ({ ...props }) => {
  const { changeLanguage, language } = useTranslations();

  const handleChange = (id: string) => changeLanguage(id);
  const options = props.config.options.map((option) => {
    if (option.id === language) {
      return { ...option, selected: true };
    }

    return option;
  });

  return <SelectOverlay {...props} config={{ ...props.config, options }} onChange={handleChange} />;
};

export const LanguageSelect = WithConfig(
  LanguageSelectBase,
  LANGUAGES_CONFIG_KEY,
  LANGUAGES_DEFAULT_CONFIG
);

LanguageSelectBase.displayName = 'LanguageSelect';
