import { useQuery } from 'react-query';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { COMMERCIAL } from '../../../constants/utils';

interface MapData {
  score: number;
  state: string;
  state_name: string;
  number_of_incentives: {
    country: number;
    miscellaneous: number;
    Municipality: number;
    utility: number;
    state: number;
  };
}

const fetchMapData = async (
  country: string = 'US',
  type: string = 'commercial'
): Promise<MapData[]> => {
  const config = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_UNIFIED_API_TOKEN}`,
    },
  };

  let baseUrl = 'https://api.beta.zappyride.com/';
  baseUrl += type === COMMERCIAL ? 'commercial/' : '';
  baseUrl += 'incentive-summary?country=' + country + '&incentive_focus=chargers,vehicles';

  let res = await axios.get(baseUrl, config);

  return res.data.summary.sort((a: any, b: any) => a.score - b.score);
};

const useFetchMapData = () => {
  const { type, country } = useParams();
  const query = useQuery(['mapData', country, type], () => fetchMapData(country, type), {
    retry: 1,
  });

  return {
    mapData: query.data || [],
    isLoading: query.isLoading,
    error: query.error,
  };
};

export default useFetchMapData;
