import { CANADA, USA } from '../../constants/utils';

export function getUSStateCapitalZipCode(stateAbbreviation: string): string | undefined {
  const stateCapitalZipCodes: { [key: string]: string } = {
    AL: '36104', // Montgomery, Alabama
    AK: '99501', // Juneau, Alaska
    AZ: '85001', // Phoenix, Arizona
    AR: '72201', // Little Rock, Arkansas
    CA: '95814', // Sacramento, California
    CO: '80202', // Denver, Colorado
    CT: '06103', // Hartford, Connecticut
    DE: '19901', // Dover, Delaware
    FL: '32301', // Tallahassee, Florida
    GA: '30301', // Atlanta, Georgia
    HI: '96813', // Honolulu, Hawaii
    ID: '83701', // Boise, Idaho
    IL: '62701', // Springfield, Illinois
    IN: '46201', // Indianapolis, Indiana
    IA: '50301', // Des Moines, Iowa
    KS: '66601', // Topeka, Kansas
    KY: '40601', // Frankfort, Kentucky
    LA: '70801', // Baton Rouge, Louisiana
    ME: '04330', // Augusta, Maine
    MD: '21401', // Annapolis, Maryland
    MA: '02108', // Boston, Massachusetts
    MI: '48901', // Lansing, Michigan
    MN: '55101', // Saint Paul, Minnesota
    MS: '39201', // Jackson, Mississippi
    MO: '65101', // Jefferson City, Missouri
    MT: '59601', // Helena, Montana
    NE: '68501', // Lincoln, Nebraska
    NV: '89701', // Carson City, Nevada
    NH: '03301', // Concord, New Hampshire
    NJ: '08601', // Trenton, New Jersey
    NM: '87501', // Santa Fe, New Mexico
    NY: '12201', // Albany, New York
    NC: '27601', // Raleigh, North Carolina
    ND: '58501', // Bismarck, North Dakota
    OH: '43201', // Columbus, Ohio
    OK: '73101', // Oklahoma City, Oklahoma
    OR: '97301', // Salem, Oregon
    PA: '17101', // Harrisburg, Pennsylvania
    RI: '02901', // Providence, Rhode Island
    SC: '29201', // Columbia, South Carolina
    SD: '57501', // Pierre, South Dakota
    TN: '37201', // Nashville, Tennessee
    TX: '73301', // Austin, Texas
    UT: '84101', // Salt Lake City, Utah
    VT: '05601', // Montpelier, Vermont
    VA: '23218', // Richmond, Virginia
    WA: '98501', // Olympia, Washington
    WV: '25301', // Charleston, West Virginia
    WI: '53701', // Madison, Wisconsin
    WY: '82001', // Cheyenne, Wyoming
  };

  return stateCapitalZipCodes[stateAbbreviation.toUpperCase()];
}

export function getCAStateCapitalZipCode(provinceAbbreviation: string): string | undefined {
  const provinceCapitalZipCodes: { [key: string]: string } = {
    AB: 'T5K 1M4', // Edmonton, Alberta
    BC: 'V8V 1X4', // Victoria, British Columbia
    MB: 'R3C 1S4', // Winnipeg, Manitoba
    NB: 'E3B 1H5', // Fredericton, New Brunswick
    NL: 'A1C 5W4', // St. John's, Newfoundland and Labrador
    NS: 'B3J 1X4', // Halifax, Nova Scotia
    NT: 'X1A 2L9', // Yellowknife, Northwest Territories
    NU: 'X0A 0H0', // Iqaluit, Nunavut
    ON: 'K1A 0A9', // Ottawa, Ontario
    PE: 'C1A 7N8', // Charlottetown, Prince Edward Island
    QC: 'G1A 1A1', // Quebec City, Quebec
    SK: 'S4P 3V7', // Regina, Saskatchewan
    YT: 'Y1A 2C6', // Whitehorse, Yukon
  };

  return provinceCapitalZipCodes[provinceAbbreviation.toUpperCase()];
}

export function getStateCapitalZipCodeByCountry(
  stateAbbreviation: string,
  country: string = 'US'
): string | undefined {
  if (country.toUpperCase() === USA) {
    return getUSStateCapitalZipCode(stateAbbreviation);
  } else if (country.toUpperCase() === CANADA) {
    return getCAStateCapitalZipCode(stateAbbreviation);
  }
  return undefined;
}
