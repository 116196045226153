import React from 'react';
import { TypeSetting } from '../../atoms/TypeSetting';
import { Typography } from '../../atoms/Typography';
import { IncentiveDetailTabProps } from '../IncentiveDetailTab';
import { Table } from '../Table';
import { TableHeader, TableRow, Title } from './styled';

export type IncentiveDetailChangelogTabProps = Pick<IncentiveDetailTabProps, 'data'>;

export const IncentiveDetailChangelogTab: React.FC<IncentiveDetailChangelogTabProps> = ({
  data,
}) => {
  const getTranslation = (id: string) => {
    switch (id) {
      case 'Created':
        return (
          <TypeSetting
            element="p"
            i18n={{ id: 'i18n.changelog.created', fallback: 'Created' }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'No change':
      case 'No Change':
        return (
          <TypeSetting
            element="p"
            i18n={{ id: 'i18n.changelog.noChange', fallback: 'No change' }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated amount per rebate':
        return (
          <TypeSetting
            element="p"
            i18n={{ id: 'i18n.changelog.amountperrebate', fallback: 'Updated amount per rebate' }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated current funding':
        return (
          <TypeSetting
            element="p"
            i18n={{ id: 'i18n.changelog.currentfunding', fallback: 'Updated current funding' }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated current funding situation description':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.currentfundingsituationdescription',
              fallback: 'Updated current funding situation description',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated current funding status':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.currentfundingstatus',
              fallback: 'Updated current funding status',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated current program status':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.currentprogramstatus',
              fallback: 'Updated current program status',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated current program status, current funding status':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.currentprogramstatuscurrentfundingstatus',
              fallback: 'Updated current program status, current funding status',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated current program status, current funding status, funding situation description':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.currentprogramstatuscurrentfundingstatusfundingsituationdescription',
              fallback:
                'Updated current program status, current funding status, funding situation description',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated eligibility assumptions':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.eligibilityassumptions',
              fallback: 'Updated eligibility assumptions',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated eligible charging requirements':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.eligiblechargingrequirements',
              fallback: 'Updated eligible charging requirements',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated eligible charging technology':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.eligiblechargingtechnology',
              fallback: 'Updated eligible charging technology',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated eligible charging technology description':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.eligiblechargingtechnologydescription',
              fallback: 'Updated eligible charging technology description',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated eligible charging technology descriptionj':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.eligiblechargingtechnologydescriptionj',
              fallback: 'Updated eligible charging technology descriptionj',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated eligible fuel types':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.eligiblefueltypes',
              fallback: 'Updated eligible fuel types',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated eligible organization classification description':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.eligibleorganizationclassificationdescription',
              fallback: 'Updated eligible organization classification description',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated eligible organization other description':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.eligibleorganizationotherdescription',
              fallback: 'Updated eligible organization other description',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated eligible organizations':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.eligibleorganizations',
              fallback: 'Updated eligible organizations',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated eligible project accessory':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.eligibleprojectaccessory',
              fallback: 'Updated eligible project accessory',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated eligible project type description':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.eligibleprojecttypedescription',
              fallback: 'Updated eligible project type description',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated eligible project types':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.eligibleprojecttypes',
              fallback: 'Updated eligible project types',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated eligible scrappage requirement':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.eligiblescrappagerequirement',
              fallback: 'Updated eligible scrappage requirement',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated eligible scrappage requirement description':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.eligiblescrappagerequirementdescription',
              fallback: 'Updated eligible scrappage requirement description',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated eligible scrappage requirement descriptiont':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.eligiblescrappagerequirementdescriptiont',
              fallback: 'Updated eligible scrappage requirement descriptiont',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated eligible vehicle duties':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.eligiblevehicleduties',
              fallback: 'Updated eligible vehicle duties',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated eligible vehicle type':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.eligiblevehicletype',
              fallback: 'Updated eligible vehicle type',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated eligible vehicle type description':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.eligiblevehicletypedescription',
              fallback: 'Updated eligible vehicle type description',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated eligible vehicle weight classes':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.eligiblevehicleweightclasses',
              fallback: 'Updated eligible vehicle weight classes',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated expiration date':
        return (
          <TypeSetting
            element="p"
            i18n={{ id: 'i18n.changelog.expirationdate', fallback: 'Updated expiration date' }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated funding cap per applicant':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.fundingcapperapplicant',
              fallback: 'Updated funding cap per applicant',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated funding cap per project':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.fundingcapperproject',
              fallback: 'Updated funding cap per project',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated funding situation description':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.fundingsituationdescription',
              fallback: 'Updated funding situation description',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated grantor name':
        return (
          <TypeSetting
            element="p"
            i18n={{ id: 'i18n.changelog.grantorname', fallback: 'Updated grantor name' }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated grantor type':
        return (
          <TypeSetting
            element="p"
            i18n={{ id: 'i18n.changelog.grantortype', fallback: 'Updated grantor type' }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated incentive application URL':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.incentiveapplicationURL',
              fallback: 'Updated incentive application URL',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated incentive calculation methodology description':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.incentivecalculationmethodologydescription',
              fallback: 'Updated incentive calculation methodology description',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated incentive description':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.incentivedescription',
              fallback: 'Updated incentive description',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated incentive focus':
        return (
          <TypeSetting
            element="p"
            i18n={{ id: 'i18n.changelog.incentivefocus', fallback: 'Updated incentive focus' }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated incentive name':
        return (
          <TypeSetting
            element="p"
            i18n={{ id: 'i18n.changelog.incentivename', fallback: 'Updated incentive name' }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated incentive nature':
        return (
          <TypeSetting
            element="p"
            i18n={{ id: 'i18n.changelog.incentivenature', fallback: 'Updated incentive nature' }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated incentive program contact info':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.incentiveprogramcontactinfo',
              fallback: 'Updated incentive program contact info',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated incentive scope':
        return (
          <TypeSetting
            element="p"
            i18n={{ id: 'i18n.changelog.incentivescope', fallback: 'Updated incentive scope' }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated incentive scope description':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.incentivescopedescription',
              fallback: 'Updated incentive scope description',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated incentive scope other':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.incentivescopeother',
              fallback: 'Updated incentive scope other',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated incentive URL':
        return (
          <TypeSetting
            element="p"
            i18n={{ id: 'i18n.changelog.incentiveURL', fallback: 'Updated incentive URL' }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated is eligible to specified form factors only':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.iseligibletospecifiedformfactorsonly',
              fallback: 'Updated is eligible to specified form factors only',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated is lease eligible':
        return (
          <TypeSetting
            element="p"
            i18n={{ id: 'i18n.changelog.isleaseeligible', fallback: 'Updated is lease eligible' }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated lease beneficiary':
        return (
          <TypeSetting
            element="p"
            i18n={{ id: 'i18n.changelog.leasebeneficiary', fallback: 'Updated lease beneficiary' }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated mutually exclusive programs':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.mutuallyexclusiveprograms',
              fallback: 'Updated mutually exclusive programs',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated mutually exclusive programs original':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.mutuallyexclusiveprogramsoriginal',
              fallback: 'Updated mutually exclusive programs original',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated stackability notes':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.stackabilitynotes',
              fallback: 'Updated stackability notes',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated start date':
        return (
          <TypeSetting
            element="p"
            i18n={{ id: 'i18n.changelog.startdate', fallback: 'Updated start date' }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated state':
        return (
          <TypeSetting
            element="p"
            i18n={{ id: 'i18n.changelog.state', fallback: 'Updated state' }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated total funding':
        return (
          <TypeSetting
            element="p"
            i18n={{ id: 'i18n.changelog.totalfunding', fallback: 'Updated total funding' }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated typical amount':
        return (
          <TypeSetting
            element="p"
            i18n={{ id: 'i18n.changelog.typicalamount', fallback: 'Updated typical amount' }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated typical amount description':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.typicalamountdescription',
              fallback: 'Updated typical amount description',
            }}
            scale="3"
            fontWeight="400"
          />
        );
      case 'Updated Workplace EVSE Rebate':
        return (
          <TypeSetting
            element="p"
            i18n={{
              id: 'i18n.changelog.workplaceEVSERebate',
              fallback: 'Updated Workplace EVSE Rebate',
            }}
            scale="3"
            fontWeight="400"
          />
        );

      default:
        return id;
    }
  };

  return (
    <>
      <Title>
        <TypeSetting
          i18n={{
            id: 'i18n.changelog.main.title',
            fallback:
              'Incentive details are updated as new information becomes available. This changelog captures those changes.',
          }}
          scale="3"
          fontWeight="500"
        />
      </Title>
      <Table
        slots={{
          header: (
            <TableHeader>
              <TypeSetting
                i18n={{ id: 'i18n.changelog.date', fallback: 'Date' }}
                scale="3"
                fontWeight="500"
              />
              <TypeSetting
                i18n={{ id: 'i18n.changelog.changes', fallback: 'changes' }}
                scale="3"
                fontWeight="500"
              />
              <TypeSetting
                i18n={{ id: 'i18n.changelog.nextupdate', fallback: 'next review by' }}
                scale="3"
                fontWeight="500"
              />
            </TableHeader>
          ),
        }}
      >
        {data?.changelog.map((item) => {
          return (
            <TableRow>
              <Typography element="p" scale="3" fontWeight="400">
                {new Date(item.changelog_date.replaceAll('-', '/')).toLocaleDateString('en-US')}
              </Typography>

              {getTranslation(item.changelog_description)}

              <Typography element="p" scale="3" fontWeight="400">
                <span id="mobileText">Next Review By: </span>
                {new Date(item.next_scheduled_review.replaceAll('-', '/')).toLocaleDateString(
                  'en-US'
                )}
              </Typography>
            </TableRow>
          );
        })}
      </Table>
      <p
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '10px 30px 0 20px',
        }}
      >
        <TypeSetting
          i18n={{
            id: 'i18n.changelog.footnote',
            fallback: ' * Reviews occur within 7 days of the specified date',
          }}
          scale="3"
          fontWeight="500"
        />
      </p>
    </>
  );
};

IncentiveDetailChangelogTab.displayName = 'IncentiveDetailChangelogTab';
