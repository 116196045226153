import { useEffect, useState } from 'react';
import { IncentiveData } from '../../components/organisms/IncentivesResults/IncentivesResults.types';
import { useLocation } from '../useLocation';
import { useEvaluatedApi } from '../useEvaluatedApi';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updaters } from '../../components/AwsAmplifyHelper/userAuthenticationSlice';
import { CONSUMER } from '../../constants/utils';

export const applyFilter =
  (
    filteredData: IncentiveData[],
    filterFunctions: {
      [key: string]: (data: IncentiveData[], value: string) => IncentiveData[];
    } = {}
  ) =>
  ({ name, value }: { name: string; value: string }) => {
    if (typeof filterFunctions[name] !== 'function') {
      return filteredData;
    }

    return filterFunctions[name](filteredData, value);
  };

export const applyFilters =
  (
    filterFunctions: {
      [key: string]: (data: IncentiveData[], value: string) => IncentiveData[];
    },
    filtersList: { [key: string]: { name: string; value: string } }
  ) =>
  (acc: IncentiveData[], currentFilterKey: string) => {
    return (applyFilter(acc, filterFunctions)(filtersList[currentFilterKey]) ||
      []) as IncentiveData[];
  };

export function useFilters(
  initialData: IncentiveData[],
  appliedFilters: { [key: string]: { name: string; value: string } } = {},
  filterFunctions: {
    [key: string]: (data: IncentiveData[], value: string) => IncentiveData[];
  } = {},
  hasVehicleTrim: boolean
) {
  const state = useSelector((state: any) => state.userSlice);
  const { data: location } = useLocation(true);
  const {
    mutate,
    data: evaluatedData,
    isLoading,
    status,
  } = useEvaluatedApi(location, state.selectedVehicle);
  const [filtersList, setFiltersList] = useState(appliedFilters);
  const [apiData, setApiDate] = useState(initialData);
  const { country, type } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (
      initialData &&
      evaluatedData &&
      evaluatedData.length > 0 &&
      state.selectedVehicle &&
      type !== CONSUMER
    ) {
      setApiDate(initialData.filter((object) => evaluatedData.includes(object.incentive_handle)));
    } else {
      if (!evaluatedData) setApiDate(initialData);
    }
  }, [initialData, evaluatedData]);

  const setFilter = ({ name, value }: { name: string; value: string }) => {
    setFiltersList((list) => {
      return {
        ...list,
        [name]: { name, value },
      };
    });
  };

  useEffect(() => {
    dispatch(updaters.userLocation(location?.data?.location?.power_supplier ?? []));
  }, [location]);

  useEffect(() => {
    if (state.selectedVehicle) mutate();
  }, [state.selectedVehicle]);

  const availableFilters = Object.keys(filterFunctions).filter((name) =>
    Object.keys(filtersList).includes(name)
  );

  useEffect(() => {
    setFiltersList(appliedFilters);
  }, [country, type, appliedFilters]);

  useEffect(() => {
    const filtered = apiData.filter((item) => {
      if (item.grantor_type === 'utility' && item.grantor_name !== state.userPowerSupplier) {
        return false;
      }
      return true;
    });

    setApiDate(filtered);
  }, [state.userPowerSupplier]);

  useEffect(() => {
    if (type !== CONSUMER) {
      setApiDate(initialData);
    } else {
      const filteredData = initialData.filter((dataItem: any) => {
        return state.consumerFilters.every((filter: any) => {
          if (filter.name === 'applicable_to_lease') {
            if (dataItem.support_for === 'Vehicle purchase') {
              if (filter.value === 'true') {
                return (
                  dataItem.evaluation?.applicable_to_lease &&
                  dataItem.evaluation?.amount_in_lease !== 0
                );
              } else {
                return (
                  dataItem.evaluation?.applicable_to_ownership &&
                  dataItem.evaluation?.amount_in_purchase !== 0
                );
              }
            } else {
              return dataItem;
            }
          }
          if (filter.name === 'turn_in_clunker') {
            if (filter.value === 'true') {
              return dataItem.limitations === 'turn in clunker';
            } else {
              return dataItem.limitations !== 'turn in clunker';
            }
          }

          if (filter.name === 'support_for') {
            const vehicleFilter = filter.value.toLowerCase().includes('vehicle');
            const chargerFilter = filter.value.toLowerCase().includes('station');

            if ((vehicleFilter && chargerFilter) || (!vehicleFilter && !chargerFilter)) return true;

            if (vehicleFilter) {
              return (
                dataItem.support_for.toLowerCase() === 'vehicle purchase' ||
                dataItem.support_for.toLowerCase() === 'driving electric'
              );
            }

            if (chargerFilter) {
              const supportFor = dataItem.support_for.toLowerCase();
              return supportFor.includes('charging station') || supportFor.includes('electricity');
            }
          }

          const filterValues = filter.value.split(',');

          return filterValues.some((filterValue: any) => {
            let booleanFilterValue = filterValue;

            if (filterValue === 'true' || filterValue === 'false') {
              booleanFilterValue = filterValue === 'true';
              return dataItem[filter.name] === booleanFilterValue;
            }

            return dataItem[filter.name] === filterValue;
          });
        });
      });

      if (hasVehicleTrim) {
        const fineFilteredData = filteredData.filter(
          (dataItem: any) => dataItem.evaluation?.eligibility === 'eligible'
        );

        setApiDate(fineFilteredData);
      } else {
        setApiDate(filteredData);
      }
    }
  }, [state.consumerFilters, type, initialData, hasVehicleTrim]);

  return {
    data: availableFilters.reduce(applyFilters(filterFunctions, filtersList), apiData),
    setFilter,
    filtersList,
    evaluatedLoading: isLoading,
    evaluatedStatus: status,
  };
}
