import React from 'react';
import { StyledIcon } from './styled';

export type IconOwnProps = {
  src: string;
  height?: string;
  width?: string;
};
export type IconProps = IconOwnProps;

export const Icon: React.FC<IconProps> = ({ src, height, width }) => {
  return <StyledIcon src={src} height={height} width={width} aria-hidden />;
};

Icon.displayName = 'Icon';
