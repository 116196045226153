import GoogleAnalytics from 'react-ga4';

const trackers: any[] = [];

const hydrateTrackers = () => {
  if (trackers.length) {
    return;
  }

  trackers.push({
    trackingId: 'G-ZVTZPQS9XW',
    gaOptions: {
      name: 'zappyTracking',
    },
  });
  trackers.push({
    trackingId: 'G-PH9TDC0GH8',
    gaOptions: {
      name: 'gmFleetsTracking',
    },
  });
};

hydrateTrackers();

const GaTracker = {
  initialize: () => {
    if (trackers.length) {
      GoogleAnalytics.initialize(trackers);
    }
  },
  getPageName: (page: any) => {
    const lastCharIndex = page.length - 1;

    return page.length > 1 && page.charAt(lastCharIndex) === '/'
      ? page.substring(0, lastCharIndex)
      : page;
  },
  trackPage: (page: any, options = {}) => {
    page = GaTracker.getPageName(page);

    if (trackers.length) {
      trackers.forEach((tracker) => {
        GoogleAnalytics.set(
          {
            page,
            ...options,
          },
          //   @ts-ignore
          [tracker.gaOptions.name]
        );
        GoogleAnalytics.pageview(page, [tracker.gaOptions.name]);
      });
    }
  },
};

export default GaTracker;
