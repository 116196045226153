import styled, { css } from 'styled-components';
import { resolveStyles } from '../../../utils/styled/resolveStyles';

const fallback = css`
  padding: 2.5rem 0;
  background-color: #f7f8f9;
  min-height: 100vh;
`;

export const Styled = styled.div`
  ${resolveStyles({ fallback })}
`;
