import { Modal } from '@material-ui/core';
import styled from 'styled-components';

export const FormBlock = styled.form`
  #exit-modal {
    background-color: white;
    padding: 20px;

    .MuiGrid-root {
      justify-content: center;
      align-items: center;
    }

    .MuiButtonBase-root {
      width: 100%;
      margin: 20px 0 30px;
      background-color: #007dbe;
      color: #fff;

      border-radius: 0;
      font-weight: normal;
    }
  }
`;

export const StyledModal = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ModalTitle = styled.div`
  border-bottom: 1px solid rgba(185, 188, 192, 1);
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 0 1em;
  margin-bottom: 1em;

  h2 {
    font-size: 24px;
    line-height: 1em;
  }
`;

export const ModalContent = styled.div`
  background: white;
  padding: 1em;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
  text-align: left;
  position: relative;
  padding: 2em;
`;

export const ExitButton = styled.div`
  cursor: pointer;
`;

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1em;
`;

export const ConfirmButton = styled.button`
  border: 0px;
  outline: 0px;
  color: rgb(255, 255, 255);
  font-size: 0.875rem;
  background-color: rgb(0, 125, 190);
  padding: 1em 2em;
  font-weight: bolder;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  min-width: 250px;

  @media (max-width: 768px) {
    min-width: 200px;
  }
`;

export const CancelButton = styled.button`
  border: 1px solid rgb(0, 125, 190);
  outline: 0px;
  color: black;
  font-size: 0.875rem;
  background-color: white;
  padding: 1em 2em;
  font-weight: bolder;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  min-width: 100px;
`;
