import styled, { css } from 'styled-components';
import { resolveStyles } from '../../../utils/styled/resolveStyles';
import { StyledTooltip } from '../../organisms/Tooltip/styled';

const fallback = css`
  label {
    font-size: 1rem;
    font-weight: 500;
  }
`;

export const Styled = styled.div`
  ${resolveStyles({ fallback })};
`;

const childrenContainerFallback = css`
  margin-top: 1.25rem;
`;

export const StyledChildrenContainer = styled.div`
  ${resolveStyles({ fallback: childrenContainerFallback })}
`;

const labelTooltipContainerFallback = css`
  display: flex;
  align-items: baseline;

  ${StyledTooltip} {
    margin-left: 0.5rem;
    top: 2px;
  }
`;

export const StyledLabelTooltipContainer = styled.div`
  ${resolveStyles({ fallback: labelTooltipContainerFallback })}
`;
