import defaultConfig, { vehiclesIncentivesConfig, defaultConsumerConfig } from '../../config';

export function isVehiclesIncentivesConfigOn() {
  return window.location.search.includes('vehicles_incentives=on');
}

export function isConsumerConfig() {
  return window.location.pathname.includes('/consumer');
}

export function resolveConfig() {
  if (isVehiclesIncentivesConfigOn()) {
    return vehiclesIncentivesConfig;
  }

  if (isConsumerConfig()) {
    return defaultConsumerConfig;
  }

  return defaultConfig;
}
