import styled from 'styled-components';
import { ContentWrapper } from '../../atoms/ContentWrapper';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 102px;
  background: #fff;
  align-items: center;
  justify-content: center;
`;

export const Wrapper = styled(ContentWrapper)``;

export const CompanyLogo = styled.img`
  width: 60px;
  height: auto;

  @media (max-width: 768px) {
    width: 36px;
  }
`;

export const ZappyLogo = styled.img`
  width: 130px;
  height: auto;
  margin-right: 24px;
`;

export const RightWrapper = styled.div`
  display: flex;
  align-items: center;

  img {
    position: relative;
    top: 2px;
  }
`;
