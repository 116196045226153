import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { resolveStyles } from '../../../utils/styled/resolveStyles';
import { StyledIcon } from '../../atoms/Icon/styled';

const fallback = css``;

export const Styled = styled.tr`
  ${resolveStyles({ fallback })}
`;

const moreDetailsFallback = css`
  color: #007dbe;
  font-size: 0.875rem;
  font-weight: bolder;
  display: inline-flex;
  align-items: center;

  ${StyledIcon} {
    margin-left: 0.75rem;
  }
`;

export const StyledMoreDetails = styled(Link)`
  ${resolveStyles({ fallback: moreDetailsFallback })}
`;

const moreDetailsColFallback = css`
  text-align: right;
`;

export const StyledMoreDetailsCol = styled.td`
  ${resolveStyles({ fallback: moreDetailsColFallback })}
`;
