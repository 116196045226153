import React, { useState } from 'react';
import { IncentiveDetailsBoxProps } from '../IncentiveDetailsBox';
import { FormattedDate } from '../../atoms/FormattedDate';
import { BoxBody, BoxHighlightedText } from '../../molecules/Box';
import { LinkButton } from '../../atoms/LinkButton';
import { StyledButtonContainer } from './styled';
import { TypeSetting } from '../../atoms/TypeSetting';
import { enUS, frCA } from 'date-fns/locale';
import { useTranslations } from '../../../contexts/Translations';
import ExitModal from '../ExitModal/ExitModal';
import OpenIcon from '../../../assets/icons/open_in_new.svg';

export type IncentiveDetailsBoxExpirationDateProps = Pick<IncentiveDetailsBoxProps, 'data'>;

export const IncentiveDetailsBoxExpirationDate: React.FC<
  IncentiveDetailsBoxExpirationDateProps
> = ({ data }) => {
  const { language } = useTranslations();
  const [isModalOpen, setModalOpen] = useState(false);

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setModalOpen(true);
  };

  const handleConfirmNavigation = () => {
    setModalOpen(false);
    window.open(data.incentive_url, '_blank', 'noopener,noreferrer');
  };

  const handleCancelNavigation = () => {
    setModalOpen(false);
  };

  const getFormattedDate = () => {
    if (data.expiration_date === '2038-01-01')
      return (
        <TypeSetting
          i18n={{
            id: 'i18n.current_program_status.open.expiration_countdown.noExpiration',
            fallback: 'No expiration date',
          }}
          element="h2"
        />
      );

    const config = {
      locale: language === 'fr' ? frCA : enUS,
    };

    return <FormattedDate date={data.expiration_date} config={config} />;
  };

  return (
    <>
      <BoxBody>
        <BoxHighlightedText>{data?.expiration_date && getFormattedDate()}</BoxHighlightedText>
        <StyledButtonContainer>
          {data?.incentive_url && (
            <LinkButton
              href={data.incentive_url}
              onClick={handleLinkClick}
              target="_blank"
              rel="nofollow noreferrer"
              i18n={{ id: 'i18n.apply', fallback: 'Apply' }}
              icon={OpenIcon}
            />
          )}
        </StyledButtonContainer>
      </BoxBody>
      <ExitModal
        isOpen={isModalOpen}
        onConfirm={handleConfirmNavigation}
        onCancel={handleCancelNavigation}
      />
    </>
  );
};

IncentiveDetailsBoxExpirationDate.displayName = 'IncentiveDetailsBoxExpirationDate';
