import styled, { css } from 'styled-components';
import { Styled as Typography } from '../Typography/styled';
import { resolveStyles } from '../../../utils/styled/resolveStyles';

const fallback = css`
  font-family: 'Overpass', sans-serif;
`;

export const StyledTypography = styled(Typography)`
  ${resolveStyles({ fallback })}
`;
