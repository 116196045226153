import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { resolveStyles } from '../../../utils/styled/resolveStyles';
import { StyledTypography } from '../../atoms/TypeSetting/styled';
import { StyledIcon } from '../../atoms/Icon/styled';

const FONT_SIZE_FALLBACK = '0.75rem';

const optionsContainerFallback = css`
  position: absolute;
  background-color: #fff;
  z-index: 1;
  top: -100%;
  width: 100%;
`;

export const StyledContainer = styled.div`
  position: relative;
  z-index: 1;
  min-width: 4.5rem;
`;

export const StyledOptionsContainer = styled(motion.menu)`
  ${resolveStyles({ fallback: optionsContainerFallback })}
`;

const styledOptionContainerFallback = css`
  ${StyledTypography} {
    font-size: ${FONT_SIZE_FALLBACK};
  }

  :hover {
    ${StyledTypography} {
      font-weight: bolder;
    }
  }
`;

export const StyledOptionContainer = styled.li`
  ${resolveStyles({ fallback: styledOptionContainerFallback })}
`;

const styledToggleButtonFallback = css`
  ${StyledTypography} {
    font-size: ${FONT_SIZE_FALLBACK};
    display: inline-flex;
    align-items: center;
  }

  ${StyledIcon} {
    margin-left: 0.5rem;
  }
`;

export const StyledToggleButton = styled.button`
  ${resolveStyles({ fallback: styledToggleButtonFallback })}
`;
