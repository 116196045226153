/* eslint-disable react-hooks/rules-of-hooks */
import { CANADA } from '../../constants/utils';
import { useTranslations } from '../../contexts/Translations';
import { useUnifiedAPI } from '../useUnifiedAPI';
import { useParams } from 'react-router-dom';
import { parseState } from '../utils';
// import { add } from 'lodash';

interface Param {
  name: string;
  value: string;
}

interface Options {
  enabled: boolean;
  country?: string;
  type?: string;
  handle?: string;
}

function ensureDefaultParams(params: Param[]): Param[] {
  const defaultParams = [
    { name: 'household_income', value: '1' },
    { name: 'tax_filing_type', value: 'single' },
    { name: 'household_size', value: '1' },
  ];

  defaultParams.forEach((defaultParam) => {
    if (!params.some((param) => param.name === defaultParam.name)) {
      params.push(defaultParam);
    }
  });

  return params;
}

function useConsumerIncentives(
  additionalParams: Param[] = [],
  options: Options = { enabled: false, country: 'US', type: 'commercial' }
) {
  const { type } = useParams();
  const { language } = useTranslations();
  const includeUsedVehicles = options.handle ? undefined : 'true';

  let params = [
    ...additionalParams.map((param) => parseState(param)),
    { name: 'country', value: options.country || 'us' },
    ...(includeUsedVehicles ? [{ name: 'include_used_vehicles', value: includeUsedVehicles }] : []),
    ...(language === 'fr' ? [{ name: 'preferred_lang', value: 'fr' }] : []),
  ];

  if (options.country === CANADA) {
    params.forEach((param) => {
      if (param.name === 'postcode') {
        param.value = param.value.replace(/\s+/g, '');
      }
    });
  }

  params = ensureDefaultParams(params);

  let isEnabled = options.enabled;

  if (type === 'commercial') {
    isEnabled = false;
  }

  return useUnifiedAPI(
    options.handle ? `/vehicles/${options.handle}` : `/incentives`,
    params,
    { runDataTransformations: false },
    { enabled: isEnabled }
  );
}

function useCommercialIncentives(
  additionalParams: Param[] = [],
  options: Options = { enabled: false, country: 'us', type: 'commercial' }
) {
  const { type } = useParams();
  const { language } = useTranslations();

  let params = [
    ...additionalParams.map((param) => parseState(param)),
    { name: 'country', value: options.country || 'us' },
    { name: 'incentive_focus', value: 'chargers,vehicles' },
    { name: 'include_used_vehicles', value: 'true' },
    ...(language === 'fr' ? [{ name: 'preferred_lang', value: 'fr' }] : []),
  ];

  if (options.country === CANADA) {
    params.forEach((param) => {
      if (param.name === 'postcode') {
        param.value = param.value.replace(/\s+/g, '');
      }
    });
  }

  params = ensureDefaultParams(params);

  let isEnabled = options.enabled;

  if (type === 'consumer') {
    isEnabled = false;
  }

  return useUnifiedAPI(
    `commercial/incentives`,
    params,
    { runDataTransformations: false },
    { enabled: isEnabled }
  );
}

export function useIncentivesApi(
  params: Param[] = [],
  options: { enabled: boolean; country?: string; type?: string } = {
    enabled: false,
    country: 'us',
    type: 'commercial',
  }
) {
  if (options.type === 'commercial') {
    return useCommercialIncentives(params, options);
  } else {
    return useConsumerIncentives(params, options);
  }
}
