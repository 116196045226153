import "./circle.css";

const Loaders = ({ type, children }) => {
  let selectedLoader;
  switch (type) {
    case "circle_spinner":
      selectedLoader = (
        <div className="spin circle-loader">
          <div></div>
        </div>
      );
      break;
    case "circle_bounce":
      selectedLoader = (
        <div className="bounce circle-loader">
          <div></div>
        </div>
      );
      break;
    default:
      break;
  }

  return (
    <>
      {selectedLoader}
      {children}
    </>
  );
};

export default Loaders;

