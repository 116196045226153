import { useMemo } from 'react';
import { IUSAState } from './USAState.types';
import { Path } from '../styled';

const CanadaState: React.FC<IUSAState> = ({
  dimensions,
  fill,
  state,
  onClickState,
  onMouseEnter,
  onMouseLeave,
  isSelected,
  selectedState,
}) => {
  const renderPath = useMemo(() => {
    return (
      <Path
        d={dimensions}
        fill={fill}
        data-name={state}
        name={'stateSVG'}
        id={'stateSVG'}
        onClick={onClickState}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        isSelected={isSelected}
        selectedState={!!selectedState}
      ></Path>
    );
  }, [
    dimensions,
    fill,
    state,
    onClickState,
    onMouseEnter,
    onMouseLeave,
    isSelected,
    selectedState,
  ]);

  return renderPath;
};
export default CanadaState;
