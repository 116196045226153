import React from 'react';
import { IncentiveDetailsBoxProps } from '../IncentiveDetailsBox';
import { BoxBody, BoxHighlightedText } from '../../molecules/Box';
import { TypeSetting } from '../../atoms/TypeSetting';

export type IncentiveDetailsBoxFundingCapProps = Pick<IncentiveDetailsBoxProps, 'data'>;

export const IncentiveDetailsBoxTypicalAmount: React.FC<IncentiveDetailsBoxFundingCapProps> = ({
  data,
}) => {
  const value = data?.typical_amount || 0;

  return (
    <BoxBody>
      {data && (
        <div>
          <BoxHighlightedText>{value}</BoxHighlightedText>
          <div>
            <TypeSetting
              scale="2"
              i18n={{
                id: 'i18n.consumer.detail.cards.per_type',
                fallback: 'per vehicle or charger',
              }}
            />
          </div>
        </div>
      )}
    </BoxBody>
  );
};

IncentiveDetailsBoxTypicalAmount.displayName = 'IncentiveDetailsBoxTypicalAmount';
