import React from 'react';
import { TypeSettingProps, TypeSetting } from '../TypeSetting';
import { Styled } from './styled';

export type TitleProps = TypeSettingProps;

export const Title: React.FC<TitleProps> = ({ i18n }) => {
  return (
    <Styled>
      <TypeSetting i18n={i18n} element="h1" />
    </Styled>
  );
};

Title.displayName = 'Title';
