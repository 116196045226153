import React, { useState } from 'react';
import {
  Container,
  ButtonContainer,
  Icon,
  Typography,
  ModalOverlay,
  Button,
  CloseIcon,
} from './styled';
import { Paper } from '@material-ui/core';

import groupIcon from '../../../assets/icons/group.svg';
import { TypeSetting } from '../TypeSetting';
import { RadioInput } from '../RadioInput';
import { useNavigate, useParams } from 'react-router-dom';
import { useURLQuery } from '../../../hooks';

export const ModalButton: React.FC = () => {
  const history = useNavigate();
  const { country: paramCountry, type: paramType } = useParams();
  const [country, setCountry] = useState(paramCountry || 'US');
  const [isOpen, seIsOpen] = useState(false);
  const [type, setType] = useState(paramType || 'commercial');
  const { setParam } = useURLQuery();

  const handleClick = () => {
    if (country === 'CA') {
      setParam({ name: 'postcode', value: 'M5V 1J1' });
    } else {
      setParam({ name: 'postcode', value: '95026' });
    }
    seIsOpen(false);
    history(`/incentives/${country}/${type}`);
  };

  return (
    <Container>
      <ButtonContainer onClick={() => seIsOpen(true)}>
        <Icon src={groupIcon} />
        <Typography>
          <TypeSetting
            element="label"
            i18n={{
              id: 'i18n.pages.splashscreen.modalButton',
              fallback: 'Incentive Type & Region',
            }}
          />
        </Typography>
      </ButtonContainer>
      {isOpen && (
        <ModalOverlay>
          <Paper className="LoginContainer">
            <CloseIcon onClick={() => seIsOpen(false)}>
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                  fill="#323232"
                />
              </svg>
            </CloseIcon>
            <div className="logoTextContainer">
              <h1 className="title">
                <TypeSetting
                  element="label"
                  i18n={{
                    id: 'i18n.pages.splashscreen.dealer',
                    fallback: 'GM Dealer Dashboard',
                  }}
                />
              </h1>
              <p className="description">
                <TypeSetting
                  element="label"
                  i18n={{
                    id: 'i18n.pages.splashscreen.description',
                    fallback: 'How would you like to view incentives? First choose your region.',
                  }}
                />
              </p>
            </div>
            <div className="textContainerWizard">
              <div className="row">
                <RadioInput
                  onClick={() => setCountry('US')}
                  isSelected={country === 'US'}
                  i18n={{ id: 'i18n.pages.splashscreen.us', fallback: 'USA' }}
                />
                <RadioInput
                  onClick={() => setCountry('CA')}
                  isSelected={country === 'CA'}
                  i18n={{ id: 'i18n.pages.splashscreen.ca', fallback: 'Canada' }}
                />
              </div>
              <p className="description middle-row">
                <TypeSetting
                  element="label"
                  i18n={{
                    id: 'i18n.pages.splashscreen.descriptionTwo',
                    fallback: 'Next, choose the type of incentives you would like to view.',
                  }}
                />
              </p>
              <div className="row">
                <RadioInput
                  onClick={() => setType('consumer')}
                  isSelected={type === 'consumer'}
                  i18n={{ id: 'i18n.pages.splashscreen.consumer', fallback: 'Consumer' }}
                  type={'consumer'}
                />
                <RadioInput
                  onClick={() => setType('commercial')}
                  isSelected={type === 'commercial'}
                  i18n={{ id: 'i18n.pages.splashscreen.commercial', fallback: 'Commercial' }}
                  type={'commercial'}
                />
              </div>
            </div>
            <Button onClick={handleClick}>
              <TypeSetting
                element="label"
                i18n={{
                  id: 'i18n.pages.splashscreen.modal.update',
                  fallback: 'Update Incentives',
                }}
              />
            </Button>
          </Paper>
        </ModalOverlay>
      )}
    </Container>
  );
};

ModalButton.displayName = 'ModalButton';
