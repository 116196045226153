import {
  differenceInYears,
  differenceInDays,
  differenceInWeeks,
  differenceInMonths,
} from 'date-fns';

export enum DifferenceKind {
  DAYS = 'days',
  WEEKS = 'weeks',
  MONTHS = 'months',
  YEARS = 'years',
}

export function resolveKind(differenceInDays: number, differenceInYears: number) {
  return differenceInYears >= 2
    ? DifferenceKind.YEARS
    : differenceInDays >= 56
    ? DifferenceKind.MONTHS
    : differenceInDays >= 14
    ? DifferenceKind.WEEKS
    : DifferenceKind.DAYS;
}

export function differenceForOpenStatus(a: Date, b: Date): { kind: DifferenceKind; count: number } {
  const days = differenceInDays(a, b);
  const years = differenceInYears(a, b);

  switch (resolveKind(days, years)) {
    case DifferenceKind.YEARS:
      return {
        kind: DifferenceKind.YEARS,
        count: years,
      };
    case DifferenceKind.MONTHS:
      return {
        kind: DifferenceKind.MONTHS,
        count: differenceInMonths(a, b),
      };
    case DifferenceKind.WEEKS:
      return {
        kind: DifferenceKind.WEEKS,
        count: differenceInWeeks(a, b),
      };
    case DifferenceKind.DAYS:
      return {
        kind: DifferenceKind.DAYS,
        count: days,
      };
  }
}
