import styled, { css } from 'styled-components';
import { resolveStyles } from '../../../utils/styled/resolveStyles';

const containerFallback = css`
  padding: 0 0 1.125rem 0;

  @media screen and (min-width: 768px) {
    padding: 2.75rem 0;
  }
`;

export const StyledContainer = styled.div`
  ${resolveStyles({ fallback: containerFallback })}
`;

const gridFallback = css`
  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: 4fr 1fr;
    column-gap: 2rem;
  }
`;

export const StyledGrid = styled.div`
  ${resolveStyles({ fallback: gridFallback })}
`;

const mainColumnFallback = css``;

export const StyledMainColumn = styled.div`
  ${resolveStyles({ fallback: mainColumnFallback })}
`;

const sidebarFallback = css``;

export const StyledSidebar = styled.div`
  ${resolveStyles({ fallback: sidebarFallback })}
`;

const contentBlockFallback = css`
  & + & {
    margin-top: 1rem;

    @media screen and (min-width: 768px) {
      margin-top: 2.5rem;
    }
  }
`;

export const StyledContentBlock = styled.div`
  ${resolveStyles({ fallback: contentBlockFallback })}
`;
