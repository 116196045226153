import React, { useState, useEffect } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useToggle } from '../../../hooks';
import { I18nStructure } from '../../../types';
import { Icon } from '../../atoms/Icon';
import { TypeSetting } from '../../atoms/TypeSetting';
import {
  StyledContainer,
  StyledOptionsContainer,
  StyledOptionContainer,
  StyledToggleButton,
} from './styled';
import chevronDown from '../../../assets/icons/chevron-down.svg';

export type SelectOverlayOption = {
  id: string;
  selected?: boolean;
} & I18nStructure;

export type SelectOverlayProps = {
  onChange?: (selectedOptionId: string) => void;
  config: {
    options: SelectOverlayOption[];
  };
};

const TRANSITION_PROPS = {
  transition: { duration: 0.4 },
};

const ANIMATION_PROPS = {
  initial: { opacity: 0 },
  exit: { opacity: 0 },
  animate: { opacity: 1 },
  ...TRANSITION_PROPS,
};

export const getSelectedOption = (options: SelectOverlayOption[]) => {
  return options.find(({ selected }) => selected) || options[0];
};

export const SelectOverlay: React.FC<SelectOverlayProps> = ({
  onChange = () => null,
  config: { options },
}) => {
  if (options.length < 2) {
    throw new Error('The select needs at least 2 options to work.');
  }

  const [selected, setSelected] = useState(getSelectedOption(options));
  const { value: isOpen, toggle } = useToggle();
  const handleClick = (option: SelectOverlayOption) => () => {
    toggle();
    setSelected(option);
    onChange(option.id);
  };

  useEffect(() => {
    if (selected === getSelectedOption(options)) return;

    setSelected(getSelectedOption(options));
  }, [options, selected]);

  return (
    <StyledContainer>
      <StyledToggleButton onClick={toggle} type="button">
        <TypeSetting element="span" i18n={selected.i18n}>
          <Icon src={chevronDown} />
        </TypeSetting>
      </StyledToggleButton>
      <AnimatePresence>
        {isOpen && (
          <StyledOptionsContainer {...ANIMATION_PROPS}>
            {options.map((option) => (
              <StyledOptionContainer key={option.id}>
                <TypeSetting element="button" i18n={option.i18n} onClick={handleClick(option)} />
              </StyledOptionContainer>
            ))}
          </StyledOptionsContainer>
        )}
      </AnimatePresence>
    </StyledContainer>
  );
};

SelectOverlay.displayName = 'SelectOverlay';
