import capitalize from 'lodash.capitalize';
import { IncentiveDetails, IncentivesListItem } from '../../../types';
import isString from 'lodash.isstring';
import isEmpty from 'lodash.isempty';

export function isValidData(
  x: unknown
): x is { [key in IncentiveDataKey]: SingleIncentiveData[IncentiveDataKey] } {
  return !isEmpty(x);
}

export type SingleIncentiveData = IncentivesListItem | IncentiveDetails;
export type StringTransformationFunction = (x: string) => string;
export type SingleDataKeyTransformationFunction = (
  x: SingleIncentiveData[IncentiveDataKey]
) => SingleIncentiveData[IncentiveDataKey];

export type IncentiveDataKey = keyof SingleIncentiveData;

export const ifEmptyReturnOriginalValue = (fn: SingleDataKeyTransformationFunction) =>
  function (x: SingleIncentiveData[IncentiveDataKey]): SingleIncentiveData[IncentiveDataKey] {
    if (isEmpty(x)) {
      return x;
    }

    return fn(x);
  };

export const runIfIsString = (fn: StringTransformationFunction) =>
  function (x: SingleIncentiveData[IncentiveDataKey]) {
    if (isString(x)) {
      return fn(x);
    }

    return x;
  };

export function performTransformation(
  x: unknown,
  keys: IncentiveDataKey[],
  fn: SingleDataKeyTransformationFunction
): { [key: string]: SingleIncentiveData[IncentiveDataKey] } | unknown {
  return keys.reduce((acc, key) => {
    if (!isValidData(acc) || !acc?.[key]) return acc;

    const originalValue = acc[key];

    acc[key] = ifEmptyReturnOriginalValue(fn)(originalValue);

    return acc;
  }, x);
}

export const capitalizeFirstLetter = (keys: IncentiveDataKey[]) =>
  function (x: unknown): { [key: string]: SingleIncentiveData[IncentiveDataKey] } | unknown {
    return performTransformation(x, keys, runIfIsString(capitalize));
  };

export const capitalizeFirstLetterOfStringsArray = (keys: IncentiveDataKey[]) =>
  function (x: unknown): { [key: string]: SingleIncentiveData[IncentiveDataKey] } | unknown {
    return performTransformation(x, keys, (x) => {
      if (Array.isArray(x)) {
        return (x as string[]).map(
          runIfIsString(capitalize)
        ) as SingleIncentiveData[IncentiveDataKey];
      }

      return x;
    });
  };

export const capitalizeFirstLetterOfFirstArrayItem = (keys: IncentiveDataKey[]) =>
  function (x: unknown): { [key: string]: SingleIncentiveData[IncentiveDataKey] } | unknown {
    return performTransformation(x, keys, (x) => {
      if (Array.isArray(x)) {
        return (x as string[]).map((x, index) =>
          index === 0 ? runIfIsString(capitalize)(x) : x.toLowerCase()
        ) as SingleIncentiveData[IncentiveDataKey];
      }

      return x;
    });
  };

export const replaceUnderlinesBySpaces = (x: string) => x.replace(/_/g, ' ');

export const underlinesToSpaces = (keys: IncentiveDataKey[]) =>
  function (x: unknown): { [key: string]: SingleIncentiveData[IncentiveDataKey] } | unknown {
    return performTransformation(x, keys, runIfIsString(replaceUnderlinesBySpaces));
  };

export const underlinesToSpacesOfStringsArray = (keys: IncentiveDataKey[]) =>
  function (x: unknown): { [key: string]: SingleIncentiveData[IncentiveDataKey] } | unknown {
    return performTransformation(x, keys, (x) => {
      if (Array.isArray(x)) {
        return (x as string[]).map(
          runIfIsString(replaceUnderlinesBySpaces)
        ) as SingleIncentiveData[IncentiveDataKey];
      }

      return x;
    });
  };

export const transformChangelogData =
  (changelogKeys: string[]) => (fns: SingleDataKeyTransformationFunction[]) =>
    function (x: unknown): { [key: string]: SingleIncentiveData[IncentiveDataKey] } | unknown {
      const data = x as unknown as { [key: string]: SingleIncentiveData[IncentiveDataKey] };
      return {
        ...(data || {}),
        changelog: ((data?.changelog as { [key: string]: unknown }[]) || [])?.map(
          (changelogItem: { [key: string]: unknown }) => {
            return Object.entries(changelogItem).reduce((acc, [key, value]) => {
              if (changelogKeys.includes(key)) {
                acc[key] = fns.reduce((acc, fn) => {
                  return fn(acc as string);
                }, value);
              } else {
                acc[key] = value;
              }

              return acc;
            }, {} as { [key: string]: unknown });
          }
        ),
      };
    };

export const DEFAULT_RESPONSE_TRANSFORMATIONS = [
  capitalizeFirstLetter(['grantor_type']),
  capitalizeFirstLetterOfStringsArray(['lease_beneficiary', 'eligible_organizations']),
  underlinesToSpaces(['incentive_description', 'incentive_scope_description']),
  underlinesToSpacesOfStringsArray(['eligible_organizations', 'eligible_project_types']),
  capitalizeFirstLetterOfFirstArrayItem(['eligible_organizations', 'eligible_project_types']),
  transformChangelogData(['changelog_description'])([runIfIsString(replaceUnderlinesBySpaces)]),
];
