import styled, { css } from 'styled-components';
import { resolveStyles } from '../../../utils/styled/resolveStyles';
import { StyledColorMarker } from '../ColorMarker/styled';

const fallback = css`
  text-align: left;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1.33;
`;

export const Styled = styled.td`
  ${resolveStyles({ fallback })}
`;

const styledTdContentFallback = css`
  display: inline-flex;
  align-items: center;

  ${StyledColorMarker} {
    margin-right: 0.5rem;
  }
`;

export const StyledTdContent = styled.div`
  ${resolveStyles({ fallback: styledTdContentFallback })}
`;

const styledColWithDateFallback = css`
  display: flex;
  flex-direction: column;
`;

export const StyledColWithDate = styled.div`
  ${resolveStyles({ fallback: styledColWithDateFallback })}
`;

const equipmentIconImageFallback = css`
  display: inline;

  & + img {
    margin-left: 1rem;
  }
`;

export const StyledEquipmentIconImage = styled.img`
  ${resolveStyles({ fallback: equipmentIconImageFallback })}
`;

const iconsContainerFallback = css`
  display: flex;
  align-items: baseline;
`;

export const StyledIconsContainer = styled.span`
  ${resolveStyles({ fallback: iconsContainerFallback })}
`;
