export const colors = {
  white: '#ffffff',
  black: '#000000',
  primary: '#0000ff',
  secondary: '#00ffff',
  neutral: '#808080',
  sky: '#00ffff',
  indigo: '#0000ff',
  text: '#a9a9a9',
};
