import styled, { css } from 'styled-components';
import { resolveStyles } from '../../../utils/styled/resolveStyles';
import { ColorMarkerProps } from './ColorMarker';

const fallback = css<ColorMarkerProps>`
  background-color: ${({ color }) => (color ? color : '#fafafa')};
  height: 0.75rem;
  width: 0.75rem;
  border-radius: 0.75rem;
  display: inline-block;
`;

export const StyledColorMarker = styled.span<ColorMarkerProps>`
  ${resolveStyles({ fallback })}
`;
