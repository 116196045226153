import styled, { css } from 'styled-components';
import { TypeSetting } from '../TypeSetting';
import { resolveStyles } from '../../../utils/styled/resolveStyles';
import { defaultFormItemPadding } from '../../../styles/fallbacks';
import { StyledIcon } from '../Icon/styled';

interface SelectProps {
  size?: string;
  isMobile?: boolean;
}

export const StyledSelect = styled.select<SelectProps>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  opacity: 0.01;
  width: auto;
  min-width: inherit;
  max-width: inherit;
  white-space: nowrap;
  z-index: 1;
  cursor: pointer;

  outline: 0;

  ${(p) =>
    p.isMobile &&
    css`
      width: 100%;
    `}
`;

const fallback = css`
  font-size: 0.875rem;
  color: #626262;
  background-color: #fff;

  button {
    ${defaultFormItemPadding}
    border: 1px solid #dbdbdb;
    border-radius: 4px;
    min-width: 4.875rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: inherit;
  }

  ${StyledIcon} {
    margin-left: 1rem;
  }
`;

export const SelectContainer = styled.div<SelectProps>`
  position: relative;
  display: inline-flex;

  :focus-within {
    box-shadow: 0px 2px 8px 0px #0000001a;
  }
  max-width: 100%;
  min-width: ${(p) =>
    p.size === 'large'
      ? '138px'
      : p.size === 'medium'
      ? '122px'
      : p.size === 'xl'
      ? '157px'
      : p.size === 'xxl'
      ? '259px'
      : p.size === 'xxxl'
      ? '332px'
      : '97px'};

  ${resolveStyles({ fallback })}
  ${(p) =>
    p.isMobile &&
    css`
      width: 100%;
    `}
`;

export const SelectedValueTypeSetting = styled(TypeSetting)``;

export const SelectedValueTypeSettingContainer = styled.button`
  border: 0;
  outline: none;
  width: 100%;
  background-color: transparent;
`;
