import React from 'react';
import { Copy } from '../Copy';
import { Localizable } from '../../../types';
import { StyledOption } from './styled';

export type SelectOptionOwnProps = Pick<HTMLOptionElement, 'value'> &
  Partial<Pick<HTMLOptionElement, 'selected' | 'disabled'>>;
export type SelectOptionProps = Localizable<SelectOptionOwnProps>;

export const SelectOption: React.FC<SelectOptionProps> = ({ i18n, ...htmlOptionProps }) => {
  return (
    <StyledOption {...htmlOptionProps}>
      <Copy i18n={i18n} />
    </StyledOption>
  );
};

SelectOption.displayName = 'SelectOption';
