import { useLayoutEffect } from 'react';
import debounce from 'lodash.debounce';

const DEBOUNCE_TIMING = 100;

export function useWindowResize(callback: () => void, shouldDebounce = true) {
  const debouncedCallback = shouldDebounce ? debounce(callback, DEBOUNCE_TIMING) : callback;

  useLayoutEffect(() => {
    window.addEventListener('resize', debouncedCallback);

    return () => window.removeEventListener('resize', debouncedCallback);
  }, [debouncedCallback]);
}
