import styled from 'styled-components';

interface ButtonProps {
  enabled?: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`;

export const Row = styled.div`
  width: auto;
  height: auto;
  display: flex;
`;

export const Button = styled.button<ButtonProps>`
  width: 182px;
  height: 40px;
  padding: 16px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(p) => (p.enabled ? '#007DBE' : 'white')};
  border: ${(p) => (p.enabled ? 'none' : '1px solid #DBDBDB')};
  color: ${(p) => (p.enabled ? 'white' : '#23262F')};
  cursor: pointer;

  :hover {
    opacity: 0.8;
  }
`;

export const Title = styled.p`
  font-family: Overpass;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  color: #000;
  margin-bottom: 10px;
`;

export const TooltipWrapper = styled.div`
  margin-left: 8px;
`;

export const TitleWrapper = styled.div`
  display: flex;
`;
