export function parseState(param: { name: string; value: string }): {
  name: string;
  value: string;
} {
  if (param.name === 'postcode' && param.value.length === 2) {
    if (/[a-zA-Z]/.test(param.value[0]) && /[a-zA-Z]/.test(param.value[1])) {
      return { ...param, name: 'region' };
    }
  } else if (param.name === 'household_income') {
    return { ...param, value: param.value.replace(/[^0-9.-]+/g, '') };
  }
  return param;
}
