import styled from 'styled-components';

interface RadioWrapperProps {
  isSelected: boolean;
}

interface TitleWrapperProps {
  isDetailed: boolean;
}

export const RadioWrapper = styled.div<RadioWrapperProps>`
  width: 338px;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 16px;

  min-height: 88px;
  max-height: 166px;
  background-color: #ffffff;
  color: #000;
  border: ${(p) => (p.isSelected ? '1px solid #007DBE' : '1px solid #DBDBDB')};

  cursor: pointer;
  position: relative;
`;

export const RadioInputCircle = styled.div<RadioWrapperProps>`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #007dbe;
  position: absolute;
  left: 16px;

  &::before {
    content: '';
    width: 16px;
    height: 16px;
    background-color: #007dbe;
    border-radius: 50%;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: ${(p) => (p.isSelected ? 'block' : 'none')};
  }
`;

export const TitleWrapper = styled.div<TitleWrapperProps>`
  font-family: Overpass;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #23262f;

  ${(p) =>
    p.isDetailed &&
    `
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    span {
      color: #777777;
      max-width: 18ch;
    }
  `}
`;
