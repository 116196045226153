import React from 'react';
import { I18nStructure } from '../../../types/Locale';
import { Translation } from '../Translation';

export type CopyProps = I18nStructure;

const translationDefaultValues: { [key: string]: string } = {};

function logTranslationDefaultValues({ id, fallback }: { id: string; fallback: string }) {
  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_LOG_TRANSLATION_DEFAULT_VALUES
  ) {
    translationDefaultValues[id] = fallback;
  }
}

export const Copy: React.FC<CopyProps> = ({ i18n }) => {
  logTranslationDefaultValues(i18n);

  return <Translation {...i18n} />;
};

Copy.displayName = 'Copy';
