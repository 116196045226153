import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { getUnifiedApiURL, getUnifiedApiToken } from '../../services/secrets';

export enum Secrets {
  UNIFIED_API_URL = 'UNIFIED_API_URL',
  UNIFIED_API_TOKEN = 'UNIFIED_API_TOKEN',
}

export function useSecret(secret: Secrets) {
  const [config, setConfig] = useState<{ name: string; func: () => Promise<string | undefined> }>({
    name: '',
    func: () => Promise.resolve(undefined),
  });

  useEffect(() => {
    switch (secret) {
      case Secrets.UNIFIED_API_URL:
        setConfig({ name: Secrets.UNIFIED_API_URL, func: getUnifiedApiURL });

        break;
      case Secrets.UNIFIED_API_TOKEN:
        setConfig({ name: Secrets.UNIFIED_API_TOKEN, func: getUnifiedApiToken });

        break;
      default:
        throw new Error(`Secret ${secret} is unknown`);
    }
  }, [secret]);

  return useQuery(config.name, config.func);
}
