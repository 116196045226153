import React, { useEffect } from 'react';
import { IncentiveData, IncentiveResultDesign } from './IncentivesResults.types';
import {
  StyledTable,
  StyledLoadingTd,
  StyledNavButton,
  StyledNavLocation,
  StyledResultsCount,
  StyledNavCol,
  StyledNavColContainer,
} from './styled';
import { IncentiveResult } from '../../molecules/IncentiveResult';
import { QueryStatus } from 'react-query';
import { TypeSetting } from '../../atoms/TypeSetting';
import { Spacer } from '../../atoms/Spacer';
import { Spinner } from '../../atoms/Spinner';
import { Warning } from '../../atoms/Warning';
import { Icon } from '../../atoms/Icon';
import chevronLeft from '../../../assets/icons/chevron-left-gray.svg';
import chevronRight from '../../../assets/icons/chevron-right-gray.svg';
import { Th } from '../Table';
import { LocalizedString } from '../../../types';
import { useParams } from 'react-router-dom';
import { COMMERCIAL } from '../../../constants/utils';

export type IncentivesResultsOwnProps = {
  status: QueryStatus;
  errorMesage: LocalizedString;
  data?: IncentiveData[];
  searchParams: { postcode?: string | undefined };
  id?: string;
  pagination: {
    currentPage: IncentiveData[];
    pages: IncentiveData[][];
    next: () => void;
    previous: () => void;
    current: number;
    isLastPage: boolean;
    isFirstPage: boolean;
  };
};
export type IncentivesResultsProps = IncentivesResultsOwnProps & IncentiveResultDesign;

export const IdleState: React.FC<Pick<IncentiveResultDesign, 'config'>> = ({
  config: {
    columns,
    states: {
      idle: { i18n },
    },
  },
}) => {
  return (
    <tr>
      <td colSpan={columns.length}>
        <Spacer pt={24}>
          <TypeSetting element="p" textAlign="center" color="#777777" i18n={i18n} />
        </Spacer>
      </td>
    </tr>
  );
};
IdleState.displayName = 'IdleState';

export const EmptyState: React.FC<
  Pick<IncentiveResultDesign, 'config'> & Pick<IncentivesResultsOwnProps, 'searchParams'>
> = ({
  config: {
    columns,
    states: {
      empty: { i18n },
    },
  },
  searchParams: { postcode },
}) => {
  return (
    <tr>
      <td colSpan={columns.length}>
        <Spacer pt={24}>
          <TypeSetting element="p" textAlign="center" color="#777777" i18n={i18n}>
            {' '}
            {postcode}
          </TypeSetting>
        </Spacer>
      </td>
    </tr>
  );
};
EmptyState.displayName = 'EmptyState';

export const LoadingState: React.FC<Pick<IncentiveResultDesign, 'config'>> = ({
  config: { columns },
}) => {
  return (
    <tr>
      <StyledLoadingTd colSpan={columns.length}>
        <Spacer py={160}>
          <Spinner />
        </Spacer>
      </StyledLoadingTd>
    </tr>
  );
};
LoadingState.displayName = 'LoadingState';

export const ErrorState: React.FC<Pick<IncentiveResultDesign, 'config'>> = ({
  config: {
    columns,
    states: {
      error: { i18n },
    },
    errorMesage,
  },
}) => {
  return (
    <tr>
      <td colSpan={columns.length}>
        <Spacer pt={12}>
          {errorMesage?.id ? (
            <Warning kind="error" i18n={errorMesage} />
          ) : (
            <Warning kind="error" i18n={i18n} />
          )}
        </Spacer>
      </td>
    </tr>
  );
};
ErrorState.displayName = 'ErrorState';

export const IncentivesResults: React.FC<IncentivesResultsProps> = ({
  status,
  data = [],
  config,
  errorMesage,
  pagination: {
    current: currentPageNumber,
    currentPage = [],
    pages = [],
    next: onMoveToNextPage,
    previous: onMoveToPreviousPage,
    isLastPage,
    isFirstPage,
  },
  searchParams,
  id,
}) => {
  useEffect(() => {
    if (!searchParams.postcode) return;

    if (/[a-zA-Z]/.test(searchParams.postcode[0])) {
      config.states.empty.i18n = {
        id: 'i18n.results.empty.canada',
        fallback: 'No provincial or local incentives apply to this zip code',
      };
    } else {
      config.states.empty.i18n = {
        id: 'i18n.results.empty',
        fallback: 'No state or local incentives apply to this zip code',
      };
    }
  }, [searchParams]);

  const { type } = useParams();
  const { columns } = config;

  config.errorMesage = errorMesage;
  return (
    <StyledTable
      id={id}
      dataStatus={status}
      cols={columns.length}
      data-columns={columns.length}
      slots={{
        header: (
          <tr>
            {columns.map(({ key, i18n }) => {
              return <Th key={key} i18n={i18n} />;
            })}
          </tr>
        ),
        footer:
          status === 'success' && data.length > 0 ? (
            <tr>
              <StyledResultsCount>
                <TypeSetting
                  i18n={{
                    id: config.states.success.i18n.id,
                    fallback:
                      data.length === 1
                        ? `${data.length} Incentive Result`
                        : `${data.length} Incentives Results`,
                    values: { count: data.length },
                  }}
                />
              </StyledResultsCount>
              <StyledNavCol colSpan={columns.length}>
                <StyledNavColContainer>
                  <StyledNavButton disabled={isFirstPage} onClick={onMoveToPreviousPage}>
                    <Icon src={chevronLeft} />
                    <TypeSetting i18n={config.pagination.controls.previous.i18n} />
                  </StyledNavButton>
                  <StyledNavLocation>
                    {currentPageNumber}{' '}
                    <TypeSetting i18n={config.pagination.controls.preposition.i18n} />{' '}
                    {pages.length}
                  </StyledNavLocation>
                  <StyledNavButton disabled={isLastPage} onClick={onMoveToNextPage}>
                    <TypeSetting i18n={config.pagination.controls.next.i18n} />
                    <Icon src={chevronRight} />
                  </StyledNavButton>
                </StyledNavColContainer>
              </StyledNavCol>
            </tr>
          ) : null,
      }}
    >
      {status === 'success' && data.length === 0 ? (
        <EmptyState searchParams={searchParams} config={config} />
      ) : status === 'success' && data.length > 0 ? (
        currentPage.map((incentive, index) => {
          const incentiveId =
            type === COMMERCIAL ? incentive.incentive_handle : String(incentive.id);

          return (
            <IncentiveResult
              columns={columns}
              data={incentive}
              id={incentiveId}
              key={`${incentiveId}-${index}`}
            />
          );
        })
      ) : status === 'loading' ? (
        <LoadingState config={config} />
      ) : status === 'error' ? (
        <ErrorState config={config} />
      ) : (
        <IdleState config={config} />
      )}
    </StyledTable>
  );
};

IncentivesResults.displayName = 'IncentivesResults';
