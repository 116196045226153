import React from 'react';
import { useCopy } from '../../../hooks/useCopy';
import { StyledInput } from './styled';
import { I18nStructure } from '../../../types';

export type InputOwnProps = Partial<
  Pick<HTMLInputElement, 'defaultValue' | 'name' | 'value' | 'id' | 'required'>
> & { placeholder: I18nStructure };
export type InputProps = InputOwnProps;

export const Input: React.FC<InputProps> = ({ placeholder: { i18n }, defaultValue, ...props }) => {
  const placeholder = useCopy(i18n);

  return <StyledInput defaultValue={defaultValue} placeholder={placeholder} {...props} />;
};

Input.displayName = 'Input';
