import { DesignWithRequiredConfig } from '../../../contexts';
import { Localizable, LocalizedString, I18nStructure, IncentivesListItem } from '../../../types';

export const IncentiveDataKeys = {
  incentive_handle: 'incentive_handle' as const,
  grantor_name: 'grantor_name' as const,
  grantor_type: 'grantor_type' as const,
  incentive_name: 'incentive_name' as const,
  incentive_description: 'incentive_description' as const,
  incentive_nature: 'incentive_nature' as const,
  incentive_program_contact_info: 'incentive_program_contact_info' as const,
  typical_amount: 'typical_amount' as const,
  typical_amount_description: 'typical_amount_description' as const,
  start_date: 'start_date' as const,
  expiration_date: 'expiration_date' as const,
  incentive_url: 'incentive_url' as const,
  incentive_application_url: 'incentive_application_url' as const,
  eligible_project_type_description: 'eligible_project_type_description' as const,
  eligible_project_types: 'eligible_project_types' as const,
  eligible_project_accessory: 'eligible_project_accessory' as const,
  eligible_organization_classification_description:
    'eligible_organization_classification_description' as const,
  eligible_organizations: 'eligible_organizations' as const,
  eligible_organization_other_description: 'eligible_organization_other_description' as const,
  incentive_focus: 'incentive_focus' as const,
  eligible_charging_technology_description: 'eligible_charging_technology_description' as const,
  eligible_charging_technology: 'eligible_charging_technology' as const,

  eligible_charging_requirements: 'eligible_charging_requirements' as const,
  eligible_vehicle_type_description: 'eligible_vehicle_type_description' as const,
  eligible_vehicle_type: 'eligible_vehicle_type' as const,
  eligible_vehicle_duties: 'eligible_vehicle_duties' as const,
  eligible_vehicle_weight_classes: 'eligible_vehicle_weight_classes' as const,
  eligible_fuel_types: 'eligible_fuel_types' as const,
  is_eligible_to_specified_form_factors_only: 'is_eligible_to_specified_form_factors_only' as const,
  is_lease_eligible: 'is_lease_eligible' as const,
  lease_beneficiary: 'lease_beneficiary' as const,
  eligible_scrappage_requirement: 'eligible_scrappage_requirement' as const,
  eligible_scrappage_requirement_description: 'eligible_scrappage_requirement_description' as const,
  eligibility_assumptions: 'eligibility_assumptions' as const,
  incentive_scope_description: 'incentive_scope_description' as const,
  incentive_scope: 'incentive_scope' as const,
  incentive_scope_other: 'incentive_scope_other' as const,
  incentive_calculation_methodology_description:
    'incentive_calculation_methodology_description' as const,
  funding_cap_per_project: 'funding_cap_per_project' as const,
  funding_cap_per_applicant: 'funding_cap_per_applicant' as const,
  mutually_exclusive_programs: 'mutually_exclusive_programs' as const,
  current_program_status: 'current_program_status' as const,
  current_funding_status: 'current_funding_status' as const,
  total_funding: 'total_funding' as const,
  current_funding: 'current_funding' as const,
  funding_situation_description: 'funding_situation_description' as const,
  changelog: 'changelog' as const,
  available: 'available' as const,
  translated_eligible_organizations: 'translated_eligible_organizations' as const,
  translated_eligible_project_types: 'translated_eligible_project_types' as const,
  support_for: 'support_for' as const,
  id: 'id' as const,
  amount_in_lease: 'amount_in_lease' as const,
  amount_in_purchase: 'amount_in_purchase' as const,
  point_of_sale: 'point_of_sale' as const,
} as const;

export interface Changelog {
  changelog_type: string;
  changelog_date: string;
  changelog_description: string;
  anticipated_changes: string;
  next_scheduled_review: string;
}

export type IncentiveData = IncentivesListItem;

export type IncentiveColumnDefinition = Localizable<{
  key: keyof typeof IncentiveDataKeys;
}>;

export type IncentiveResultConfig = {
  pagination: {
    itemsPerPage: number;
    controls: {
      preposition: {
        i18n: LocalizedString;
      };
      previous: {
        i18n: LocalizedString;
      };
      next: {
        i18n: LocalizedString;
      };
    };
  };
  states: {
    idle: { i18n: LocalizedString };
    empty: { i18n: LocalizedString };
    error: { i18n: LocalizedString };
    success: { i18n: LocalizedString; messages: I18nStructure[] };
  };
  columns: Array<IncentiveColumnDefinition>;
  errorMesage?: LocalizedString;
  maps: {
    canada: boolean;
    us: boolean;
  };
};

export type IncentiveResultDesign = DesignWithRequiredConfig<IncentiveResultConfig>;
