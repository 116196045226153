import React from 'react';
import i18next from 'i18next';
import { useTranslations } from '../../../contexts/Translations';

export interface FormattedMoneyProps {
  value: number;
}

export const FormattedMoney: React.FC<FormattedMoneyProps> = ({ value }) => {
  const { language } = useTranslations();
  let currencyValue = { currency: 'USD', locale: 'en-US', currencyDisplay: 'symbol' };

  if (language === 'fr') {
    currencyValue = { currency: 'USD', locale: 'fr-FR', currencyDisplay: 'narrowSymbol' };
  }

  const copy = i18next.t('i18n.base.empty.currency', {
    value,
    formatParams: {
      value: currencyValue,
    },
  });

  return <>{copy}</>;
};

FormattedMoney.displayName = 'FormattedMoney';
