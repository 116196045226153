import React, { PropsWithChildren } from 'react';
import { Styled } from './styled';

export type SpacerProps = {
  p?: number;
  px?: number;
  py?: number;
  pt?: number;
  pb?: number;
  pl?: number;
  pr?: number;
  m?: number;
  mx?: number;
  my?: number;
  mt?: number;
  mb?: number;
  ml?: number;
  mr?: number;
};

export const Spacer: React.FC<PropsWithChildren<SpacerProps>> = ({ children, ...props }) => {
  return <Styled {...props}>{children}</Styled>;
};

Spacer.displayName = 'Spacer';
