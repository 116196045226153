import styled, { css } from 'styled-components';
import { defaultFormItemPadding } from '../../../styles/fallbacks';
import { resolveStyles } from '../../../utils/styled/resolveStyles';

export const fallback = css`
  ${defaultFormItemPadding}
  border: 0;
  outline: 0;
  color: #263238;
  font-size: 1rem;
  border-bottom: 1px solid #263238;
  background-color: transparent;

  ::placeholder {
    color: inherit;
    font-size: inherit;
  }
`;

export const StyledInput = styled.input`
  ${resolveStyles({ fallback })}
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  width: 157px;
  font-size: 14px;
`;
