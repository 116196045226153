import styled, { css } from 'styled-components';
import { resolveStyles } from '../../../utils/styled/resolveStyles';
import { Typography } from '../Typography';
import { headerFallback } from '../../molecules/Box/styled';

const mainTitleFallback = css`
  ${headerFallback}

  margin-bottom: 1rem;

  @media screen and (min-width: 728px) {
    color: #000000;
    margin-bottom: 2.5rem;
    border-bottom: 0;
    padding: 0;
  }
`;

export const StyledTabContentMainTitle = styled(Typography)`
  ${resolveStyles({ fallback: mainTitleFallback })}
`;
