import styled, { css, keyframes } from 'styled-components';
import { SpinnerProps } from './Spinner';
import { resolveStyles } from '../../../utils/styled/resolveStyles';

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

const fallback = css<SpinnerProps>`
  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-repeat: no-repeat;
  height: ${({ height }) => `${height}px`};
  width: ${({ width }) => `${width}px`};
  display: inline-block;
  pointer-events: none;
  animation: ${rotate} 1s linear infinite;
`;

export const StyledSpinner = styled.span<SpinnerProps>`
  ${resolveStyles({ fallback })}
`;
