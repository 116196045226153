import isEmpty from 'lodash.isempty';
import { useCallback, useMemo, useEffect } from 'react';
import { ParamKeyValuePair, useParams, useSearchParams } from 'react-router-dom';

export const paramsAsRecord = (params: ParamKeyValuePair[] = []): Record<string, string> => {
  return params.reduce((acc, [name, value]) => {
    acc[name] = decodeURIComponent(value);

    return acc;
  }, {} as Record<string, string>);
};

export const paramsAsArray = (record: Record<string, string>) =>
  Object.entries(record).map(([name, value]) => {
    return { name, value };
  });

export const paramsStringToArray = (p = '') => {
  return p
    .replace(/^\?/, '')
    .split('&')
    .filter((x: string) => !isEmpty(x))
    .map((pair: string) => pair.split('=')) as ParamKeyValuePair[];
};

export const locationSearchAsRecord = () =>
  paramsAsRecord(paramsStringToArray(window.location.search));

export function useURLQuery(initialValue: ParamKeyValuePair[] = []) {
  const initialParams = isEmpty(initialValue) ? locationSearchAsRecord() : initialValue;
  const [params, setParams] = useSearchParams(initialParams);
  const { country } = useParams();

  const setParam = useCallback(
    ({ name, value }: { name: string; value: string }) => {
      const currentParams = paramsAsRecord(paramsStringToArray(params.toString()));
      if (currentParams[name] !== value) {
        setParams({ ...currentParams, [name]: value });
      }
    },
    [params, setParams]
  );

  useEffect(() => {
    const paramsObj = paramsAsRecord(paramsStringToArray(params.toString()));
    const zipcode = country === 'CA' ? 'M5V 1J1' : '95026';
    if (!('postcode' in paramsObj)) {
      setParams({ ...paramsObj, postcode: zipcode });
    }
  }, [params]);

  const paramsRecord = paramsAsRecord(paramsStringToArray(params.toString()));

  // @ts-ignore
  if (paramsRecord.postcode) {
    // @ts-ignore
    paramsRecord.postcode = paramsRecord.postcode.replace('+', ' ');
  }

  const paramsArray = useMemo(() => paramsAsArray(paramsRecord), [paramsRecord, params]);

  return {
    params,
    setParam,
    paramsRecord,
    paramsArray,
  };
}
