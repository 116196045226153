import React, { PropsWithChildren } from 'react';
import { StyledTable, StyledTableContainer } from './styled';
import { TableProps } from './Table.types';
import { TypeSetting, TypeSettingProps } from '../../atoms/TypeSetting';

export const Table: React.FC<PropsWithChildren<TableProps>> = ({ slots, children, ...props }) => {
  return (
    <StyledTableContainer {...props}>
      <StyledTable>
        {slots?.header && <thead>{slots?.header}</thead>}
        <tbody>{children}</tbody>
        {slots?.footer && <tfoot>{slots?.footer}</tfoot>}
      </StyledTable>
    </StyledTableContainer>
  );
};

Table.displayName = 'Table';

export const Th: React.FC<TypeSettingProps> = ({ i18n }) => (
  <TypeSetting
    element="th"
    i18n={i18n}
    textTransform="uppercase"
    color="#777777"
    scale="2"
    fontWeight="500"
    fontSize="14px"
    fontFamily="Overpass"
  />
);

Th.displayName = 'Th';
