import i18next, { InitOptions, Resource } from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { useEffect, useState } from 'react';
import { initReactI18next } from 'react-i18next';
import { QueryKey, QueryFunction, useQuery } from 'react-query';

export const EN = 'en';
export const ES = 'es';
export const FR = 'fr';

export type Language = typeof EN | typeof ES | typeof FR | string;
export const NAMESPACE = 'translations';
const i18nextOptions: InitOptions = {
  fallbackLng: EN,
  debug: false,
  ns: [NAMESPACE],
  defaultNS: NAMESPACE,
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
};

const resources: Resource = {
  [EN]: {
    [NAMESPACE]: {},
  },
  [ES]: {
    [NAMESPACE]: {},
  },
  [FR]: {
    [NAMESPACE]: {},
  },
};

export const i18nInitOptions: InitOptions = {
  resources,
  ...i18nextOptions,
};

export type GetResourcesFN = () => Promise<Resource | undefined>;

const getDefaultResources: GetResourcesFN = () => Promise.resolve(resources);

export function getUnitializedI18n() {
  return i18next.use(initReactI18next).use(intervalPlural);
}

export async function initI18n(getResources: GetResourcesFN = getDefaultResources) {
  try {
    const resources = await getResources();

    return getUnitializedI18n().init({
      ...i18nInitOptions,
      resources,
      interpolation: { escapeValue: false },
    });
  } catch (err) {
    console.error(`initI18n: ${err}`);
  }
}

export function gotResource(isSuccess: boolean, data: unknown): data is Resource {
  return isSuccess && Boolean(data);
}

export function useTranslationsResources(getResources: GetResourcesFN) {
  if (typeof getResources !== 'function') {
    throw new Error('`getResources` needs to be a function returning a Promise');
  }

  const { data, isSuccess, ...props } = useQuery<Resource>(
    NAMESPACE,
    getResources as QueryFunction<Resource, QueryKey>
  );
  const [hasLoadedTranslations, setHasLoadedTranslations] = useState(false);

  useEffect(() => {
    if (!gotResource(isSuccess, data)) return;

    Object.keys(data).forEach((lang, index) => {
      i18next.addResourceBundle(lang, NAMESPACE, data[lang][NAMESPACE]);

      if (index === Object.keys(data).length - 1) {
        setHasLoadedTranslations(true);
      }
    });
  }, [data, isSuccess]);

  return { data, isSuccess: isSuccess && hasLoadedTranslations, ...props };
}
