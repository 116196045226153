import React from 'react';
import { RadioWrapper, RadioInputCircle, TitleWrapper } from './styled';
import { Copy } from '../Copy';
import { ShoppingIcon, BusinessIcon } from '../../../assets/icons';

interface RadioProps {
  i18n: { id: string; fallback: string };
  isSelected: boolean;
  disabled?: boolean;
  onClick?: () => void;
  type?: string;
}

export const RadioInput: React.FC<RadioProps> = ({ i18n, isSelected, disabled, onClick, type }) => {
  const isDetailed = type === 'consumer' || type === 'commercial';

  const renderDetails = () => {
    switch (type) {
      case 'consumer':
        return (
          <>
            <ShoppingIcon />
            <Copy i18n={i18n} />
          </>
        );

      case 'commercial':
        return (
          <>
            <BusinessIcon />
            <Copy i18n={i18n} />
          </>
        );

      default:
        return <Copy i18n={i18n} />;
    }
  };

  return (
    <RadioWrapper isSelected={isSelected} onClick={onClick}>
      <RadioInputCircle isSelected={isSelected} />
      <TitleWrapper isDetailed={isDetailed}>{renderDetails()}</TitleWrapper>
    </RadioWrapper>
  );
};

RadioInput.displayName = 'RadioInput';
