import { useState, useEffect } from 'react';
import intersection from 'lodash.intersection';
import chunk from 'lodash.chunk';

export function usePagination<T>(
  content: T[] = [],
  { itemsPerPage, initialPage = 0 }: { itemsPerPage: number; initialPage?: number }
) {
  if (itemsPerPage <= 0) {
    throw new Error('`itemsPerPage` must be higher than 0');
  }

  const [source, setSource] = useState(content);
  const pages = chunk(source, itemsPerPage);
  const [current, setCurrent] = useState(initialPage);

  const next = () => {
    const newCurrent = current + 1 < pages.length ? current + 1 : current;

    if (newCurrent === current) return;

    setCurrent(newCurrent);
  };
  const previous = () => {
    const newCurrent = current - 1 >= 0 ? current - 1 : current;

    if (newCurrent === current) return;

    setCurrent(newCurrent);
  };

  useEffect(() => {
    const intersect = intersection(content, source);

    if (intersect.length !== source.length || intersect.length !== content.length) {
      setSource(content);
      setCurrent(0);
    }
  }, [content, source]);

  return {
    pages,
    current: current + 1,
    isFirstPage: current === 0,
    isLastPage: current === pages.length - 1,
    next,
    previous,
    currentPage: pages[current],
  };
}
