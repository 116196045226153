import React from 'react';
import { Outlet } from 'react-router-dom';
import { Header } from '../../molecules/Header';
import { Styled } from './styled';

export const Layout: React.FC = () => {
  return (
    <Styled>
      <Header />
      <Outlet />
    </Styled>
  );
};

Layout.displayName = 'Layout';
