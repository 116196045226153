/* eslint-disable @typescript-eslint/no-unused-vars */

import { USHeatMap } from '../../../../utils/heatmap/US';
import { useMemo, useState, useCallback, useRef, useEffect } from 'react';
import useHeatMapConfig from '../../../../utils/heatmap/hooks/useHeatMapConfig';
import useFetchMapData from '../../../../utils/heatmap/hooks/useFetchMapData';
import { useURLQuery } from '../../../../hooks';
import { useSearchParams } from 'react-router-dom';

interface FilterChangeParams {
  name: string;
  value: string;
}

interface FilterObject {
  name: string;
  value: string;
}
interface USAHeatMapProps {
  isConsumer: boolean;
  handleFilterChange: (params: FilterChangeParams) => void;
  setFilter: (filterObj: FilterObject) => void;
}

const USAHeatMap: React.FC<USAHeatMapProps> = ({ isConsumer, handleFilterChange, setFilter }) => {
  const [selectedState, setSelectedState] = useState<string>('');
  const { paramsRecord, paramsArray, setParam } = useURLQuery();
  const selectedStateRef = useRef<string>('');
  const clickOutsideEL = useRef<any>();
  const [searchParams] = useSearchParams();
  const urlPostcode = searchParams.get('postcode');

  const { mapData } = useFetchMapData();

  useEffect(() => {
    if (paramsRecord.postcode?.length === 2) {
      if (/[a-zA-Z]/.test(paramsRecord.postcode[0]) && /[a-zA-Z]/.test(paramsRecord.postcode[1])) {
        setSelectedState(paramsRecord.postcode);
        selectedStateRef.current = paramsRecord.postcode;
      }
    }
  }, [urlPostcode, paramsRecord.postcode]);

  useEffect(() => {
    if (clickOutsideEL.current) return;

    clickOutsideEL.current = document.addEventListener('click', (e) => {
      if (e.target instanceof Element) {
        if (e.target?.id === 'submitFormSearch') return;

        const tableDiv = document.getElementById('incentives-results');
        const incentivePage = document.getElementById('incentive-page');
        if (tableDiv && tableDiv.contains(e.target)) return;
        if (incentivePage && incentivePage.contains(e.target)) return;

        if (selectedStateRef.current) {
          setSelectedState('');
          selectedStateRef.current = '';
          setFilter({ name: 'postcode', value: '95026' });
          setParam({ name: 'postcode', value: '95026' });
          handleFilterChange({ name: 'postcode', value: '95026' });

          setTimeout(() => {
            document.getElementById('submitFormSearch')?.click();
          }, 300);
        }
      }
    });
  }, [handleFilterChange, setParam, setFilter]);

  // previous handle state click - check if can be better
  const handleStateClick = useCallback(
    (state: string, event: any) => {
      event.stopPropagation();
      event.preventDefault();
      setSelectedState(state);
      selectedStateRef.current = state;
      setFilter({ name: 'postcode', value: state });
      setParam({ name: 'postcode', value: state });
      handleFilterChange({ name: 'postcode', value: state });

      setTimeout(() => {
        document.getElementById('submitFormSearch')?.click();
      }, 300);
    },
    [handleFilterChange, setParam, setFilter]
  );

  // New hook to handle the heatmap config
  const { hooveredState, statesCustomConfig } = useHeatMapConfig({
    mapData: mapData,
    handleStateClick,
  });

  // Func to render the US HeatMap
  const renderUSAMap = useMemo(() => {
    return (
      <USHeatMap
        hooveredState={hooveredState}
        mapData={mapData}
        customize={statesCustomConfig}
        selectedState={selectedState}
      />
    );
  }, [statesCustomConfig, selectedState, mapData, hooveredState]);

  return <div>{renderUSAMap}</div>;
};

export default USAHeatMap;
