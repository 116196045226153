import { CANADA } from '../../constants/utils';
import { useUnifiedAPI } from '../useUnifiedAPI';
import { parseState } from '../utils';

export type VehiclesMake = 'Chevrolet' | 'GMC' | 'Cadillac';

export function useVehicles(
  additionalParams: { name: string; value: string }[],
  options: { enabled: boolean; country?: string; make?: VehiclesMake } = {
    enabled: false,
    country: 'us',
    make: 'Chevrolet',
  }
) {
  const { country, enabled, make } = options;
  const postcodeParam = additionalParams.find((param) => param.name === 'postcode') || {
    name: 'postcode',
    value: '',
  };
  const postcode =
    country === CANADA
      ? { ...postcodeParam, value: postcodeParam.value.replace(/\s+/g, '') }
      : postcodeParam;

  const params = [
    ...additionalParams.map((param) => parseState(param)),
    { name: 'availability', value: 'released,pre-order,upcoming' },
    { name: 'include_used_vehicles', value: 'true' },
    { name: 'country', value: country || 'us' },
    { name: 'make', value: make || 'Chevrolet' },
    postcode,
  ];

  return useUnifiedAPI(
    `commercial/vehicles`,
    params,
    { runDataTransformations: true },
    { enabled: enabled }
  );
}
