import React from 'react';
import { TypeSettingProps, TypeSetting } from '../TypeSetting';
import { Styled } from './styled';

export type TooltipTitleProps = TypeSettingProps;

export const TooltipTitle: React.FC<TooltipTitleProps> = ({ i18n }) => {
  return (
    <Styled>
      <TypeSetting i18n={i18n} textTransform="uppercase" scale="2" element="h2" fontWeight="500" />
    </Styled>
  );
};

TooltipTitle.displayName = 'TooltipTitle';
