import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import React from 'react';
import styled from 'styled-components';
import {
  TypographyStylingProps,
  TypographyThemeInterface,
} from '../../../interfaces/TypographyStylingProps';
import { Theme } from '../../../theme';
import { TypographyProps } from './Typography';

const TypographyHTMLElement: React.FC<
  TypographyProps & { theme: { [key: string]: unknown; typography: TypographyThemeInterface } }
> = ({
  element = 'span',
  children,
  color,
  fontFamily,
  fontSize,
  fontVariant,
  fontWeight,
  textAlign,
  textDecoration,
  textOverflow,
  lineHeight,
  textTransform,
  whiteSpace,
  variant,
  scale,
  theme,
  ...props
}) => {
  return React.createElement(element, props, children);
};

export const getAttributeValueFromOverrideOrTheme =
  (attr: keyof TypographyStylingProps, defaultOrNull: string | undefined | null = 'inherit') =>
  (_: TypographyProps & { theme: Theme }) =>
  ({ variant, scale, theme, ...props }: TypographyProps & { theme: Theme }) => {
    const prop = props[attr];
    const typography = theme?.typography;

    if (prop && attr === 'color') {
      return get(theme.colors, prop, prop);
    }

    if (prop) return prop;

    if (attr === 'fontSize') {
      return scale ? get(typography, ['scale', 'fontSizes', scale], defaultOrNull) : defaultOrNull;
    }

    const variants = !isEmpty(typography?.variants) ? typography?.variants : null;

    return variant ? get(variants, [variant, attr], defaultOrNull) : defaultOrNull;
  };

export const Styled = styled(TypographyHTMLElement)<TypographyProps>`
  color: ${getAttributeValueFromOverrideOrTheme('color')};
  font-family: ${getAttributeValueFromOverrideOrTheme('fontFamily')};
  font-size: ${getAttributeValueFromOverrideOrTheme('fontSize')};
  font-weight: ${getAttributeValueFromOverrideOrTheme('fontWeight')};
  text-align: ${getAttributeValueFromOverrideOrTheme('textAlign')};
  line-height: ${getAttributeValueFromOverrideOrTheme('lineHeight')};
  text-transform: ${getAttributeValueFromOverrideOrTheme('textTransform')};
  font-variant: ${getAttributeValueFromOverrideOrTheme('fontVariant', null)};
  text-overflow: ${getAttributeValueFromOverrideOrTheme('textOverflow', null)};
  text-decoration: ${getAttributeValueFromOverrideOrTheme('textDecoration', null)};
  white-space: ${getAttributeValueFromOverrideOrTheme('whiteSpace', null)};
`;
