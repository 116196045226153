import styled, { css } from 'styled-components';
import { resolveStyles } from '../../../utils/styled/resolveStyles';

const defaultFilterStyle = css`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;
  gap: 40px 20px;

  button {
    grid-column: span 2;
  }

  @media screen and (min-width: 1100px) {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    gap: 5px;
  }

  @media screen and (min-width: 1300px) {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    gap: 20px;
  }

  @media screen and (min-width: 1570px) {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    gap: 40px;

    > div ~ div,
    > div ~ button {
      margin-left: 16px;
    }
  }

  @media print {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    gap: 5px;
  }
`;

interface styledFormProps {
  isSearchView?: boolean;
}

export const StyledForm = styled.form<styledFormProps>``;

export const CopyField = styled.p`
  color: #626262;
  font-weight: 400;
  font-size: 11px;
  line-height: 14px;
  position: absolute;
  margin-top: 8px;
`;

export const FilterRow = styled.div<styledFormProps>`
  ${resolveStyles({ fallback: defaultFilterStyle })}
  ${(p) =>
    p.isSearchView &&
    css`
      flex-wrap: wrap;
      gap: 34px 16px;

      @media screen and (min-width: 768px) {
        > div ~ div,
        > div ~ button {
          margin-left: 0;
        }
      }
    `}
`;

export const ButtonFilter = styled.button`
  width: 100%;
  height: 55px;
  border: 1px solid #dbdbdb;
  background: #ffffff;
  color: #007dbe;
  font-family: Overpass;
  font-size: 14px;
  font-weight: 700;

  span {
    margin-right: 8px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  align-items: flex-end;
`;
