import styled, { css } from 'styled-components';
import { InlineImageProps } from './InlineImage';
import { resolveStyles } from '../../../utils/styled/resolveStyles';

const fallback = css<InlineImageProps>`
  height: ${({ height }) => (height ? height : '0.875rem')};
  width: ${({ width }) => (width ? width : '0.875rem')};
  margin-left: ${({ ml }) => (ml ? ml : undefined)};
  margin-right: ${({ mr }) => (mr ? mr : undefined)};
  display: inline-block;
`;

export const StyledInlineImage = styled.img<InlineImageProps>`
  ${resolveStyles({ fallback })}
`;
