import styled from 'styled-components';

interface CSSProps {
  margin?: string;
  mobileMargin?: string;
  width?: string;
  marginMobile?: string;
  borderBottom?: boolean;
}

export const Title = styled.div`
  p {
    text-transform: uppercase;
    margin: 0 0 46px;
  }

  @media screen and (max-width: 767px) {
    p {
      color: #777777;
      margin: 2px 0 16px;
    }
  }
`;

export const Row = styled.div<CSSProps>`
  margin: ${(p) => p.margin || '0'};
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  border-bottom: ${(p) => (p.borderBottom ? '1px solid #dfe0eb' : '')};

  @media screen and (max-width: 767px) {
    margin: ${(p) => p.mobileMargin || '0'};
    flex-direction: column;

    padding: ${(p) => (p.borderBottom ? '0 0 16px' : '')};
  }

  h5 {
    margin: 0 0 16px;
  }
`;

export const Column = styled.div<CSSProps>`
  margin: ${(p) => p.margin || '0'};
  width: ${(p) => p.width || 'auto'};

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (max-width: 767px) {
    margin: ${(p) => p.mobileMargin || '0'};
    width: 100%;

    h5 {
      margin: 0 0 8px;
    }
  }
`;
