import styled from 'styled-components';

export const FormBlock = styled.form`
  .MuiPaper-root {
    padding: 20px;

    .MuiGrid-root {
      justify-content: center;
      align-items: center;
    }

    .MuiButtonBase-root {
      width: 100%;
      margin: 20px 0 30px;
      background-color: #007dbe;
      color: #fff;

      border-radius: 0;
      font-weight: normal;
    }
  }
`;
