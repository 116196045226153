import React, { PropsWithChildren } from 'react';
import { TypographyProps } from '../Typography';
import { Copy, CopyProps } from '../Copy';
import { StyledTypography } from './styled';

export type TypeSettingProps = CopyProps & Partial<TypographyProps>;

export const TypeSetting: React.FC<PropsWithChildren<TypeSettingProps>> = ({
  i18n,
  element = 'span',
  onClick,
  children,
  ...props
}) => {
  return (
    <StyledTypography element={element} onClick={onClick} {...props}>
      <Copy i18n={i18n} />
      {children}
    </StyledTypography>
  );
};

TypeSetting.displayName = 'TypeSetting';
