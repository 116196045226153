import styled from 'styled-components';

interface columnProps {
  gap?: string;
}

export const Wrapper = styled.div`
  margin-top: 24px;
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
`;

export const Column = styled.div<columnProps>`
  display: flex;
  flex-direction: column;
  gap: ${(p) => p.gap};

  span {
    font-family: Overpass;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
  }

  h3 {
    font-family: Overpass;
    font-size: 16px;
    font-weight: 400;
    line-height: 44px;
    letter-spacing: 0em;
    text-align: left;
    span {
      font-family: Overpass;
      font-size: 16px;
      font-weight: 400;
      line-height: 44px;
      letter-spacing: 0em;
      text-align: left;
    }
  }
`;

export const MoreButton = styled.button`
  background: #fff;
  width: Hug (186px);
  height: Fixed (48px);
  padding: 15px 40px;
  border: 1px;
  border: 1px solid #dbdbdb;
  display: flex;

  color: #007dbe;
  font-family: Overpass;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;

  cursor: pointer;

  img {
    margin-right: 8px;
  }
`;

export const RowWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 35px 172px;
  justify-content: space-between;
`;
