import React from 'react';
import { TypeSetting, TypeSettingProps } from '../TypeSetting';
import { StyledWarning } from './styled';
import { Icon } from '../Icon';
import errorIcon from '../../../assets/icons/error.svg';

export type WarningOwnProps = { kind: 'error' };
export type WarningProps = WarningOwnProps & TypeSettingProps;

export const Warning: React.FC<WarningProps> = ({ i18n, kind }) => {
  return (
    <StyledWarning kind={kind}>
      {kind === 'error' ? <Icon src={errorIcon} /> : null} <TypeSetting i18n={i18n} />
    </StyledWarning>
  );
};

Warning.displayName = 'Warning';
