export const DEFAULT_CONFIG = {
  config: {
    topRow: {
      slots: {
        highlight: {
          key: 'typical_amount',
          i18n: {
            id: 'i18n.incentive.typical_amount',
            fallback: 'Potential Value',
          },
        },
        boxes: [
          {
            key: 'funding_cap_per_applicant',
            components: {
              tooltip: 'designs.incentives.filters.status.tooltip',
            },
            i18n: {
              id: 'i18n.incentive.current_program_status',
              fallback: 'Status',
            },
          },
          {
            key: 'grantor_name',
            components: undefined,
            i18n: {
              id: 'i18n.incentive.grantor_name',
              fallback: 'State',
            },
          },
          {
            key: 'typical_amount',
            components: undefined,
            i18n: {
              id: 'i18n.incentive.rebate',
              fallback: 'Rebate',
            },
          },
          {
            key: 'expiration_date',
            components: undefined,
            i18n: {
              id: 'i18n.incentive.expiration_date',
              fallback: 'Deadline',
            },
          },
        ],
      },
    },
    tabs: [
      {
        id: 'overview',
        i18n: {
          id: 'i18n.incentive.tab.overview',
          fallback: 'Overview',
        },
      },
      {
        id: 'eligibility',
        i18n: {
          id: 'i18n.incentive.tab.eligibility',
          fallback: 'Eligibility',
        },
      },
      {
        id: 'funding',
        i18n: {
          id: 'i18n.incentive.tab.funding',
          fallback: 'Funding',
        },
      },
      {
        id: 'changelog',
        i18n: {
          id: 'i18n.incentive.tab.changelog',
          fallback: 'Changelog',
        },
      },
    ],
  },
};
