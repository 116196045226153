import React, { useState } from 'react';
import { Wrapper, Button, Row, Title, TooltipWrapper, TitleWrapper } from './styled';
import { Copy } from '../../atoms/Copy';
import { Tooltip } from '../../organisms/Tooltip';
import { DesignKey } from '../../../contexts';

interface SwitchProps {
  options: [{ id: string; fallback: string }, { id: string; fallback: string }];
  onSwitch?: (option: { id: string; fallback: string }) => void;
  title: { id: string; fallback: string };
  defaultOption?: { id: string; fallback: string };
  tooltipContent?: Array<[string, DesignKey]>;
}

const Switch: React.FC<SwitchProps> = ({
  options,
  onSwitch,
  title,
  defaultOption,
  tooltipContent,
}) => {
  const [selectedOption, setSelectedOption] = useState(defaultOption ? defaultOption : options[0]);

  const handleSwitch = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    const newOption = selectedOption.id === options[0].id ? options[1] : options[0];
    setSelectedOption(newOption);
    if (onSwitch) {
      onSwitch(newOption);
    }
  };

  return (
    <Wrapper>
      <TitleWrapper>
        <Title>
          <Copy i18n={title} />
        </Title>
        {tooltipContent && (
          <TooltipWrapper>
            <Tooltip content={tooltipContent} toggleOnHover modalXPosition={-100} />
          </TooltipWrapper>
        )}
      </TitleWrapper>
      <Row>
        {options.map((option) => (
          <Button
            key={option.id}
            onClick={(e) => handleSwitch(e)}
            enabled={selectedOption.id === option.id}
          >
            <Copy i18n={option} />
          </Button>
        ))}
      </Row>
    </Wrapper>
  );
};

export default Switch;
