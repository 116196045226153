import React from 'react';
import { TypeSetting } from '../../atoms/TypeSetting';
import { Typography } from '../../atoms/Typography';
import { IncentiveDetailTabProps } from '../IncentiveDetailTab';
import { Table } from '../Table';
import { Column, Item, Row, Title } from './styled';
import { FormattedMoney } from '../../atoms/FormattedMoney';

export type IncentiveDetailFundingTabProps = Pick<IncentiveDetailTabProps, 'data'>;

export const IncentiveDetailFundingTab: React.FC<IncentiveDetailFundingTabProps> = ({ data }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return (
    <Table>
      <Title>
        <TypeSetting
          i18n={{ id: 'i18n.funding.title', fallback: 'FUNDING DETAILS' }}
          scale="3"
          fontWeight="600"
        />
      </Title>
      <Item borderTop>
        <Column>
          <Row>
            <Column margin="0 38px 0 0">
              <TypeSetting
                i18n={{ id: 'i18n.funding.totalfunding', fallback: 'Total Funding' }}
                scale="4"
                fontWeight="600"
                element="h5"
              />
              <Typography element="p" scale="4" fontWeight="400">
                {data.total_funding ? formatter.format(data.total_funding) : '-'}
              </Typography>
            </Column>

            <Column>
              <TypeSetting
                i18n={{ id: 'i18n.funding.currentfunding', fallback: 'Current Funding' }}
                scale="4"
                fontWeight="600"
                element="h5"
              />
              <Typography element="p" scale="4" fontWeight="400">
                {data.current_funding ? formatter.format(data.current_funding) : '-'}
              </Typography>
            </Column>
          </Row>
        </Column>
      </Item>

      <Item>
        <Column>
          <TypeSetting
            i18n={{
              id: 'i18n.funding.fundingsituation',
              fallback: 'Funding Situation Description',
            }}
            scale="4"
            fontWeight="600"
            element="h5"
          />
          <Typography element="p" scale="4" fontWeight="400">
            {data.funding_situation_description || (
              <TypeSetting
                i18n={{
                  id: 'i18n.common.na',
                  fallback: 'N/A',
                }}
              />
            )}
          </Typography>
        </Column>
      </Item>

      <Item>
        <Column>
          <TypeSetting
            i18n={{
              id: 'i18n.funding.methodologydescription',
              fallback: 'Methodology Description',
            }}
            scale="4"
            fontWeight="600"
            element="h5"
          />
          <Typography element="p" scale="4" fontWeight="400">
            {data.incentive_calculation_methodology_description || (
              <TypeSetting
                i18n={{
                  id: 'i18n.common.na',
                  fallback: 'N/A',
                }}
              />
            )}
          </Typography>
        </Column>
      </Item>

      <Item>
        <Column>
          <TypeSetting
            i18n={{ id: 'i18n.funding.capperproject', fallback: 'Funding Cap per Project' }}
            scale="4"
            fontWeight="600"
            element="h5"
          />
          <Typography element="p" scale="4" fontWeight="400">
            {data.funding_cap_per_project || (
              <TypeSetting
                i18n={{
                  id: 'i18n.common.na',
                  fallback: 'N/A',
                }}
              />
            )}
          </Typography>
        </Column>
      </Item>

      <Item noBorder>
        <Column>
          <TypeSetting
            i18n={{ id: 'i18n.funding.capperapplicant', fallback: 'Funding Cap per Applicant' }}
            scale="4"
            fontWeight="600"
            element="h5"
          />
          <Typography element="p" scale="4" fontWeight="400">
            {<FormattedMoney value={data?.funding_cap_per_applicant} /> || (
              <TypeSetting
                i18n={{
                  id: 'i18n.common.na',
                  fallback: 'N/A',
                }}
              />
            )}
          </Typography>
        </Column>
      </Item>
    </Table>
  );
};

IncentiveDetailFundingTab.displayName = 'IncentiveDetailFundingTab';
