import React from 'react';
import { IncentiveDetailsBoxProps } from '../IncentiveDetailsBox';
import { BoxBody, BoxHighlightedText } from '../../molecules/Box';
import { FormattedMoney } from '../../atoms/FormattedMoney';
import { TypeSetting } from '../../atoms/TypeSetting';

export type IncentiveDetailsBoxFundingCapProps = Pick<IncentiveDetailsBoxProps, 'data'>;

export const IncentiveDetailsBoxFundingCap: React.FC<IncentiveDetailsBoxFundingCapProps> = ({
  data,
}) => {
  return (
    <BoxBody>
      {data?.funding_cap_per_applicant && typeof data?.funding_cap_per_applicant === 'number' && (
        <div>
          <BoxHighlightedText>
            <FormattedMoney value={data?.funding_cap_per_applicant} />
          </BoxHighlightedText>
          <div>
            <TypeSetting scale="2" i18n={{ id: 'i18n.funding.remaining', fallback: 'remaining' }} />
          </div>
        </div>
      )}
    </BoxBody>
  );
};

IncentiveDetailsBoxFundingCap.displayName = 'IncentiveDetailsBoxFundingCap';
