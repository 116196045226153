import React from 'react';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';

/**
 * Had to copy/paste the Locale type from date-fns
 * because it is not being exported by it,
 * I also had to substitute all `any`s for `unknown` because of eslint
 */
type Locale = {
  code?: string;
  formatDistance?: (...args: Array<unknown>) => unknown;
  formatRelative?: (...args: Array<unknown>) => unknown;
  localize?: {
    ordinalNumber: (...args: Array<unknown>) => unknown;
    era: (...args: Array<unknown>) => unknown;
    quarter: (...args: Array<unknown>) => unknown;
    month: (...args: Array<unknown>) => unknown;
    day: (...args: Array<unknown>) => unknown;
    dayPeriod: (...args: Array<unknown>) => unknown;
  };
  formatLong?: {
    date: (...args: Array<unknown>) => unknown;
    time: (...args: Array<unknown>) => unknown;
    dateTime: (...args: Array<unknown>) => unknown;
  };
  match?: {
    ordinalNumber: (...args: Array<unknown>) => unknown;
    era: (...args: Array<unknown>) => unknown;
    quarter: (...args: Array<unknown>) => unknown;
    month: (...args: Array<unknown>) => unknown;
    day: (...args: Array<unknown>) => unknown;
    dayPeriod: (...args: Array<unknown>) => unknown;
  };
  options?: {
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6;
    firstWeekContainsDate?: 1 | 2 | 3 | 4 | 5 | 6 | 7;
  };
};
export type FormattedDateProps = {
  date: string;
  stringFormat?: string;
  config?: {
    locale?: Locale | undefined;
    weekStartsOn?: 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;
    firstWeekContainsDate?: number | undefined;
    useAdditionalWeekYearTokens?: boolean | undefined;
    useAdditionalDayOfYearTokens?: boolean | undefined;
  };
};

const DEFAULT_CONFIG = {
  locale: enUS,
};

const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const FormattedDate: React.FC<FormattedDateProps> = ({
  date,
  stringFormat = 'MMMM do, uuuu',
  config = DEFAULT_CONFIG,
}) => {
  return <>{capitalizeFirstLetter(format(new Date(date), stringFormat, config))}</>;
};

FormattedDate.displayName = 'FormattedDate';
