import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import isEmpty from 'lodash.isempty';
import isEqual from 'lodash/isEqual';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { Filter } from '../../organisms/Filter';
import { useFilters, useIncentivesApi, usePagination, useURLQuery } from '../../../hooks';
import { ContentWrapper } from '../../atoms/ContentWrapper';
import { Trans } from 'react-i18next';
import { Title } from '../../atoms/Title';
import { SubTitle } from '../../atoms/SubTitle';
import { Copy } from '../../atoms/Copy';
import { Main } from '../../atoms/Main';
import { Spacer } from '../../atoms/Spacer';
import { IncentivesResults } from '../../organisms/IncentivesResults';
import {
  IncentiveData,
  IncentiveResultDesign,
} from '../../organisms/IncentivesResults/IncentivesResults.types';
import { WithConfig } from '../../HOCs/WithConfig';
import { DEFAULT_CONFIG } from './Incentives.defaults';
import {
  Line,
  LineWrapper,
  SmallWarningContainer,
  SmallWarningDialog,
  SmallWarningWrapper,
  Styled,
  IncentiveContainer,
  WarningContainer,
  WarningDialog,
  PrintTimeStamp,
  StyledHeaderIconsContainer,
  IconContainer,
  FooterLogoContainer,
} from './styled';
import PrintModal from '../../organisms/PrintModal';
import { incentivesFilters } from '../../../utils/incentivesFilters';
import { IncentiveList, LocalizedString } from '../../../types';
import { useTranslations } from '../../../contexts/Translations';
import { QueryStatus } from 'react-query';
import { validZipcode } from '../../../data/samples/validZipcode';
import { TypeSetting } from '../../atoms/TypeSetting';
import { useDispatch, useSelector } from 'react-redux';
import USAHeatMap from '../../organisms/HeatMaps/USAHeatMap';
import CanadaHeatMap from '../../organisms/HeatMaps/CanadaHeatMap';
import {
  CANADA,
  COMMERCIAL,
  CONSUMER,
  USA,
  HEATMAP_INCENTIVE,
  SEARCH_INCENTIVE,
  LEASE,
} from '../../../constants/utils';
import { WarningIcon, CashierIcon, CurrencyIcon, CheckIcon } from '../../../assets/icons';
import Switch from '../../molecules/Switch/Switch';
import poweredByJDPLogo from '../../../assets/powered-by-jdp-logo.svg';
import poweredByJDPLogoFrench from '../../../assets/powered-by-jdp-logo-french.svg';
import { updaters } from '../../AwsAmplifyHelper/userAuthenticationSlice';

const INITIALLY_APPLIED_FILTERS = {
  incentive_focus: { name: 'incentive_focus', value: 'chargers' },
};

const REQUIRED_PARAM_NAMES = ['postcode'];
const REQUIRED_CONSUMER_PARAM_NAMES = ['postcode'];

export const hasAllRequiredParams = (params: { name: string; value: string }[]) =>
  REQUIRED_PARAM_NAMES.every((name) =>
    params
      .filter(({ name }) => REQUIRED_PARAM_NAMES.includes(name))
      .map(({ name }) => name)
      .includes(name)
  );

export const getInitialFiltersFromParamsRecord = (paramsRecord: { [key: string]: string }) => {
  return Object.entries(paramsRecord).reduce((acc, entry) => {
    const [name, value] = entry as [string, string];
    acc[name] = { name, value };

    return acc;
  }, {} as { [key: string]: { name: string; value: string } });
};

export const IncentivesBase: React.FC<IncentiveResultDesign> = ({ config }) => {
  const state = useSelector((state: any) => state.userSlice);
  const { paramsRecord, paramsArray, setParam } = useURLQuery();
  const history = useNavigate();
  const { country, type } = useParams();
  const [params, setParams] = useState<{ name: string; value: string }[]>(paramsArray);
  const [apiParams, setApiParams] = useState<{ name: string; value: string }[]>(paramsArray);
  const [searchedPostcode, setSearchedPostcode] = useState<string | undefined>();
  const [isQueryEnabled, setIsQueryEnabled] = useState(false);
  const [errorMesage, setErrorMesage] = useState<LocalizedString>({ id: '', fallback: '' });
  const [invalidZip, setInvalidZip] = useState<QueryStatus | null>(null);
  const { language } = useTranslations();

  const [isTranslate, setIsTranslated] = useState(true);

  const [useResult, setUseResult] = useState<any>(null);
  const [useIsLoading, setUseIsLoading] = useState<any>(null);
  const [useStatus, setUseStatus] = useState<any>(null);
  const [useError, setUseError] = useState<any>(null);
  const [filterParams, setFilterParams] = useState<any>(INITIALLY_APPLIED_FILTERS);
  const [currentConsumerView, setCurrentConsumerView] = useState<string>(
    state.consumerView || SEARCH_INCENTIVE
  );
  const dispatch = useDispatch();

  const [totalIncentives, setTotalIncentives] = useState<number>(0);
  const [availableAtSale, setAvailableAtSale] = useState<number>(0);
  const [applicableCount, setApplicableCount] = useState<number>(0);

  useEffect(() => {
    if (JSON.stringify(params) !== JSON.stringify(apiParams)) {
      setApiParams(params);
    }
  }, [params]);

  useEffect(() => {
    dispatch(updaters.consumerView(currentConsumerView));
  }, [currentConsumerView]);

  const incentiveOptions =
    type === CONSUMER
      ? {
          enabled: isQueryEnabled,
          country: country,
          type: type,
          handle: state?.selectedVehicle?.handle ?? '',
        }
      : {
          enabled: isQueryEnabled,
          country: country,
          type: type,
        };

  const { data: result, isLoading, status, error } = useIncentivesApi(apiParams, incentiveOptions);

  const filterParamsRecord = useMemo(() => paramsRecord, [paramsRecord]);
  const prevParamsRecordRef = useRef();

  useEffect(() => {
    if (!isEqual(prevParamsRecordRef.current, paramsRecord)) {
      if (isEmpty(paramsRecord)) {
        setFilterParams(INITIALLY_APPLIED_FILTERS);
      } else {
        setFilterParams(getInitialFiltersFromParamsRecord(paramsRecord));
      }
      // @ts-ignore
      prevParamsRecordRef.current = paramsRecord;
    }
  }, [filterParamsRecord]);

  const {
    data: incentives,
    setFilter,
    evaluatedLoading,
    evaluatedStatus,
  } = useFilters(
    (useResult?.data?.incentives as IncentiveList) ||
      (useResult?.data?.vehicle.incentives as IncentiveList) ||
      [],
    filterParams,
    // @ts-ignore
    incentivesFilters,
    !!incentiveOptions.handle
  );

  useEffect(() => {
    let totalIncentives = 0,
      availableAtSale = 0,
      applicableCount = 0;
    const isPurchaseTypeLease = state.consumerTableView === LEASE;

    incentives.map((incentive) => {
      const amount = isPurchaseTypeLease
        ? incentive.evaluation?.amount_in_lease || 0
        : incentive.evaluation?.amount_in_purchase || 0;

      totalIncentives += amount;

      if (incentive?.point_of_sale) {
        availableAtSale += amount;
      }

      if (incentive.evaluation && incentive.evaluation.eligibility === 'eligible') {
        applicableCount += 1;
      }

      return amount;
    });

    setTotalIncentives(totalIncentives);
    setAvailableAtSale(availableAtSale);
    setApplicableCount(applicableCount);
  }, [incentives, state.consumerTableView]);

  useEffect(() => {
    setParams(paramsArray);
  }, [paramsArray]);

  useEffect(() => {
    setUseResult(result);
    setUseIsLoading(isLoading);
    setUseError(error);

    if (evaluatedLoading) {
      setUseStatus(evaluatedStatus);
    } else {
      setUseStatus(status);
    }
  }, [params, result, evaluatedLoading]);

  useEffect(() => {
    if (useIsLoading) {
      setUseResult(result);
      setUseIsLoading(isLoading);
      setUseStatus(status);
      setUseError(error);
    }
  }, [isLoading]);

  useEffect(() => {
    if (useError) {
      setErrorMesage({ id: 'i18n.base.error.zip', fallback: '' });
    } else {
      setErrorMesage({ id: '', fallback: '' });
    }
  }, [useError]);

  useEffect(() => {
    if (!incentives) return;
    if (language === 'en') return setIsTranslated(true);

    let result = true;
    incentives.forEach((incentive) => {
      if (incentive.lang !== 'fr') {
        result = false;
      }
    });

    setIsTranslated(result);
  }, [incentives, language]);

  const { pages, currentPage, current, next, previous, isLastPage, isFirstPage } =
    usePagination<IncentiveData>(incentives, {
      itemsPerPage: config.pagination.itemsPerPage,
    });

  const handleFilterChange = useCallback(
    ({ name, value }: { name: string; value: string }) => {
      const isStateCode = /^[A-Z]{2}$/.test(value);
      if (
        name === 'postcode' &&
        value.length > 4 &&
        !/[a-zA-Z]/.test(value[0]) &&
        !validZipcode.includes(value) &&
        !isStateCode
      ) {
        setInvalidZip('error');
        setErrorMesage({ id: 'i18n.base.error.zip', fallback: '' });
      }

      if (
        name === 'postcode' &&
        !isStateCode &&
        ((country === USA && value.length < 5) || (country === CANADA && value.length < 7))
      ) {
        return;
      }

      setFilter({ name, value });
      setParam({ name, value });
      setInvalidZip(null);
      setErrorMesage({ id: '', fallback: '' });
    },
    [setFilter, setParam]
  );

  const handleSubmit = (
    params: { name: string; value: string }[],
    e: React.FormEvent<HTMLFormElement> | undefined
  ) => {
    e?.stopPropagation();
    setInvalidZip(null);
    setErrorMesage({ id: '', fallback: '' });
    setParams(params);
    setIsQueryEnabled(true);
    setSearchedPostcode(params.find(({ name }) => name === 'postcode')?.value);
  };

  const addURLParam = useCallback(
    ({ name, value }: { name: string; value: string }) => {
      if (isEmpty(paramsRecord[name])) {
        setParam({ name, value });
        setParams((params) =>
          params.filter(({ name: paramName }) => name !== paramName).concat({ name, value })
        );
      }
    },
    [setParam, paramsRecord]
  );

  const filter = useMemo(
    () => (
      <Filter
        onSubmit={handleSubmit}
        onChange={handleFilterChange}
        isLoading={useIsLoading}
        initialValuesFromURLParams={paramsRecord}
        addURLParam={addURLParam}
        requiredParamNames={REQUIRED_PARAM_NAMES}
        setInvalidZip={setInvalidZip}
        setErrorMesage={setErrorMesage}
        vehicleSelected={!!incentiveOptions.handle && incentiveOptions.handle !== ''}
      />
    ),
    [
      paramsRecord,
      handleFilterChange,
      useIsLoading,
      addURLParam,
      type,
      currentConsumerView,
      incentiveOptions,
    ]
  );

  useEffect(() => {
    if (isQueryEnabled && !useIsLoading) {
      setIsQueryEnabled(false);
    }
  }, [useIsLoading, isQueryEnabled]);

  useEffect(() => {
    const paramsNames = paramsArray.map(({ name }: { name: string; value: string }) => name);
    const requiredParams =
      type === 'consumer' ? REQUIRED_CONSUMER_PARAM_NAMES : REQUIRED_PARAM_NAMES;

    if (
      !isEmpty(paramsRecord) &&
      requiredParams.every((required) => paramsNames.includes(required))
    ) {
      setIsQueryEnabled(true);
    }
  }, [paramsRecord, paramsArray]);

  const RenderedMap = useMemo(() => {
    const commonProps = {
      isConsumer: type === 'consumer',
      handleFilterChange: handleFilterChange,
      setFilter: setFilter,
    };

    if (country === 'US' && config?.maps?.us) {
      return <USAHeatMap {...commonProps} />;
    } else if (country === 'CA' && config?.maps?.canada) {
      return <CanadaHeatMap {...commonProps} />;
    } else {
      return null;
    }
  }, [country, type, config]);

  useEffect(() => {
    const isDev = window.location.hostname === 'localhost';
    if (!isDev && !state.accessToken) {
      history('/');
    }
  }, [state.accessToken]);

  const isCommercial = type === COMMERCIAL;

  const showMap = type === CONSUMER ? currentConsumerView === HEATMAP_INCENTIVE : true;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = today.getFullYear();
    return `${month}/${day}/${year}`;
  };

  let i18nPrinted = {
    id: 'i18n.printed_format',
    fallback: 'Printed on',
  };

  return (
    <Styled>
      <Main>
        <ContentWrapper flex={false}>
          <StyledHeaderIconsContainer>
            {!isLoading ? (
              <>
                <IconContainer>
                  <PrintModal />
                </IconContainer>
              </>
            ) : null}
          </StyledHeaderIconsContainer>
        </ContentWrapper>
        <ContentWrapper flex={false}>
          <PrintTimeStamp>
            <p>
              <Copy i18n={i18nPrinted} /> {getCurrentDate()}
            </p>
          </PrintTimeStamp>

          <IncentiveContainer>
            <Outlet />
            <div>
              <Title
                i18n={
                  isCommercial
                    ? { id: 'i18n.pages.incentives.title', fallback: 'Commercial Incentives' }
                    : {
                        id: 'i18n.pages.incentives.title_consumer',
                        fallback: 'Consumer Incentives',
                      }
                }
              />
              <SubTitle
                i18n={{
                  id: 'i18n.pages.incentives.subtitle',
                  fallback:
                    'Search for incentives that may reduce the cost of electric vehicles and charging equipment.',
                }}
              />
            </div>
            {!isCommercial && (
              <Switch
                options={[
                  { id: 'i18n.incentives.switch.options.search', fallback: 'Incentive Search' },
                  { id: 'i18n.incentives.switch.options.heatmap', fallback: 'Incentive Heatmap' },
                ]}
                title={{ id: 'i18n.incentives.switch.title.view', fallback: 'Incentive View' }}
                onSwitch={(e) => setCurrentConsumerView(e.fallback)}
                defaultOption={
                  {
                    id: `i18n.incentives.switch.options.${
                      currentConsumerView.includes('Search') ? 'search' : 'heatmap'
                    }`,
                    fallback: currentConsumerView,
                  } || { id: 'i18n.incentives.switch.options.search', fallback: 'Incentive Search' }
                }
                tooltipContent={[['incentive.view', 'designs.incentives.switch.view']]}
              />
            )}
          </IncentiveContainer>
        </ContentWrapper>

        <LineWrapper>
          <Line />
        </LineWrapper>

        {!isCommercial && (
          <ContentWrapper flex={false}>
            {' '}
            <Spacer pt={0}>{filter}</Spacer>{' '}
          </ContentWrapper>
        )}

        <ContentWrapper flex={false}>{showMap && RenderedMap}</ContentWrapper>

        {incentiveOptions.handle ? (
          <ContentWrapper flex={false}>
            <SmallWarningWrapper>
              <SmallWarningContainer>
                <SmallWarningDialog>
                  <CurrencyIcon />
                  <div className="column">
                    <TypeSetting
                      i18n={{
                        id: 'i18n.incentives.total_incentives',
                        fallback: 'Total Incentives',
                      }}
                      element="p"
                      className="title"
                    />
                    <p className="subtitle">{formatter.format(totalIncentives)}</p>
                  </div>
                </SmallWarningDialog>
              </SmallWarningContainer>
              <SmallWarningContainer>
                <SmallWarningDialog>
                  <CashierIcon />
                  <div className="column">
                    <TypeSetting
                      i18n={{
                        id: 'i18n.incentives.total_sale',
                        fallback: 'Available at salesss',
                      }}
                      element="p"
                      className="title"
                    />
                    <p className="subtitle">{formatter.format(availableAtSale)}</p>
                  </div>
                </SmallWarningDialog>
              </SmallWarningContainer>
              <SmallWarningContainer>
                <SmallWarningDialog>
                  <CheckIcon />
                  <div className="column">
                    <TypeSetting
                      i18n={{
                        id: 'i18n.incentives.total_applicable',
                        fallback: 'Applicable Incentive Count',
                      }}
                      element="p"
                      className="title"
                    />
                    <p className="subtitle">{applicableCount}</p>
                  </div>
                </SmallWarningDialog>
              </SmallWarningContainer>
            </SmallWarningWrapper>
          </ContentWrapper>
        ) : null}

        {!isCommercial && (
          <WarningContainer>
            <WarningDialog>
              <WarningIcon />
              <p>
                <Trans
                  i18nKey="'i18n.incentives.warning_dialog"
                  components={{
                    br: <br />,
                  }}
                >
                  {
                    'For specific incentive evaluations, select the customer situational option from the toggle above.<br/> Note: Potential incentive totals above exclude non-monetary benefits and charger-based incentives which may appear in results below.'
                  }
                </Trans>
              </p>
            </WarningDialog>
          </WarningContainer>
        )}

        <ContentWrapper flex={false}>
          {isCommercial && <Spacer pt={0}>{filter}</Spacer>}
          {!isTranslate && (
            <Spacer pt={32}>
              <TypeSetting
                i18n={{
                  id: 'i18n.incentives.not_available',
                  fallback: 'Incentives are not available in the selected language',
                }}
                element="p"
              />
            </Spacer>
          )}
          <Spacer pt={32}>
            <IncentivesResults
              id="incentives-results"
              errorMesage={errorMesage}
              status={invalidZip || useStatus}
              data={Array.isArray(incentives) ? (incentives as IncentiveData[]) : []}
              pagination={{ pages, currentPage, current, next, previous, isLastPage, isFirstPage }}
              config={config}
              searchParams={{ postcode: searchedPostcode }}
            />
          </Spacer>
        </ContentWrapper>
        <ContentWrapper flex={true}>
          <FooterLogoContainer>
            <img
              src={
                language === 'en'
                  ? poweredByJDPLogo
                  : language === 'fr'
                  ? poweredByJDPLogoFrench
                  : poweredByJDPLogo
              }
              alt="Powered by JDP"
            />
          </FooterLogoContainer>
        </ContentWrapper>
        <ContentWrapper flex={true}>
          <TypeSetting
            style={{ margin: '24px auto 150px', fontSize: '12px' }}
            i18n={{
              id: 'i18n.base.footer.disclaimer',
              fallback: 'Search for incentives that may significantly reduce the cost of chargers.',
            }}
            element="p"
          />
        </ContentWrapper>
      </Main>
    </Styled>
  );
};

export const Incentives = WithConfig(
  IncentivesBase,
  'designs.incentives.results.table',
  DEFAULT_CONFIG
);

IncentivesBase.displayName = 'Incentives';
