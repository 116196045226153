import styled, { css } from 'styled-components';
import { resolveStyles } from '../../../utils/styled/resolveStyles';
import { ButtonProps } from './Button';

export const defaultButtonStyles = css<Pick<ButtonProps, 'disabled'>>`
  border: 0;
  outline: 0;
  color: #ffffff;
  font-size: 0.875rem;
  background-color: #007dbe;
  padding: 1em 2em;
  font-weight: bolder;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
    `};
`;

export const StyledButton = styled.button`
  ${resolveStyles({ fallback: defaultButtonStyles })}
`;
