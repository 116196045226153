import React from 'react';
import { IncentiveDetails } from '../../../types';
import { IncentiveDetailsBoxFundingCap } from '../IncentiveDetailsBoxFundingCap';
import { IncentiveDetailsBoxGrantorName } from '../IncentiveDetailsBoxGrantorName';
import { IncentiveDetailsBoxExpirationDate } from '../IncentiveDetailsBoxExpirationDate';
import { IncentiveDetailsBoxEligibility } from '../IncentiveDetailsBoxEligibility';
import { IncentiveDetailsBoxGrantor } from '../IncentiveDetailsBoxGrantor';
import { IncentiveDetailsBoxTypicalAmount } from '../IncentiveDetailsBoxTypicalAmount';
import { IncentiveDetailsBoxPointOfSale } from '../IncentiveDetailsBoxPointOfSale';

export type IncentiveDetailBoxKind =
  | 'funding_cap_per_applicant'
  | 'grantor_name'
  | 'expiration_date'
  | 'available'
  | 'grantor'
  | 'typical_amount'
  | 'point_of_sale';
export type IncentiveDetailsBoxProps = {
  kind: IncentiveDetailBoxKind;
  data: IncentiveDetails;
};

export const IncentiveDetailsBox: React.FC<IncentiveDetailsBoxProps> = ({ kind, data }) => {
  switch (kind) {
    case 'funding_cap_per_applicant':
      return <IncentiveDetailsBoxFundingCap data={data} />;
    case 'grantor_name':
      return <IncentiveDetailsBoxGrantorName data={data} />;
    case 'expiration_date':
      return <IncentiveDetailsBoxExpirationDate data={data} />;
    case 'available':
      return <IncentiveDetailsBoxEligibility data={data} />;
    case 'grantor':
      return <IncentiveDetailsBoxGrantor data={data} />;
    case 'typical_amount':
      return <IncentiveDetailsBoxTypicalAmount data={data} />;
    case 'point_of_sale':
      return <IncentiveDetailsBoxPointOfSale data={data} />;
    default:
      throw new Error(
        `The kind ${kind} has no mapped component to render for an Incentive details box`
      );
  }
};

IncentiveDetailsBox.displayName = 'IncentiveDetailsBox';
