import React, { FormEvent, useState, useLayoutEffect, useEffect, useCallback } from 'react';
import isEmpty from 'lodash.isempty';
import { BaseDesignConfig, ContentEntry, Design, getConfig } from '../../../contexts';
import { FilterConfig, useFilterConfig, useURLQuery } from '../../../hooks';
import { Localizable, LocalizedString, I18nStructure, QueryStatus } from '../../../types';
import { Input } from '../../atoms/Input';
import { Select } from '../../atoms/Select';
import { Button, ButtonType } from '../../atoms/Button';
import { SelectOption } from '../../atoms/SelectOption';
import { StyledForm, CopyField, FilterRow, ButtonFilter, ButtonContainer } from './styled';
import { LabeledInput } from '../../molecules/LabeledInput';
import { useExtendedConfig } from '../../../hooks/useExtendedConfig';
import { Tooltip } from '../Tooltip';
import { PostcodeInput } from '../../molecules/PostcodeInput';
import { HouseholdIncomeInput } from '../../molecules/HouseholdIncomeInput';
import isString from 'lodash.isstring';
import { useTranslations } from '../../../contexts/Translations';
import { Copy } from '../../atoms/Copy';
import { useParams } from 'react-router-dom';
import { VehiclesMake, useVehicles } from '../../../hooks/useVehicles';
import { useDispatch, useSelector } from 'react-redux';
import { updaters } from '../../AwsAmplifyHelper/userAuthenticationSlice';
import { CONSUMER, HEATMAP_INCENTIVE, LEASE, PURCHASE, USA } from '../../../constants/utils';
import consumerSearchInputs from '../../../config/consumerSearchInputs.json';
import { Icon } from '../../atoms/Icon';
import FilterIcon from '../../../assets/icons/filterIcon.svg';
import GoogleAnalytics from 'react-ga4';

export enum FormComponentKind {
  SELECT = 'SELECT',
  TEXT_INPUT = 'TEXT_INPUT',
  HIDDEN_INPUT = 'HIDDEN_INPUT',
  BUTTON = 'BUTTON',
  UNKNOW = 'UNKNOWN',
}
interface trimsType {
  name: string;
  value: string;
  handle: string;
  vehicleName: string;
  trim: string;
  selected: boolean;
  i18n: {
    id: string;
    fallback: string;
  };
}

interface utilityType {
  name: string;
  value: string;
  acronym: string;
  type: string;
  rates: [];
  logo_url: string;
  selected: boolean;
  i18n: {
    id: string;
    fallback: string;
  };
}

export function resolveInputKind(type?: unknown | 'select' | 'text' | 'hidden'): FormComponentKind {
  switch (type) {
    case 'select':
      return FormComponentKind.SELECT;
    case 'text':
      return FormComponentKind.TEXT_INPUT;
    case 'hidden':
      return FormComponentKind.HIDDEN_INPUT;
    default:
      return FormComponentKind.UNKNOW;
  }
}

export function resolveComponentKind(config?: BaseDesignConfig): FormComponentKind {
  switch (config?.element) {
    case 'input':
      return resolveInputKind(config?.type);
    case 'button':
      return FormComponentKind.BUTTON;
    default:
      return FormComponentKind.UNKNOW;
  }
}

export interface ResolvedFilterDesign extends Pick<Design, 'content' | 'components'> {
  config: {
    content: Array<ContentEntry>;
    id: string;
    name: string;
    value: string;
    i18n: LocalizedString;
    type?: ButtonType;
    required?: boolean;
    disabled?: boolean;
    defaultValue?: string;
    placeholder?: I18nStructure;
    size?: string;
    options?: Array<
      Localizable<{
        name: string;
        value: string;
        selected?: boolean;
      }>
    >;
  };
  schema?: unknown;
}

type TransformedVehicle = {
  i18n: {
    id: string;
    fallback: string;
  };
  name: string;
  selected: boolean;
  value: string;
};

type VehicleModel = {
  model: string;
};

export const RenderFromDesign: React.FC<
  ResolvedFilterDesign &
    Pick<FilterProps, 'isLoading' | 'initialValuesFromURLParams'> & {
      vehicleModels: VehicleModel[];
      trim?: trimsType[];
      utility?: utilityType[];
      index?: number;
    }
> = ({
  config,
  components,
  isLoading,
  initialValuesFromURLParams = {},
  vehicleModels,
  trim,
  utility,
  index,
}) => {
  const { config: tooltipDesign } = useExtendedConfig(components?.tooltip || '');
  const { country } = useParams();
  const state = useSelector((state: any) => state.userSlice);
  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  let i18nZipcode = {} as LocalizedString;
  let i18nLocationPlaceholder = {};

  switch (country) {
    case 'US':
      i18nZipcode = {
        id: 'i18n.incentives.filters.location.input.alertUS',
        fallback: 'US Format - 12345',
      };
      i18nLocationPlaceholder = {
        i18n: {
          id: 'i18n.incentives.filters.location.input.placeholder',
          fallback: 'Zip Code',
        },
      };
      break;
    default:
      i18nZipcode = {
        id: 'i18n.incentives.filters.location.input.alert',
        fallback: 'Canada Format - A1A 1A1',
      };
      i18nLocationPlaceholder = {
        i18n: {
          id: 'i18n.incentives.filters.location.input.placeholder.canada',
          fallback: 'Postal Code',
        },
      };
      break;
  }

  const handleVehicle = vehicleModels.reduce<TransformedVehicle[]>((acc, vehicle) => {
    const isDuplicate = acc.find((v) => v.name === vehicle.model);
    if (!isDuplicate) {
      const newVehicle = {
        i18n: {
          id: `i18n.incentives.filters.vehicles.${vehicle.model}`,
          fallback: vehicle.model,
        },
        name: vehicle.model,
        selected: false,
        value: vehicle.model,
      };
      acc.push(newVehicle);
    }
    return acc;
  }, []);

  const vehicleOptions = vehicleModels
    ? ((config?.options as TransformedVehicle[]) || []).concat(handleVehicle)
    : config?.options || [];

  const trimsOptions = trim
    ? ((config?.options as trimsType[]) || []).concat(trim)
    : config?.options || [];

  const utilityOptions = utility
    ? ((config?.options as utilityType[]) || []).concat(utility)
    : config?.options || [];

  const isPair = index && index % 2 === 0;

  const defaultValue = state && state.defaultFilters && state.defaultFilters[config?.name];

  switch (resolveComponentKind(config || undefined)) {
    case FormComponentKind.SELECT:
      return (
        <LabeledInput
          i18n={config?.i18n as LocalizedString}
          htmlFor={config?.id || config?.name}
          required={config?.required}
          components={{
            tooltip:
              tooltipDesign?.config && tooltipDesign?.content
                ? (modalXPosition) => (
                    <Tooltip
                      content={tooltipDesign?.content || []}
                      toggleOnHover={!isMobile}
                      modalXPosition={isPair || !isMobile ? modalXPosition : -230}
                    />
                  )
                : () => undefined,
          }}
        >
          <Select
            defaultValue={
              defaultValue
                ? defaultValue
                : initialValuesFromURLParams[config?.name] ||
                  (config?.options || []).find(({ selected }) => selected)?.value
            }
            id={config?.id || config?.name}
            name={config?.name}
            size={config?.size}
            i18n={config?.i18n as LocalizedString}
          >
            {config?.name === 'model'
              ? vehicleOptions.map(({ selected, ...props }) => (
                  <SelectOption key={`${props.name}-${props.value}`} {...props} />
                ))
              : config?.name === 'trim'
              ? trimsOptions.map(({ selected, ...props }) => (
                  <SelectOption key={`${props.name}-${props.value}`} {...props} />
                ))
              : config?.name === 'utility'
              ? utilityOptions.map(({ selected, ...props }) => (
                  <SelectOption key={`${props.name}-${props.value}`} {...props} />
                ))
              : (config?.options || []).map(({ selected, ...props }) => (
                  <SelectOption key={`${props.name}-${props.value}`} {...props} />
                ))}
          </Select>
        </LabeledInput>
      );
    case FormComponentKind.HIDDEN_INPUT:
      return <input type="hidden" name={config?.name} value={config?.value} />;
    case FormComponentKind.TEXT_INPUT:
      if (isEmpty(config?.placeholder?.i18n?.id) || isEmpty(config?.placeholder?.i18n?.fallback)) {
        throw new Error('The placeholder field is required for a text input');
      }

      if (isEmpty(config?.i18n?.id) || isEmpty(config?.i18n?.fallback)) {
        throw new Error('The i18n field is required for rendering a labeled text input');
      }

      return (
        <LabeledInput
          i18n={config?.i18n as LocalizedString}
          htmlFor={config.id}
          required={config?.required}
          components={{
            tooltip:
              tooltipDesign?.config && tooltipDesign?.content
                ? (modalXPosition) => (
                    <Tooltip
                      content={tooltipDesign?.content || []}
                      toggleOnHover={true}
                      modalXPosition={modalXPosition}
                    />
                  )
                : () => undefined,
          }}
        >
          {config.name === 'postcode' ? (
            <>
              <PostcodeInput
                id={config.id}
                required={config?.required}
                name={config.name}
                defaultValue={initialValuesFromURLParams[config?.name] || config?.defaultValue}
                placeholder={i18nLocationPlaceholder as I18nStructure}
              />
              <CopyField>
                <Copy i18n={i18nZipcode} />
              </CopyField>
            </>
          ) : config.name === 'household_income' ? (
            <>
              <HouseholdIncomeInput
                id={config.id}
                required={config?.required}
                name={config.name}
                defaultValue={initialValuesFromURLParams[config?.name] || config?.defaultValue}
                placeholder={config.placeholder as I18nStructure}
              />
              <CopyField>
                <Copy i18n={i18nZipcode} />
              </CopyField>
            </>
          ) : (
            <Input
              id={config.id}
              required={config?.required}
              name={config.name}
              defaultValue={initialValuesFromURLParams[config?.name] || config?.defaultValue}
              placeholder={config.placeholder as I18nStructure}
            />
          )}
        </LabeledInput>
      );
    case FormComponentKind.BUTTON:
      return (
        <Button disabled={isLoading} type={config?.type} i18n={config?.i18n as LocalizedString} />
      );
    default:
      return null;
  }
};

const hasAnyEmptyRequiredField = (fields: [string, ResolvedFilterDesign][]) => {
  return fields.some(
    ([_, { config }]) => config?.required === true && isEmpty(config?.defaultValue)
  );
};

const prepareSubmitConfig = (
  fields: [string, ResolvedFilterDesign][],
  [name, design]: [string, ResolvedFilterDesign]
): [string, ResolvedFilterDesign] => {
  if (!hasAnyEmptyRequiredField(fields)) return [name, design];

  return [name, { ...design, config: { ...design.config, disabled: true } }];
};

export const formFieldsHasElement = (
  formFields: [string, ResolvedFilterDesign][],
  elementName: string
) => {
  return formFields.some(
    ([
      _,
      {
        config: { name },
      },
    ]) => name === elementName
  );
};

export const validate = (
  name: string,
  value?: string,
  validations?: { [name: string]: (value?: string) => boolean },
  defaultEvaluation = true
): boolean => {
  if (typeof validations?.[name] === 'function') {
    return validations[name](value);
  }

  return defaultEvaluation;
};

export interface FilterProps {
  isLoading?: boolean;
  onSubmit?: (
    elementsValues: { name: string; value: string }[],
    e: React.FormEvent<HTMLFormElement> | undefined
  ) => void;
  onChange?: (target: { name: string; value: string }) => void;
  validations?: { [name: string]: (value?: string) => boolean };
  initialValuesFromURLParams?: { [name: string]: string };
  addURLParam?: ({ name, value }: { name: string; value: string }) => void;
  requiredParamNames?: string[];
  setInvalidZip: (string: QueryStatus) => void;
  setErrorMesage: (obj: LocalizedString) => void;
  vehicleSelected?: boolean;
}

export const composeFormFields = (
  content: Array<[string, ResolvedFilterDesign]> = []
): Array<[string, ResolvedFilterDesign]> => {
  return Object.values(content).reduce((fields, [name, design]) => {
    const field = [name, design] as [string, ResolvedFilterDesign];

    if (name === 'submit') {
      const submitDesign = prepareSubmitConfig(fields, [name, design]);

      return [...fields, submitDesign];
    }

    return [...fields, field];
  }, [] as Array<[string, ResolvedFilterDesign]>);
};

export const getFormFieldsMap = (
  fields: [string, ResolvedFilterDesign][]
): { [key: string]: ResolvedFilterDesign } =>
  fields.reduce((acc, [, design]) => {
    if (design?.config?.name) {
      acc[design?.config?.name] = design;
    }

    return acc;
  }, {} as { [key: string]: ResolvedFilterDesign });

export const isFieldRequired = (fields: [string, ResolvedFilterDesign][], name?: string) =>
  Boolean(name && getFormFieldsMap(fields)[name]?.config?.required);

const getInitialFormFields = (content: unknown | Array<[string, ResolvedFilterDesign]> = []) =>
  composeFormFields(content as [string, ResolvedFilterDesign][] | undefined);

export const Filter: React.FC<FilterProps> = ({
  onSubmit,
  onChange,
  validations,
  isLoading,
  initialValuesFromURLParams = {},
  addURLParam,
  requiredParamNames = [],
  setInvalidZip,
  setErrorMesage,
  vehicleSelected,
}) => {
  const {
    design: {
      config: { content = [], thirdRowContent = [] },
    },
  } = useFilterConfig();
  const [formFields, setFormFields] = useState(getInitialFormFields(content));
  const [thirdRowFormFields, setThirdRowFormFields] = useState(
    getInitialFormFields(thirdRowContent)
  );
  const state = useSelector((state: any) => state.userSlice);
  const { language } = useTranslations();
  const { paramsArray } = useURLQuery();
  const { country, type } = useParams();
  const [params, setParams] = useState<{ name: string; value: string }[]>(paramsArray);
  const [make, setMake] = useState<VehiclesMake | undefined | any>(state.defaultFilters.make);
  const [model, setModel] = useState<string>(state.defaultFilters.model);
  const [trim, setTrim] = useState<trimsType[] | any>();
  const [utility, setUtility] = useState<utilityType[]>();
  const [selectedUtility, setSelectedUtility] = useState<utilityType[]>();
  const [selectedCar, setSelectedCar] = useState(state.defaultFilters.selectedCar);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [showAdditionalFilters, setShowAdditionalFilters] = useState(false);
  const [searchView, setSearchView] = useState<boolean>(false);
  const dispatch = useDispatch();
  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  useEffect(() => {
    if (state.defaultFilters.country !== country || state.defaultFilters.type !== type) {
      setMake(undefined);
      setModel('');
      setTrim([]);
      setVehicleModels([]);
      setSelectedCar(undefined);
      setUtility(undefined);
      dispatch(updaters.selectedVehicle(null));
      dispatch(updaters.userPowerSupplier(null));
      dispatch(updaters.defaultFilters({ country, type }));
    }
  }, [country, type]);

  useEffect(() => {
    if (vehicleSelected) {
      const currentFilters = state.consumerFilters;
      const newFilters = [...currentFilters, { name: 'applicable_to_lease', value: 'true' }];
      dispatch(updaters.consumerFilters(newFilters));
    }
  }, [vehicleSelected]);

  useEffect(() => {
    if (type === CONSUMER) {
      if (state.consumerView === HEATMAP_INCENTIVE) {
        setSearchView(false);
        setFormFields(getInitialFormFields(content));
        setThirdRowFormFields(getInitialFormFields(thirdRowContent));
      } else {
        setSearchView(true);
        const formFieldsDesign = getConfig(consumerSearchInputs)('designs.incentives.filters');
        const designFinal = Array.isArray(formFieldsDesign?.config?.content)
          ? // @ts-ignore
            Object.values((formFieldsDesign.config as FilterConfig).content).map(([name, k]) => [
              name,
              getConfig(consumerSearchInputs)(k as string),
            ])
          : [];

        // @ts-ignore
        setFormFields(designFinal);

        const thirdRowFormFieldsDesign = getConfig(consumerSearchInputs)(
          'designs.incentives.thirdRowFilters'
        );
        const thirdRowDesignFinal = Array.isArray(thirdRowFormFieldsDesign?.config?.content)
          ? // @ts-ignore
            Object.values((thirdRowFormFieldsDesign.config as FilterConfig).content).map(
              ([name, k]) => [name, getConfig(consumerSearchInputs)(k as string)]
            )
          : [];

        // @ts-ignore
        setThirdRowFormFields(thirdRowDesignFinal);
      }
    } else {
      setSearchView(false);
      setFormFields(getInitialFormFields(content));
      setThirdRowFormFields(getInitialFormFields(thirdRowContent));
    }
  }, [state.consumerView, type, country, content, thirdRowContent]);

  useEffect(() => {
    const utilityArr = state.userLocation
      ? state.userLocation.map((power_supplier: any) => ({
          name: power_supplier.name,
          value: power_supplier.name,
          acronym: power_supplier.acronym,
          type: power_supplier.type,
          rates: power_supplier.rates ?? [],
          logo_url: power_supplier.logo_url,
          i18n: {
            id: `i18n.incentives.filters.vehicles.${power_supplier.name}`,
            fallback: power_supplier.name,
          },
          selected: false,
        }))
      : [];
    setUtility(utilityArr);
  }, [state.userLocation]);

  useEffect(() => {
    dispatch(updaters.userPowerSupplier(selectedUtility));
  }, [selectedUtility]);

  useEffect(() => {
    if (JSON.stringify(paramsArray) !== JSON.stringify(params)) {
      setParams(paramsArray);
    }
  }, [paramsArray]);

  const { data: vehicleResult } = useVehicles(params, {
    enabled: !!make,
    country: country,
    make: make,
  });

  useEffect(() => {
    if (vehicleResult) {
      let result = vehicleResult?.data || [];
      setVehicleModels(result);
    }
  }, [vehicleResult, params]);

  useEffect(() => {
    if (model) {
      getTrims();
    }
  }, [model, vehicleModels]);

  useEffect(() => {
    dispatch(updaters.selectedVehicle(selectedCar));
  }, [selectedCar]);

  const getTrims = () => {
    const trimOrder = [
      'Cadillac_LYRIQ_AWD_BEV_2024',
      'Cadillac_LYRIQ_RWD_BEV_2024',
      'Cadillac_LYRIQ_Sport_1_AWD_BEV_2024',
      'Cadillac_LYRIQ_Sport_1_RWD_BEV_2024',
      'Cadillac_LYRIQ_Luxury_1_AWD_BEV_2024',
      'Cadillac_LYRIQ_Luxury_1_RWD_BEV_2024',
      'Cadillac_LYRIQ_AWD_BEV_2024_CA',
      'Cadillac_LYRIQ_RWD_BEV_2024_CA',
      'Cadillac_LYRIQ_Sport_1_AWD_BEV_2024_CA',
      'Cadillac_LYRIQ_Sport_1_RWD_BEV_2024_CA',
      'Cadillac_LYRIQ_Luxury_1_AWD_BEV_2024_CA',
      'Cadillac_LYRIQ_Luxury_1_RWD_BEV_2024_CA',
    ];

    const trims = vehicleModels
      .filter((vehicle: any) => vehicle.model === model)
      .map((vehicle: any) => ({
        name: vehicle.trim,
        value: vehicle.trim,
        handle: vehicle.handle,
        vehicleName: vehicle.display_name,
        trim: vehicle.trim,
        i18n: {
          id: `i18n.incentives.filters.vehicles.${vehicle.trim}`,
          fallback: vehicle.trim,
        },
        selected: false,
      }))
      .sort((a, b) => {
        let indexA = trimOrder.indexOf(a.handle);
        let indexB = trimOrder.indexOf(b.handle);

        indexA = indexA === -1 ? Number.MAX_VALUE : indexA;
        indexB = indexB === -1 ? Number.MAX_VALUE : indexB;

        return indexA - indexB;
      });

    setTrim(trims);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement> | undefined) => {
    e?.preventDefault();

    const target = e?.target as HTMLFormElement;
    const elements = [].slice
      .call(target?.elements)
      .reduce((acc, e: HTMLInputElement | HTMLSelectElement | HTMLButtonElement) => {
        if (isEmpty(e.name) || !formFieldsHasElement(formFields, e.name)) return acc;

        acc[e.name] = { name: e.name, value: e.value };

        if (acc[e.name].name === 'postcode') {
          acc[e.name].value = acc[e.name].value.replaceAll(' ', '');
        }

        return acc;
      }, {} as { [key: string]: { name: string; value: string } });

    const isUS = country === USA;

    if (isUS && elements.postcode.value.length < 5) {
      setInvalidZip('error');
      return setErrorMesage({ id: 'i18n.base.error.zip', fallback: '' });
    }

    if (!isUS && elements.postcode.value.length < 6) {
      setInvalidZip('error');
      return setErrorMesage({ id: 'i18n.base.error.zip', fallback: '' });
    }

    const query = Object.values(elements);

    if (language === 'fr') {
      query.push({ name: 'preferred_lang', value: 'fr' });
    }

    if (typeof onSubmit === 'function') {
      onSubmit(query, e);
    }
  };

  const handleOutsideSubmit = useCallback(() => {
    const target = document.getElementById('filterForm') as HTMLFormElement;
    const elements = [].slice
      .call(target?.elements)
      .reduce((acc, e: HTMLInputElement | HTMLSelectElement | HTMLButtonElement) => {
        if (isEmpty(e.name) || !formFieldsHasElement(formFields, e.name)) return acc;

        acc[e.name] = { name: e.name, value: e.value };

        if (acc[e.name].name === 'postcode') {
          acc[e.name].value = acc[e.name].value.replaceAll(' ', '');
        }

        return acc;
      }, {} as { [key: string]: { name: string; value: string } });

    const query = Object.values(elements);

    if (language === 'fr') {
      query.push({ name: 'preferred_lang', value: 'fr' });
    }
  }, [language]);

  useEffect(() => {
    handleOutsideSubmit();
  }, [handleOutsideSubmit]);

  const handleChange = (e: FormEvent<HTMLFormElement>) => {
    const target = e?.target as HTMLFormElement;
    const isRequiredField = isFieldRequired(formFields, target?.name);
    const isSubmittable =
      isRequiredField &&
      !isEmpty(target?.value) &&
      validate(target?.name, target?.value, validations);

    if (target.name === 'make') {
      if (!target.value) {
        setModel('');
        setMake('');
        setTrim('');
        setSelectedCar('');
        dispatch(updaters.consumerFilters([]));
        dispatch(
          updaters.defaultFilters({
            ...state.defaultFilters,
            trim: '',
            selectedCar: '',
            make: '',
            model: '',
          })
        );

        return;
      }

      setMake(target.value);
      dispatch(updaters.defaultFilters({ ...state.defaultFilters, make: target.value }));
      setTrim([]);
      return;
    }

    if (target.name === 'model') {
      setModel(target.value);
      dispatch(updaters.defaultFilters({ ...state.defaultFilters, model: target.value }));
      return;
    }

    if (target.name === 'trim') {
      const handle = trim?.find((e: any) => e.trim === target.value)?.handle;
      const vehicle = vehicleModels.find((e: any) => e.handle === handle) as any;
      if (!target.value || target.value === '-') {
        setSelectedCar(undefined);
      }

      if (vehicle) {
        GoogleAnalytics.event({
          action: 'Select a Vehicle',
          category: 'Vehicles',
          label: `${vehicle?.make} ${vehicle?.model} ${vehicle?.trim}`,
        });
      }

      setSelectedCar(vehicle);
      dispatch(
        updaters.defaultFilters({
          ...state.defaultFilters,
          trim: target.value,
          selectedCar: vehicle,
        })
      );
      return;
    }

    if (target.name === 'utility') {
      setSelectedUtility(target.value);
      dispatch(updaters.defaultFilters({ ...state.defaultFilters, utility: target.value }));
      return;
    }

    dispatch(updaters.defaultFilters({ ...state.defaultFilters, [target.name]: target.value }));

    if (target.name === 'applicable_to_lease') {
      if (target.value === 'true') {
        //amount_in_lease
        dispatch(updaters.consumerTableView(LEASE));
      } else {
        //amount_in_purchase
        dispatch(updaters.consumerTableView(PURCHASE));
      }
    }

    const consumerToCheck: string[] = [
      'household_size',
      'turn_in_clunker',
      'household_income',
      'tax_filing_type',
    ];

    if (type === CONSUMER && !consumerToCheck.includes(target.name) && target.name !== 'postcode') {
      const currentFilters = state.consumerFilters;
      const existingFilterIndex = currentFilters.findIndex((f: any) => f.name === target.name);
      const newConsumerFilters =
        existingFilterIndex !== -1
          ? currentFilters.map((filter: any, index: any) =>
              index === existingFilterIndex ? { name: target.name, value: target.value } : filter
            )
          : [...currentFilters, { name: target.name, value: target.value }];

      dispatch(updaters.consumerFilters(newConsumerFilters));
      return;
    }

    if (typeof onChange === 'function') {
      onChange({ name: target?.name, value: target?.value });
    }

    if (!isRequiredField) {
      return;
    }

    if (isSubmittable) {
      setFormFields(
        formFields.map(([name, design]) => {
          if (name !== 'submit') {
            return [name, design];
          }

          const enabledButtonDesign = { ...design, config: { ...design?.config, disabled: false } };

          return [name, enabledButtonDesign];
        })
      );
    } else if (!isSubmittable) {
      setFormFields(
        formFields.map(([name, design]) => {
          if (name !== 'submit') {
            return [name, design];
          }

          const enabledButtonDesign: any = {
            ...design,
            config: { ...design?.config, disabled: true },
          };

          return [name, enabledButtonDesign];
        })
      );
    }
  };

  useLayoutEffect(() => {
    if (isEmpty(getInitialFormFields(content))) return;

    const initialFormFields = getInitialFormFields(content);
    initialFormFields.forEach(([, { config }]) => {
      const name = config?.name;
      const defaultValue =
        country?.toUpperCase() === 'US' ? config?.defaultValue || config?.value : 'M5V 1J1';

      if (
        requiredParamNames.includes(name) &&
        !isEmpty(defaultValue) &&
        isString(defaultValue) &&
        typeof addURLParam === 'function'
      ) {
        addURLParam({ name, value: defaultValue });
      }
    });

    const formFildValue = type === CONSUMER ? getInitialFormFields(content) : initialFormFields;
    setFormFields(formFildValue);
  }, [content, addURLParam, requiredParamNames, type]);

  return (
    <StyledForm
      id="filterForm"
      onChange={handleChange}
      onSubmit={handleSubmit}
      isSearchView={searchView}
    >
      <FilterRow id="filterForm" isSearchView={searchView}>
        {formFields.map(([name, design], i) => {
          if (i === 0) {
            return (
              <div key={`${i}-filter.${name}`}>
                <RenderFromDesign
                  key={`${i}-filter.${name}`}
                  {...design}
                  isLoading={isLoading}
                  initialValuesFromURLParams={initialValuesFromURLParams}
                  vehicleModels={vehicleModels}
                  trim={trim}
                  utility={utility}
                />
              </div>
            );
          }

          if (isMobile && i === 1) {
            const btnCopy = showAdditionalFilters
              ? {
                  id: 'i18n.incentives.filter_button.hide',
                  fallback: 'Hide Filters',
                }
              : {
                  id: 'i18n.incentives.filter_button.show',
                  fallback: 'Show Filters',
                };

            return (
              <ButtonContainer>
                <ButtonFilter onClick={() => setShowAdditionalFilters((e) => !e)}>
                  <Icon src={FilterIcon} />
                  <Copy i18n={btnCopy} />
                </ButtonFilter>
              </ButtonContainer>
            );
          }

          return (
            (!isMobile || showAdditionalFilters) && (
              <RenderFromDesign
                key={`${i}-filter.${name}`}
                {...design}
                isLoading={isLoading}
                initialValuesFromURLParams={initialValuesFromURLParams}
                vehicleModels={vehicleModels}
                trim={trim}
                utility={utility}
                index={i}
              />
            )
          );
        })}
      </FilterRow>
      <br />
      <br />
      <br />
      {vehicleSelected ? (
        <FilterRow id="filterForm" isSearchView={searchView}>
          {thirdRowFormFields.map(([name, design], i) => {
            return (
              <RenderFromDesign
                key={`${i}-filter.${name}`}
                {...design}
                isLoading={isLoading}
                initialValuesFromURLParams={initialValuesFromURLParams}
                vehicleModels={vehicleModels}
                trim={trim}
                utility={utility}
              />
            );
          })}
        </FilterRow>
      ) : null}
    </StyledForm>
  );
};

Filter.displayName = 'Filter';
