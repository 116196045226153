import React from 'react';
import {
  IncentiveData,
  IncentiveResultConfig,
  IncentiveDataKeys,
} from '../../organisms/IncentivesResults/IncentivesResults.types';
import chevronRight from '../../../assets/icons/chevron-right.svg';
import { Styled, StyledMoreDetails, StyledMoreDetailsCol } from './styled';
import { IncentiveResultTd } from '../../atoms/IncentiveResultTd';
import { I18nStructure } from '../../../types';
import { TypeSetting } from '../../atoms/TypeSetting';
import { Icon } from '../../atoms/Icon';
import {
  URL_QUERY_NAME as URL_LANGUAGE_QUERY_NAME,
  useTranslations,
} from '../../../contexts/Translations';
import { useURLQuery } from '../../../hooks';
import { useParams } from 'react-router-dom';

export type IncentiveResultProps = {
  data: IncentiveData;
  id: string;
} & Pick<IncentiveResultConfig, 'columns'>;

export const MoreDetails: React.FC<I18nStructure & { id: string }> = ({ i18n, id }) => {
  const { language } = useTranslations();
  const { paramsArray } = useURLQuery();
  const { country } = useParams();
  const defaultPostcode =
    country?.toLowerCase() === 'us' ? { value: '95206' } : { value: 'M5V 1J1' };
  const postcode = paramsArray.find((e) => e.name === 'postcode') || defaultPostcode;

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <StyledMoreDetailsCol data-key="more-details">
      <StyledMoreDetails
        to={`${id}?${URL_LANGUAGE_QUERY_NAME}=${language}&postcode=${postcode.value}`}
        onClick={(e) => {
          e.stopPropagation();
          scrollToTop();
        }}
      >
        <TypeSetting i18n={i18n} />
        <Icon src={chevronRight} />
      </StyledMoreDetails>
    </StyledMoreDetailsCol>
  );
};

export const IncentiveResult: React.FC<IncentiveResultProps> = ({ columns, data, id }) => {
  return (
    <Styled>
      {columns
        .map(({ key }) => {
          // @ts-ignore
          const col = data[key] as keyof typeof IncentiveDataKeys;
          return (
            <IncentiveResultTd
              key={JSON.stringify([key].concat(col))}
              col={key}
              startDate={data.start_date}
              expirationDate={data.expiration_date}
              data={data}
            >
              {col}
            </IncentiveResultTd>
          );
        })
        .concat([
          <MoreDetails
            id={id}
            key={id}
            i18n={{ id: 'i18n.more-details', fallback: 'More Details' }}
          />,
        ])}
    </Styled>
  );
};

IncentiveResult.displayName = 'IncentiveResult';
