import React, { useMemo, useState, useEffect } from 'react';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import isString from 'lodash.isstring';
import { useParams, useNavigate } from 'react-router-dom';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@reach/tabs';
import { useIncentiveApi, useURLQuery } from '../../../hooks';
import {
  Styled,
  StyledHeader,
  StyledHeaderTitle,
  StyledBoxesRow,
  StyledTopRow,
  StyledTabs,
  StyledTabList,
  StyledTab,
  StyledHeaderIconsContainer,
  PrintTabs,
  IconContainer,
  ContainerBox,
  PrintTimeStamp,
  FooterLogoContainer,
} from './styled';
import { ContentWrapper } from '../../atoms/ContentWrapper';
import PrintModal from '../../organisms/PrintModal';
import { Typography } from '../../atoms/Typography';
import { Box, BoxBody, BoxTitle, BoxHighlightedText } from '../../molecules/Box';
import { TypeSetting } from '../../atoms/TypeSetting';
import back from '../../../assets/icons/back.svg';
import { Spacer } from '../../atoms/Spacer';
import { DEFAULT_COMMERCIAL, DEFAULT_CONSUMER } from './Incentive.defaults';
import { IncentiveDesign } from './Incentive.types';
import { IncentiveDetails } from '../../../types';
import { WithConfig } from '../../HOCs/WithConfig';
import { IncentiveDetailTab, IncentiveDetailTabKind } from '../../organisms/IncentiveDetailTab';
import { IncentiveDetailsBox, IncentiveDetailBoxKind } from '../../organisms/IncentiveDetailsBox';
import { IncentiveDetailsBoxHeader } from '../../organisms/IncentiveDetailsBoxHeader';
import { Icon } from '../../atoms/Icon';
import { Copy } from '../../atoms/Copy';
import { useSelector } from 'react-redux';
import { CONSUMER } from '../../../constants/utils';
import { ConsumerDetail } from '../../organisms/ConsumerDetail';
import { useTranslations } from '../../../contexts/Translations';
import poweredByJDPLogo from '../../../assets/powered-by-jdp-logo.svg';
import poweredByJDPLogoFrench from '../../../assets/powered-by-jdp-logo-french.svg';

export const isIncentive = (x?: IncentiveDetails | undefined): x is IncentiveDetails => x !== null;

export const IncentiveBase: React.FC<IncentiveDesign> = ({ config }) => {
  const state = useSelector((state: any) => state.userSlice);
  const navigate = useNavigate();
  const { paramsArray } = useURLQuery();
  const { id, type } = useParams();
  const [params, setParams] = useState<{ name: string; value: string }[]>(paramsArray);
  const [apiData, setApiData] = useState({});
  const isConsumer = type === CONSUMER;
  const { language } = useTranslations();

  if (isEmpty(id) || !isString(id)) {
    navigate('incentives', { replace: true });
  }

  useEffect(() => {
    if (JSON.stringify(paramsArray) !== JSON.stringify(params)) {
      setParams(paramsArray);
    }
  }, [paramsArray]);

  const { data, isLoading } = useIncentiveApi(params, id as string, { enabled: true });

  useEffect(() => {
    if (isConsumer) {
      if (data) {
        const incentiveData = data?.data?.incentives;
        const filteredData = incentiveData?.find(
          (incentive: any) => Number(incentive.id) === Number(id)
        );
        setApiData(filteredData ?? {});
      }
    } else {
      setApiData(data?.data ?? {});
    }
  }, [type, data]);

  const incentive: IncentiveDetails = isConsumer
    ? (apiData as any)
    : (
        apiData as {
          [key: string]: unknown;
          incentive: IncentiveDetails;
        }
      )?.incentive;

  const renderTowRow = useMemo(() => {
    switch (type) {
      case 'consumer':
        return (
          <>
            <ContentWrapper>
              <StyledBoxesRow isConsumer>
                {config.topRow.slots.boxes.map(({ i18n, key, components }) => (
                  <Box
                    key={key}
                    slots={{
                      header: (
                        <IncentiveDetailsBoxHeader
                          i18n={i18n}
                          kind={key}
                          components={components}
                          incentive={incentive}
                        />
                      ),
                    }}
                  >
                    <IncentiveDetailsBox kind={key as IncentiveDetailBoxKind} data={incentive} />
                  </Box>
                ))}
              </StyledBoxesRow>
            </ContentWrapper>
          </>
        );

      case 'commercial':
        return (
          <>
            <ContentWrapper flex={false}>
              <Box
                slots={{
                  header: <BoxTitle i18n={config.topRow.slots.highlight.i18n} />,
                }}
              >
                <BoxBody>
                  <BoxHighlightedText>
                    {get(incentive, config.topRow.slots.highlight.key, '')}
                  </BoxHighlightedText>
                </BoxBody>
              </Box>
            </ContentWrapper>

            <ContentWrapper>
              <StyledBoxesRow isConsumer>
                {config.topRow.slots.boxes.map(({ i18n, key, components }) => (
                  <Box
                    key={key}
                    slots={{
                      header: (
                        <IncentiveDetailsBoxHeader
                          isCommercial
                          i18n={i18n}
                          kind={key}
                          components={components}
                          incentive={incentive}
                        />
                      ),
                    }}
                  >
                    <IncentiveDetailsBox kind={key as IncentiveDetailBoxKind} data={incentive} />
                  </Box>
                ))}
              </StyledBoxesRow>
            </ContentWrapper>
          </>
        );

      default:
        break;
    }
  }, [type, config, incentive]);

  const isDev = window.location.hostname === 'localhost';

  if (!isDev && !state.accessToken) {
    window.location.replace('/');
    return <></>;
  }

  const getCurrentDate = () => {
    const today = new Date();
    const day = String(today.getDate()).padStart(2, '0');
    const month = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
    const year = today.getFullYear();
    return `${month}/${day}/${year}`;
  };

  let i18nPrinted = {
    id: 'i18n.printed_format',
    fallback: 'Printed on',
  };

  return (
    <Styled id="incentive-page">
      <Spacer pt={50}>
        <ContentWrapper>
          <PrintTimeStamp>
            <p>
              <Copy i18n={i18nPrinted} /> {getCurrentDate()}
            </p>
          </PrintTimeStamp>
        </ContentWrapper>
        <ContentWrapper>
          <StyledHeader>
            <Spacer mr={32}>
              <button onClick={() => navigate(-1)}>
                <Icon src={back} height="1.375rem" width="1.5rem" />
              </button>
            </Spacer>
            <StyledHeaderTitle>
              <Typography
                element="h1"
                scale="5"
                fontWeight="600"
                color={isLoading ? 'transparent' : undefined}
              >
                {isLoading ? '.' : incentive?.incentive_name}
              </Typography>
            </StyledHeaderTitle>

            <StyledHeaderIconsContainer>
              {!isLoading ? (
                <>
                  <IconContainer>
                    <PrintModal />
                  </IconContainer>
                </>
              ) : null}
            </StyledHeaderIconsContainer>
          </StyledHeader>
        </ContentWrapper>
      </Spacer>

      <StyledTopRow>{renderTowRow}</StyledTopRow>

      {isConsumer ? (
        <ContentWrapper flex={false}>
          {config.tabs.map(({ i18n, id }) => (
            <ContainerBox key={id}>
              <TypeSetting textTransform="uppercase" fontWeight="400" color="#777777" i18n={i18n} />
              {incentive && <ConsumerDetail id={id} incentive={incentive} />}
            </ContainerBox>
          ))}
        </ContentWrapper>
      ) : (
        <ContentWrapper>
          <Tabs as={StyledTabs}>
            <TabList id="incentives-tab-list" as={StyledTabList}>
              {config.tabs.map(({ i18n, id }) => (
                <Tab as={StyledTab} key={id}>
                  <TypeSetting textTransform="uppercase" fontWeight="bold" i18n={i18n} />
                </Tab>
              ))}
            </TabList>
            <TabPanels id="tabPanels">
              {config.tabs.map(({ id }) => (
                <TabPanel key={id}>
                  {incentive && (
                    <IncentiveDetailTab data={incentive} kind={id as IncentiveDetailTabKind} />
                  )}
                </TabPanel>
              ))}
            </TabPanels>
            <PrintTabs id="printTabs">
              {config.tabs.map(
                ({ id }) =>
                  incentive && (
                    <IncentiveDetailTab data={incentive} kind={id as IncentiveDetailTabKind} />
                  )
              )}
            </PrintTabs>
          </Tabs>
        </ContentWrapper>
      )}

      <ContentWrapper flex={true}>
        <FooterLogoContainer>
          <img
            src={
              language === 'en'
                ? poweredByJDPLogo
                : language === 'fr'
                ? poweredByJDPLogoFrench
                : poweredByJDPLogo
            }
            alt="Powered by JDP"
          />
        </FooterLogoContainer>
      </ContentWrapper>

      <ContentWrapper flex={true}>
        <TypeSetting
          style={{ margin: '24px auto 150px', fontSize: '12px' }}
          i18n={{
            id: 'i18n.base.footer.disclaimer',
            fallback: 'Search for incentives that may significantly reduce the cost of chargers.',
          }}
          element="p"
        />
      </ContentWrapper>
    </Styled>
  );
};

IncentiveBase.displayName = 'Incentive';

export const Incentive = WithConfig(IncentiveBase, 'designs.incentive.details', {
  defaultConsumerConfig: DEFAULT_CONSUMER,
  defaultCommercialConfig: DEFAULT_COMMERCIAL,
});
