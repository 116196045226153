import { createContext, ReactNodeArray, useContext } from 'react';

export interface FlowsSharedContextInterface {
  config: Map<string, object>;
  data: Map<any, any>;
  readonly update: (key: any, value: any, store?: string) => any;
  links: ReactNodeArray;
  navigationMap: Map<any, any>;
}

const contextDefaultValues: FlowsSharedContextInterface = {
  config: new Map(),
  data: new Map(),
  update: () => {},
  links: [],
  navigationMap: new Map(),
};

const FlowsSharedContext = createContext<FlowsSharedContextInterface>(contextDefaultValues);

const useFlowsConfig = (key: string): [() => object | undefined, (value: object) => void] => {
  const config: Map<string, object> = useContext(FlowsSharedContext).config;

  const setConfig = (value: object) => {
    config.set(key, value);
  };

  const getConfig = () => {
    if (!config.has(key)) return undefined;
    return config.get(key);
  };

  return [getConfig, setConfig];
};

export function useAuthOauth2GrantsConfig(
  authOauth2GrantsConfig?: AuthOauth2GrantsConfig
): AuthOauth2GrantsConfig | undefined {
  const [getConfig, setConfig] = useFlowsConfig('OAUTH2_GRANTS_CONFIG');

  if (authOauth2GrantsConfig) {
    setConfig(authOauth2GrantsConfig);
  }

  return getConfig() as AuthOauth2GrantsConfig | undefined;
}

export interface AuthOauth2GrantsConfigBuilderInterface {
  withResponseType(value: string): AuthOauth2GrantsConfigBuilderInterface;
  withClientId(value: string): AuthOauth2GrantsConfigBuilderInterface;
  withRedirectUri(value: string): AuthOauth2GrantsConfigBuilderInterface;
  withState(value: string): AuthOauth2GrantsConfigBuilderInterface;
  withScope(value: string): AuthOauth2GrantsConfigBuilderInterface;
  withIdentityProvider(value: string): AuthOauth2GrantsConfigBuilderInterface;
  withIdpIdentityProvider(value: string): AuthOauth2GrantsConfigBuilderInterface;
  withCodeChallenge(value: string): AuthOauth2GrantsConfigBuilderInterface;
  withCodeChallengeMethod(value: string): AuthOauth2GrantsConfigBuilderInterface;
  withGrantType(value: string): AuthOauth2GrantsConfigBuilderInterface;
  withCodeVerifier(value: string): AuthOauth2GrantsConfigBuilderInterface;
  withClientSecret(value: string): AuthOauth2GrantsConfigBuilderInterface;
}

export type AuthOauth2GrantsConfig = {
  cognitoUrl: string;
  responseType: string;
  clientId: string;
  redirectUri: string;
  grantType: string;
  state?: string;
  scope?: string;
  identityProvider?: string;
  idpIdentityProvider?: string;
  codeChallenge?: string;
  codeChallengeMethod?: string;
  codeVerifier?: string;
  clientSecret?: string;
};

export class AuthOauth2GrantsConfigBuilder implements AuthOauth2GrantsConfigBuilderInterface {
  //@ts-ignore : This is in prep for the move to ECMAScript2015
  #inputs: AuthOauth2GrantsConfig;

  withCognitoUrl(value: string) {
    this.#inputs.cognitoUrl = value;
    return this;
  }
  withResponseType(value: string) {
    this.#inputs.responseType = value;
    return this;
  }
  withClientId(value: string) {
    this.#inputs.clientId = value;
    return this;
  }
  withRedirectUri(value: string) {
    this.#inputs.redirectUri = value;
    return this;
  }
  withState(value: string) {
    this.#inputs.state = value;
    return this;
  }
  withScope(value: string) {
    this.#inputs.scope = value;
    return this;
  }
  withIdentityProvider(value: string) {
    this.#inputs.identityProvider = value;
    return this;
  }
  withIdpIdentityProvider(value: string) {
    this.#inputs.idpIdentityProvider = value;
    return this;
  }
  withCodeChallenge(value: string) {
    this.#inputs.codeChallenge = value;
    return this;
  }
  withCodeChallengeMethod(value: string) {
    this.#inputs.codeChallengeMethod = value;
    return this;
  }
  withGrantType(value: string) {
    this.#inputs.grantType = value;
    return this;
  }
  withCodeVerifier(value: string) {
    this.#inputs.codeVerifier = value;
    return this;
  }
  withClientSecret(value: string) {
    this.#inputs.clientSecret = value;
    return this;
  }

  constructor() {
    this.#inputs = {
      cognitoUrl: '',
      responseType: '',
      clientId: '',
      redirectUri: '',
      grantType: '',
      state: undefined,
      scope: undefined,
      identityProvider: undefined,
      idpIdentityProvider: undefined,
      codeChallenge: undefined,
      codeChallengeMethod: undefined,
      codeVerifier: undefined,
      clientSecret: undefined,
    };
  }

  build(): AuthOauth2GrantsConfig {
    return this.#inputs;
  }
}
