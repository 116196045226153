import React, { ChangeEvent, useState } from 'react';
import { createNumberMask } from 'text-mask-addons';
import { useCopy } from '../../../hooks/useCopy';
import { InputProps } from '../../atoms/Input';
import { StyledMaskedInput } from './styled';

const defaultMaskOptions = {
  prefix: '$',
  suffix: '',
  includeThousandsSeparator: true,
  thousandsSeparatorSymbol: ',',
  allowDecimal: true,
  decimalSymbol: '.',
  decimalLimit: 2, // how many digits allowed after the decimal
  integerLimit: 7, // limit length of integer numbers
  allowNegative: false,
  allowLeadingZeroes: false,
};

export type RenderedInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export const sanitizeInputValue = (conformedValue: string) => {
  return /[a-zA-Z]/.test(conformedValue) ? conformedValue.toUpperCase() : conformedValue;
};

export const HouseholdIncomeInput: React.FC<InputProps> = ({
  placeholder: { i18n },
  defaultValue,
  ...props
}) => {
  const placeholder = useCopy(i18n);

  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
  });

  const [value, setValue] = useState<any>(defaultValue);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setValue(value);
  };

  return (
    <StyledMaskedInput
      mask={currencyMask}
      value={value}
      guide={false}
      {...props}
      placeholder={placeholder}
      onChange={handleChange}
      pipe={sanitizeInputValue}
    />
  );
};

HouseholdIncomeInput.displayName = 'HouseholdIncomeInput';
