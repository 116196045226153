import { useMutation, UseMutationResult, MutateOptions } from 'react-query';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

interface PostResponse {
  data: {
    evaluations: {
      incentives: [];
    };
  };
}

type returnType = string[] | undefined;

type ObjectWithAnyProps = { [key: string]: any };

function filterProps(obj: ObjectWithAnyProps, keysToKeep: string[]): ObjectWithAnyProps {
  const filteredObj: ObjectWithAnyProps = {};
  const powerSupplier = obj?.power_supplier[0];
  for (const key of keysToKeep) {
    if (key in obj) {
      filteredObj[key] = obj[key];
    }
  }

  if (powerSupplier) {
    filteredObj.power_supplier = powerSupplier;
  }

  return filteredObj;
}

interface vehicleType {
  subtype: string;
  make: string;
  model: string;
  trim: string;
  fuel: string;
  weight_class: string;
  model_year: string | number;
  vehicle_type?: string;
  type?: string;
  handle?: string;
  form_factor: string;
  msrp: string | number;
  purchase_price: string | number;
  lift_capacity: string | number;
  battery_capacity: string | number;
  replaces_ice_vehicle?: boolean;
  identifier: string;
  lease_length?: string | null;
  eligible_for_ca_rebate?: any;
  gvwr: string | number;
  is_offroad: string | boolean;
  horsepower: string | number;
}

const evaluatedPayload = (location: Object, vehicle: vehicleType) => {
  const propsToKeep = [
    'address_id',
    'street_address',
    'municipality',
    'region',
    'country',
    'postcode',
    'active',
    'owner',
  ];

  const defaultAddress = {
    address_id: 0,
    street_address: '5011 E 8 Mile Rd',
    municipality: 'Warren',
    region: 'FL',
    country: 'US',
    postcode: '32207',
    active: true,
    owner: false,
    power_supplier: {
      name: 'JEA',
    },
  };

  const apiAddress = location ? filterProps(location, propsToKeep) : defaultAddress;

  const defaultVehicle = [
    {
      details: {
        type: 'vehicle',
        subtype: vehicle.subtype,
        make: vehicle.make,
        model: vehicle.model,
        trim: vehicle.trim,
        fuel: vehicle.fuel,
        weight_class: vehicle.weight_class,
        model_year: vehicle.model_year,
        vehicle_type: vehicle.type,
        form_factor: vehicle.form_factor === 'Crossover' ? 'SUV' : vehicle.form_factor,
        msrp: vehicle.msrp,
        purchase_price: vehicle.msrp,
        lift_capacity: vehicle.lift_capacity,
        battery_capacity: vehicle.battery_capacity,
        replaces_ice_vehicle: true,
        identifier: vehicle.handle,
        lease_length: null,
        eligible_for_ca_rebate: vehicle.eligible_for_ca_rebate
          ? vehicle.eligible_for_ca_rebate.toLowerCase() === 'no'
            ? false
            : true
          : false,
        gvwr: vehicle.gvwr,
        is_offroad: vehicle.is_offroad,
        horsepower: vehicle.horsepower,
      },
      count: 1,
      type: 'vehicle',
    },
  ];

  return {
    project: {
      project_type: 'fleets',
      projected_cost: 185398,
      organization: {
        organization_type: 'medium_or_large_businesses',
        customer_id: 'AcmeGoogleSheet',
        yearsOfOperation: 10,
        is_public: true,
        is_tax_exempt: false,
      },
      site: {
        address: apiAddress,
        location: null,
        in_a_dac: true,
        site_id: null,
        name: null,
        description: null,
      },
      custom_vehicles: null,
      custom_chargers: null,
      estimated_installation_costs: 23000,
      'estimated BTM_costs': null,
      vehicles: defaultVehicle,
    },
  };
};

function extractHandles(responseData: any) {
  return responseData?.evaluations?.incentives?.map((incentive: any) => incentive.handle) || [];
}

export function useEvaluatedApi<T = typeof evaluatedPayload>(
  location: any,
  vehicle: any
): Omit<UseMutationResult<PostResponse, unknown, T, unknown>, 'error' | 'data'> & {
  isLoading: boolean;
  error: any;
  data: returnType | undefined;
  mutate: (payload?: T, options?: MutateOptions<PostResponse, unknown, T, unknown>) => void;
} {
  const [handles, setHandles] = useState<string[] | undefined>();
  const { country, type } = useParams();

  useEffect(() => {
    setHandles(undefined);
  }, [country, type]);

  const config = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_UNIFIED_API_TOKEN}`,
    },
  };

  const url = `${process.env.REACT_APP_EVALUATOR_API_URL}/project`;

  const mutation = useMutation<PostResponse, unknown, T>(
    async (payload = evaluatedPayload(location?.data.location, vehicle) as unknown as T) => {
      const response = await axios.post<PostResponse>(url, payload, config);
      return response.data;
    }
  );

  useEffect(() => {
    if (mutation.data) {
      setHandles(extractHandles(mutation.data));
    }
  }, [mutation.data]);

  const mutate = (payload?: T, options?: MutateOptions<PostResponse, unknown, T, unknown>) => {
    mutation.mutate(payload as T, options);
  };

  return {
    ...mutation,
    isLoading: mutation.isLoading,
    error: mutation.error,
    data: handles,
    mutate,
    status: mutation.status,
  };
}
