import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { AuthOauth2GrantsConfig } from './AuthOauth2Grants';
import { federatedOauth } from '../../constants';
import { AuthorizationCodeGrant } from '../../components/AwsAmplifyHelper/FederatedUser';

function formatCognitoOauthBody(body: Map<string, string>) {
  const values: Array<string> = [];
  body.forEach((value, key) => {
    values.push(encodeURIComponent(key) + '=' + encodeURIComponent(value));
  });
  return values.join('&');
}

export const CognitoApi = ((authConfig: AuthOauth2GrantsConfig) => {
  if (!authConfig) {
    throw new Error('AuthOauth2GrantCofig was not set');
  }
  const tokenPostBody = (code: string) => {
    return formatCognitoOauthBody(
      new Map([
        ['grant_type', 'authorization_code'],
        ['code', code],
        ['client_id', authConfig.clientId ?? ''],
        ['redirect_uri', authConfig.redirectUri ?? ''],
      ]) as Map<string, string>
    );
  };
  return createApi({
    reducerPath: 'cognitoApi',
    baseQuery: fetchBaseQuery({
      baseUrl: authConfig.cognitoUrl,
      prepareHeaders: (headers) => {
        // By default, if we have a token in the store, let's use that for authenticated requests
        //   const token = (getState() as RootState).auth.token
        if (authConfig.grantType === 'authorization_code') {
          headers.set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8');
        }
        return headers;
      },
    }),
    endpoints: (builder) => ({
      oAuthGrantFromToken: builder.mutation<AuthorizationCodeGrant, string>({
        query: (code) => ({
          url: 'oauth2/token',
          method: 'POST',
          body: tokenPostBody(code),
        }),
      }),
      protected: builder.mutation<{ message: string }, void>({
        query: () => 'protected',
      }),
    }),
  });
})(federatedOauth);

export const { useOAuthGrantFromTokenMutation, useProtectedMutation } = CognitoApi;
