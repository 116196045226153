import styled, { css } from 'styled-components';
import { resolveStyles } from '../../../utils/styled/resolveStyles';

const fallback = css`
  display: flex;
  align-items: stretch;
  margin-top: 0.75rem;

  a {
    flex: 1;
  }

  .exit-modal {
    background-color: green;
  }
`;

export const StyledButtonContainer = styled.div`
  ${resolveStyles({ fallback })}
`;
