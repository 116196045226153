export const SCALE_POSITION_ONE = '1';
export const SCALE_POSITION_TWO = '2';
export const SCALE_POSITION_THREE = '3';
export const SCALE_POSITION_FOUR = '4';
export const SCALE_POSITION_FIVE = '5';
export const SCALE_POSITION_SIX = '6';
export const SCALE_POSITION_SEVEN = '7';
export const SCALE_POSITION_EIGHT = '8';
export const SCALE_POSITION_NINE = '9';

export const SCALE_POSITIONS = {
  [SCALE_POSITION_ONE]: SCALE_POSITION_ONE,
  [SCALE_POSITION_TWO]: SCALE_POSITION_TWO,
  [SCALE_POSITION_THREE]: SCALE_POSITION_THREE,
  [SCALE_POSITION_FOUR]: SCALE_POSITION_FOUR,
  [SCALE_POSITION_FIVE]: SCALE_POSITION_FIVE,
  [SCALE_POSITION_SIX]: SCALE_POSITION_SIX,
  [SCALE_POSITION_SEVEN]: SCALE_POSITION_SEVEN,
  [SCALE_POSITION_EIGHT]: SCALE_POSITION_EIGHT,
  [SCALE_POSITION_NINE]: SCALE_POSITION_NINE,
};

export type ScalePosition = keyof typeof SCALE_POSITIONS;

export type TypographyScaleFontSizesEntries = {
  [k in ScalePosition]: string;
};

export interface Scale {
  base?: number;
  multipliers?: number[];
  fontSizes: TypographyScaleFontSizesEntries;
}
