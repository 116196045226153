import styled, { css } from 'styled-components';
import { resolveStyles } from '../../../utils/styled/resolveStyles';

const fallback = css`
  & + & {
    margin-top: 1.25rem;
  }
`;

export const Styled = styled.div`
  ${resolveStyles({ fallback })}
`;
