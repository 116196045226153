import React, { useEffect } from 'react';
import GM_Home from '../../../ComponentMetadata/HomePage';
import GenericMetadataParser from '../../GenericMetadataParser';
import { useOAuthGrantFromTokenMutation } from '../../AwsAmplifyHelper/CognitoService';
import { useFederatedUserAuthUrl } from '../../AwsAmplifyHelper/FederatedUser';
import { useDispatch } from 'react-redux';
import { updaters } from '../../AwsAmplifyHelper/userAuthenticationSlice';
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router';
import Loaders from '../../Loaders';
import GaTracker from '../../../utils/GATracker';

const GM_HomePage = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    GaTracker.initialize();
    const page = window.location.pathname + window.location.search;
    GaTracker.trackPage(page, {});
  }, []);

  const federatedUserAuthUrl = useFederatedUserAuthUrl();

  const params = new URLSearchParams(document.location.search);
  const [validateToken, { data, error, isLoading }] = useOAuthGrantFromTokenMutation();
  if (localStorage.getItem('priorAuthentication') == null) {
    localStorage.setItem('priorAuthentication', 'false');
  }

  if (!params.get('code') && localStorage.getItem('priorAuthentication') === 'true') {
    window.open(federatedUserAuthUrl, '_self');
  }

  if (data) {
    localStorage.setItem('priorAuthentication', 'true');
    dispatch(updaters.grant(data));
  }
  const callTokenAPI = async () => {
    const code = params.get('code');

    if (code) {
      let res: any = await validateToken(code);

      if (res.error) {
        return;
      }

      let dataArr: any[] = [
        { label: 'access_token', func: updaters.accessToken },
        { label: 'expires_in', func: updaters.expires_in },
        { label: 'id_token', func: updaters.idToken },
        { label: 'refresh_token', func: updaters.refreshToken },
        { label: 'token_type', func: updaters.tokenType },
      ];

      dataArr.forEach((itemObj) => {
        if (res.data[itemObj.label]) {
          // @ts-ignore
          dispatch(itemObj.func(res.data[itemObj.label]));
        }
      });

      dispatch(updaters.accessToken(true));
      dispatch(updaters.userStep(2));

      history(`/`);
    }
  };
  useEffect(() => {
    callTokenAPI();
    // eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <Loaders type="circle_bounce">Loading</Loaders>
      ) : data ? (
        <Navigate to={'/incentives'} />
      ) : error || localStorage.getItem('priorAuthentication') === 'false' ? (
        <GenericMetadataParser
          id="Home"
          className="GmHomeImage"
          componentMetadata={GM_Home().build()}
        />
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default GM_HomePage;
