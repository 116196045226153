/* eslint-disable react-hooks/rules-of-hooks */
import { useParams } from 'react-router-dom';
import { useTranslations } from '../../contexts/Translations';
import { useUnifiedAPI } from '../useUnifiedAPI';
import { CANADA } from '../../constants/utils';

interface Param {
  name: string;
  value: string;
}

interface Options {
  enabled: boolean;
  vehicle_handle?: string;
}

function useCommercialIncentive(
  handle: string,
  { enabled }: { enabled: boolean } = { enabled: false }
) {
  const { language } = useTranslations();
  const { type } = useParams();
  let url = `commercial/incentives/${handle}?include_used_vehicles`;

  if (language === 'fr') {
    url = url + '?preferred_lang=fr';
  }

  if (type !== 'commercial') {
    enabled = false;
  }

  url.replace('+', '');

  return useUnifiedAPI(
    url,
    [],
    { runDataTransformations: true },
    {
      enabled,
    }
  );
}

function useConsumerIncentive(
  initialParams: Param[],
  handle: string,
  { enabled, vehicle_handle }: { enabled: boolean; vehicle_handle?: string } = {
    enabled: false,
    vehicle_handle: '',
  }
) {
  const { language } = useTranslations();
  const { country } = useParams();
  const { type } = useParams();
  let url = vehicle_handle ? `/vehicles/${vehicle_handle}` : `/incentives`;

  if (language === 'fr') {
    url = url + '?preferred_lang=fr';
  }

  if (type !== 'consumer') {
    enabled = false;
  }
  const params = [
    ...initialParams,
    { name: 'country', value: country || 'us' },
    { name: 'include_used_vehicles', value: 'true' },
  ];

  if (country === CANADA) {
    params.forEach((param) => {
      if (param.name === 'postcode') {
        param.value = param.value.replace(/\s+/g, '');
      }
    });
  }

  url.replace('+', '');

  const response = useUnifiedAPI(
    url,
    params,
    { runDataTransformations: true },
    {
      enabled,
    }
  );

  return response;
}

export function useIncentiveApi(
  params: Param[],
  handle: string,
  options: Options = { enabled: false, vehicle_handle: '' }
) {
  const { type } = useParams();

  if (type === 'commercial') {
    return useCommercialIncentive(handle, options);
  } else {
    return useConsumerIncentive(params, handle, options);
  }
}
