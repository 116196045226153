import { useParams } from 'react-router-dom';
import { useUnifiedAPI } from '../useUnifiedAPI';
import { useURLQuery } from '../useURLQuery';
import { CANADA } from '../../constants/utils';
import { getStateCapitalZipCodeByCountry } from '../../utils/heatmap/utils';

export function useLocation(enabled: boolean = false) {
  const { country } = useParams();
  const { paramsArray } = useURLQuery();
  const postcodeParam = paramsArray.find((param) => param.name === 'postcode') || {
    name: 'postcode',
    value: '',
  };
  let isEnabled = enabled;

  const isStateAbbreviation = /^[A-Z]{2}$/i.test(postcodeParam.value);
  const postcodeValue = isStateAbbreviation
    ? getStateCapitalZipCodeByCountry(postcodeParam.value.toUpperCase(), country) ||
      postcodeParam.value
    : postcodeParam.value;

  const postcode =
    country === CANADA
      ? { ...postcodeParam, value: postcodeValue.replace(/\s+/g, '') }
      : { ...postcodeParam, value: postcodeValue };

  const params = [{ name: 'country', value: country || 'US' }, postcode];

  return useUnifiedAPI(
    '/location',
    params,
    { runDataTransformations: true },
    { enabled: isEnabled }
  );
}
