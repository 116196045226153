import React from 'react';
import { IncentiveDetailsBoxProps } from '../IncentiveDetailsBox';
import { BoxBody, BoxHighlightedText } from '../../molecules/Box';
import { TypeSetting } from '../../atoms/TypeSetting';

export type IncentiveDetailsBoxFundingCapProps = Pick<IncentiveDetailsBoxProps, 'data'>;

export const IncentiveDetailsBoxPointOfSale: React.FC<IncentiveDetailsBoxFundingCapProps> = ({
  data,
}) => {
  return (
    <BoxBody>
      {data && (
        <div>
          <BoxHighlightedText>
            {!data.point_of_sale ? (
              <TypeSetting
                i18n={{
                  id: 'i18n.consumer.detail.cards.purchase_received',
                  fallback: 'Received after purchase',
                }}
              />
            ) : (
              <TypeSetting
                i18n={{
                  id: 'i18n.consumer.detail.cards.purchase_deducted',
                  fallback: 'Deducted from purchase price',
                }}
              />
            )}
          </BoxHighlightedText>
        </div>
      )}
    </BoxBody>
  );
};

IncentiveDetailsBoxPointOfSale.displayName = 'IncentiveDetailsBoxPointOfSale';
