import styled, { css } from 'styled-components';
import { IconProps } from './Icon';
import { resolveStyles } from '../../../utils/styled/resolveStyles';

const fallback = css<IconProps>`
  background-image: ${({ src }) => `url(${src})`};
  background-position: center;
  background-repeat: no-repeat;
  height: ${({ height }) => (height ? height : '0.875rem')};
  width: ${({ width }) => (width ? width : '0.875rem')};
  display: inline-block;
  pointer-events: none;
`;

export const StyledIcon = styled.span<IconProps>`
  ${resolveStyles({ fallback })}
`;
