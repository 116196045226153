import styled from 'styled-components';

interface PathProps {
  selectedState: boolean;
  isSelected: boolean;
}

export const Path = styled.path<PathProps>`
  transition: all ease 0.3s;

  opacity: ${(s) => (s.selectedState ? (s.isSelected ? 1 : 0.3) : 1)};

  &:hover {
    cursor: pointer;
    opacity: ${(s) => (s.isSelected ? '1 !important' : '0.5 !important')};
  }
`;

export const Container = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

export const MapContainer = styled.div`
  margin: 20px;
  width: 90vw;

  display: flex;

  justify-content: center;

  svg {
    transform: scale(1.101);
    width: 1920;
    height: 1080;
  }

  @media screen and (max-width: 1023px) {
    svg {
      transform: scale(0.8);
    }
  }
`;

export const TopRow = styled.div`
  margin: 50px 0 -50px;
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 2;

  @media (max-width: 768px) {
    margin: 0;
  }
`;

export const LegendRow = styled.div`
  width: auto;

  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const LegendItem = styled.div`
  width: auto;
  margin: 0 25px 0 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const LegendMark = styled.div`
  width: 16px;
  height: 16px;

  background: ${(p) => p.color || '#005091'};
  border-radius: 100%;
`;

export const LegendText = styled.div`
  margin: 0 8px;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #000000;
`;

export const ToggleRow = styled.div`
  width: auto;

  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

interface ToggleProps {
  isOn: boolean;
}

export const ToggleContainer = styled.div<ToggleProps>`
  width: 40px;
  height: 24px;

  display: flex;
  justify-content: ${(p) => (p.isOn ? 'flex-end' : 'flex-start')};
  align-items: center;

  padding: 0 2px;

  background: ${(p) => (p.isOn ? '#005091' : 'white')};
  border: 1px solid #005091;
  border-radius: 100px;

  cursor: pointer;
  transition: all ease 0.3s;
`;

export const Toggle = styled.div<ToggleProps>`
  width: 20px;
  height: 20px;

  background: ${(p) => (p.isOn ? 'white' : '#005091')};
  border-radius: 100px;
`;

export const ToggleText = styled.p`
  margin: 0 0 0 8px;

  font-family: 'Arial';
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  color: #000000;
`;

export const ToolTip = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 2px;

  position: absolute;
  top: 0;
  left: 0;
  width: 264px;
  min-height: 112px;

  background: #000000;
  border-radius: 5px;

  h1 {
    margin: 0 0 8px;

    width: 232px;
    height: 18px;

    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;

    color: #ffffff;
  }

  p {
    margin: 2px 0;

    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;

    color: #ffffff;
  }
`;
