import React from 'react';
import { useTranslation as useTranslationHook } from '../../../hooks/useTranslation';

export interface TranslationProps {
  id: string;
  fallback: string;
  values?: { [key: string]: unknown };
  useTranslation?: typeof useTranslationHook;
}

export function useResolvedCopy({
  id,
  fallback,
  values,
  useTranslation = useTranslationHook,
}: TranslationProps) {
  const { translate } = useTranslation();
  const translatedValue = values ? translate(id, values) : translate(id);

  return translatedValue !== id ? translatedValue : fallback;
}

export const Translation: React.FC<TranslationProps> = ({
  id,
  fallback,
  values,
  useTranslation = useTranslationHook,
}) => {
  const copy = useResolvedCopy({ id, fallback, values, useTranslation });

  return <>{copy}</>;
};

Translation.displayName = 'Translation';
