import styled from 'styled-components';

interface CSSProps {
  margin?: string;
  marginMobile?: string;
  noBorder?: boolean;
  borderTop?: boolean;
}

export const Title = styled.div`
  p {
    text-transform: uppercase;
    margin: 0 0 46px;
  }

  @media screen and (max-width: 767px) {
    p {
      color: #777777;
      margin: 2px 0 16px;
    }
  }
`;

export const Item = styled.div<CSSProps>`
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  border-bottom: ${(p) => (p.noBorder ? '' : '1px solid #dfe0eb')};

  padding: 23px 0;

  p {
    margin: 0;
  }

  h5 {
    margin: 0 0 16px;
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;

    padding: 16px 0;

    border-top: ${(p) => (p.borderTop ? '1px solid #dfe0eb' : '')};
  }
`;

export const Row = styled.div<CSSProps>`
  margin: ${(p) => p.margin || '0'};
  width: 100%;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;

export const Column = styled.div<CSSProps>`
  margin: ${(p) => p.margin || '0'};

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;
