import React from 'react';
import { StyledButton } from './styled';
import { Designable, Localizable } from '../../../types';
import { Copy } from '../Copy';

export type ButtonType = 'button' | 'submit' | 'reset' | undefined;
export type ButtonOwnProps = Partial<Pick<HTMLButtonElement, 'type' | 'disabled'>>;
export type ButtonProps = Designable<Localizable<ButtonOwnProps>>;

export const Button: React.FC<ButtonProps> = ({ i18n, type, disabled }) => {
  return (
    <StyledButton type={type as ButtonType} disabled={disabled}>
      <Copy i18n={i18n} />
    </StyledButton>
  );
};

Button.displayName = 'Button';
