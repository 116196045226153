import React, { useState, useMemo, useCallback, useEffect, useRef } from 'react';

import {
  Container,
  LegendItem,
  LegendMark,
  LegendRow,
  LegendText,
  MapContainer,
  ToggleRow,
  TopRow,
  ToggleContainer,
  Toggle,
  ToolTip,
} from './styled';
import { IMapData, IUSHeatMap } from '../US/USHeatMap.types';
import { Tooltip } from '../../../components/organisms/Tooltip';
import CanadaState from './components/CanadaState';
import data from './data/canada-map-dimensions';
import { TypeSetting } from '../../../components/atoms/TypeSetting';
import { useParams } from 'react-router-dom';

export const CanadaHeatMap: React.FC<IUSHeatMap> = ({
  customize,
  selectedState,
  defaultFill = '#D3D3D3',
  mapData,
  hooveredState,
}) => {
  const [showMap, setShowMap] = useState(true);
  const { type } = useParams();
  const mouseListener = useRef<any>();

  const clickHandler = useCallback((stateAbbreviation: string) => {
    return () => {};
  }, []);

  const fillStateColor = useCallback(
    (state: string) => {
      if (customize && customize[state] && customize[state].fill) {
        return customize[state].fill;
      }
      return defaultFill;
    },
    [customize, defaultFill]
  );

  const stateClickHandler = useCallback(
    (state: string) => {
      if (customize && customize[state] && customize[state].clickHandler) {
        return customize[state].clickHandler;
      }
      return clickHandler;
    },
    [clickHandler, customize]
  );

  const stateMouseEnter = useCallback(
    (state: string) => {
      if (customize && customize[state] && customize[state].mouseEnter) {
        return customize[state].mouseEnter;
      }
      return;
    },
    [customize]
  );

  const stateMouseLeave = useCallback(
    (state: string) => {
      if (customize && customize[state] && customize[state].mouseLeave) {
        return customize[state].mouseLeave;
      }
      return;
    },
    [customize]
  );

  const buildPaths = useMemo(() => {
    let paths = [];
    let dataStates: any = data();
    for (let stateKey in dataStates) {
      const path = (
        <CanadaState
          onMouseEnter={stateMouseEnter(stateKey)}
          onMouseLeave={stateMouseLeave(stateKey)}
          key={stateKey}
          dimensions={dataStates[stateKey]['dimensions']}
          state={stateKey}
          fill={fillStateColor(stateKey)}
          onClickState={stateClickHandler(stateKey)}
          isSelected={selectedState === stateKey}
          selectedState={selectedState}
        />
      );
      paths.push(path);
    }
    return paths;
  }, [selectedState, fillStateColor, stateClickHandler, stateMouseEnter, stateMouseLeave]);

  const buildTooltips = useMemo(() => {
    if (!mapData.length) return;

    let arr: any[] = [];

    mapData.forEach((stateObj: IMapData) => {
      arr.push(
        <div
          className="heatmaptooltip"
          style={{ position: 'absolute', zIndex: '1000', display: 'none' }}
          id={`heatmapTT${stateObj.state}`}
          key={`heatmapTT${stateObj.state}`}
        >
          <ToolTip id={`${stateObj.state}`}>
            <h1>{stateObj.state_name}</h1>
            <p>
              {`${
                stateObj.number_of_incentives.country ? stateObj.number_of_incentives.country : 0
              } `}
              <TypeSetting
                i18n={{
                  id: 'i18n.pages.heatmap.tooltip.federalIncentives',
                  fallback: 'federal incentives',
                }}
              />
            </p>
            <p>
              {`${stateObj.number_of_incentives.state ? stateObj.number_of_incentives.state : 0} `}
              <TypeSetting
                i18n={{
                  id: 'i18n.pages.heatmap.tooltip.stateIncentives.canada',
                  fallback: 'province incentives',
                }}
              />
            </p>
            <p>
              {`${
                stateObj.number_of_incentives.utility ||
                stateObj.number_of_incentives?.['Power Supplier'] ||
                0
              } `}
              <TypeSetting
                i18n={{
                  id: 'i18n.pages.heatmap.tooltip.utilityIncentives',
                  fallback: 'utility incentives',
                }}
              />
            </p>
            <p>
              {`${
                stateObj.number_of_incentives.Municipality
                  ? stateObj.number_of_incentives.Municipality
                  : 0
              } `}
              <TypeSetting
                i18n={{
                  id: 'i18n.pages.heatmap.tooltip.municipalityIncentives',
                  fallback: 'municipality (city and county) incentives',
                }}
              />
            </p>
            <p>
              {`${
                stateObj.number_of_incentives.miscellaneous ||
                stateObj.number_of_incentives?.['Miscellaneous Organization'] ||
                0
              } `}
              <TypeSetting
                i18n={{
                  id: 'i18n.pages.heatmap.tooltip.organizationIncentives',
                  fallback: 'other organization incentives',
                }}
              />
            </p>
          </ToolTip>
        </div>
      );
    });

    return arr;
  }, [mapData]);

  const addEL: () => any = useCallback(() => {
    // eslint-disable-next-line no-undef
    const tooltip: NodeListOf<HTMLElement> = document.querySelectorAll('.heatmaptooltip');

    if (!tooltip.length) return setTimeout(() => addEL(), 500);

    const moveTooltip = (e: MouseEvent) => {
      for (let i = tooltip.length; i--; ) {
        tooltip[i].style.left = e.pageX + 10 + 'px';
        tooltip[i].style.top = e.pageY + 10 + 'px';
      }
    };

    const _mouseListener = document.addEventListener('mousemove', moveTooltip, false);

    mouseListener.current = _mouseListener;
  }, []);

  useEffect(() => {
    addEL();

    // @ts-ignore
    return document.removeEventListener('mousemove', mouseListener);
  }, [addEL]);

  useEffect(() => {
    // eslint-disable-next-line no-undef
    const tooltip: NodeListOf<HTMLElement> = document.querySelectorAll('.heatmaptooltip');

    tooltip.forEach((tooltip) => {
      tooltip.style.display = 'none';
    });

    if (hooveredState) {
      const target = document.getElementById(`heatmapTT${hooveredState}`);
      target!.style.display = 'block';
    }
  }, [hooveredState]);

  return (
    <Container>
      <TopRow>
        {buildTooltips}
        <LegendRow>
          <LegendItem>
            <LegendMark />
            <LegendText>
              <TypeSetting
                i18n={{
                  id: 'i18n.pages.heatmap.legend.highIntensity',
                  fallback: 'High Intensity',
                }}
              />
            </LegendText>
            <Tooltip
              content={[
                ['high.intensity', 'designs.incentives.filters.location.tooltip.high.intensity'],
              ]}
              toggleOnHover
            />
          </LegendItem>
          <LegendItem>
            <LegendMark color="#00B4D8" />
            <LegendText>
              <TypeSetting
                i18n={{
                  id: 'i18n.pages.heatmap.legend.mediumIntensity',
                  fallback: 'Medium Intensity',
                }}
              />
            </LegendText>
            <Tooltip
              content={[
                [
                  'medium.intensity',
                  'designs.incentives.filters.location.tooltip.medium.intensity',
                ],
              ]}
              toggleOnHover
            />
          </LegendItem>
          <LegendItem>
            <LegendMark color="#BFC4C7" />
            <LegendText>
              <TypeSetting
                i18n={{
                  id: 'i18n.pages.heatmap.legend.lowIntensity',
                  fallback: 'Low Intensity',
                }}
              />
            </LegendText>
            <Tooltip
              content={[
                ['low.intensity', 'designs.incentives.filters.location.tooltip.low.intensity'],
              ]}
              toggleOnHover
            />
          </LegendItem>
        </LegendRow>

        {type === 'incentives' && (
          <ToggleRow>
            <ToggleContainer onClick={() => setShowMap((s) => !s)} isOn={showMap}>
              <Toggle isOn={showMap} />
            </ToggleContainer>
          </ToggleRow>
        )}
      </TopRow>
      {showMap && (
        <MapContainer>
          <svg
            className="us-state-map"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="-27500 -24050 55700 47100"
            height="800"
            width="1920"
          >
            <g className="outlines">{buildPaths}</g>
          </svg>
        </MapContainer>
      )}
    </Container>
  );
};

export default CanadaHeatMap;
