import { useLayoutEffect, useState } from 'react';
import { BaseDesignConfig, ContentEntry, getConfig } from '../../contexts';
import { useExtendedConfig as useConfig } from '../useExtendedConfig';

export interface FilterConfig extends Required<BaseDesignConfig> {
  content: Array<ContentEntry>;
}

const KEY = 'designs.incentives.filters';
const thirdRowKey = 'designs.incentives.thirdRowFilters';

export function useFilterConfig() {
  const { configs, config: design, ...queryProps } = useConfig(KEY);
  const { configs: thirdRowConfigs, config: thirdRowDesign } = useConfig(thirdRowKey);

  const [content, setContent] = useState(
    Array.isArray(design?.config?.content)
      ? Object.values((design.config as FilterConfig).content).map(([name, k]) => [
          name,
          getConfig(configs)(k as string),
        ])
      : []
  );

  const [thirdRowContent, setThirdRowContent] = useState(
    Array.isArray(thirdRowDesign?.config?.content)
      ? Object.values((thirdRowDesign.config as FilterConfig).content).map(([name, k]) => [
          name,
          getConfig(thirdRowConfigs)(k as string),
        ])
      : []
  );

  useLayoutEffect(() => {
    setContent(
      Array.isArray(design?.config?.content)
        ? Object.values((design.config as FilterConfig).content).map(([name, k]) => [
            name,
            getConfig(configs)(k as string),
          ])
        : []
    );
  }, [design, configs]);

  useLayoutEffect(() => {
    setThirdRowContent(
      Array.isArray(thirdRowDesign?.config?.content)
        ? Object.values((thirdRowDesign.config as FilterConfig).content).map(([name, k]) => [
            name,
            getConfig(thirdRowConfigs)(k as string),
          ])
        : []
    );
  }, [thirdRowDesign, thirdRowConfigs]);

  return {
    design: { config: { ...(design?.config || {}), content, thirdRowContent } },
    ...queryProps,
  };
}
