import React from 'react';
import { CopyProps, Copy } from '../Copy';
import { StyledTabContentMainTitle } from './styled';

export type TabContentMainTitleProps = CopyProps;

export const TabContentMainTitle: React.FC<TabContentMainTitleProps> = ({ i18n }) => {
  return (
    <StyledTabContentMainTitle
      element="h1"
      scale="2"
      textTransform="uppercase"
      fontWeight="500"
      color="#777777"
    >
      <Copy i18n={i18n} />
    </StyledTabContentMainTitle>
  );
};

TabContentMainTitle.displayName = 'TabContentMainTitle';
