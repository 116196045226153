import React from 'react';
import isEmpty from 'lodash.isempty';
import { Localizable } from '../../../types';
import { BoxTitle } from '../../molecules/Box';
import { GrantorBoxTitle, Styled, StyledFlexEndElement } from './styled';
import { useExtendedConfig } from '../../../hooks/useExtendedConfig';
import { Tooltip } from '../Tooltip';
import { IncentiveDetails } from '../../../types';
import { CurrentProgramStatusValueResults } from '../../atoms/IncentiveResultTd';
import { IncentiveCurrentProgramStatusDefaultMappingConfig } from '../../pages/Incentives/Incentives.defaults';
import { ColorMarkerConfig } from '../../atoms/ColorMarker';
import { TypeSetting } from '../../atoms/TypeSetting';
import { CountdownDifference } from '../../atoms/IncentiveResultTd';
import { differenceForOpenStatus } from '../../../utils/dates';
import { Typography, TypographyProps } from '../../atoms/Typography';
import { Icon } from '../../atoms/Icon';
import stateIcon from '../../../assets/icons/stateIcon.svg';
import federalIcon from '../../../assets/icons/federalIcon.svg';
import utilityIcon from '../../../assets/icons/utilityIcon.svg';
import miscIcon from '../../../assets/icons/miscIcon.svg';
import consumerGrantorIcon from '../../../assets/icons/consumer-grantor.svg';
import consumerAmountIcon from '../../../assets/icons/consumer-amount.svg';
import electricCar from '../../../assets/icons/electric-car.svg';

export type IncentiveDetailsBoxHeaderProps = Localizable<{
  kind: string;
  isCommercial?: boolean;
  incentive: IncentiveDetails;
  components?: { tooltip?: string };
}>;

export const FundingCapHeader: React.FC<
  Pick<IncentiveDetailsBoxHeaderProps, 'i18n' | 'components' | 'incentive'>
> = ({ i18n, components, incentive }) => {
  const tooltip = useExtendedConfig(components?.tooltip || '');
  const currentProgramStatus = useExtendedConfig(
    'designs.incentives.results.table.current_program_status'
  );
  const status = incentive?.current_program_status as keyof typeof CurrentProgramStatusValueResults;
  const statusDesign =
    currentProgramStatus?.config as typeof IncentiveCurrentProgramStatusDefaultMappingConfig;
  const statusConfig = statusDesign?.config;
  const currentStatusMapping = statusConfig?.map?.[status];
  const statusI18n = currentStatusMapping?.i18n;
  const withColorMarker = currentStatusMapping?.withColorMarker;
  const { config: colorMarkerConfig, isLoading } =
    useExtendedConfig<ColorMarkerConfig>(withColorMarker);

  return isEmpty(incentive) ? null : (
    <Styled>
      <BoxTitle i18n={i18n} />
      {!isEmpty(tooltip?.config) && (
        <Tooltip
          content={tooltip?.config?.content || []}
          toggleOnHover={Boolean(tooltip?.config?.config?.toggleOnHover)}
        />
      )}
      {!isLoading && (
        <StyledFlexEndElement>
          <TypeSetting
            fontWeight="bold"
            i18n={statusI18n}
            scale="2"
            textTransform="uppercase"
            color={colorMarkerConfig?.config?.color}
          />
        </StyledFlexEndElement>
      )}
    </Styled>
  );
};

FundingCapHeader.displayName = 'FundingCapHeader';

export const CountdownTypography: React.FC<TypographyProps> = ({ children, ...props }) => (
  <Typography {...props} scale="2" textTransform="uppercase" color="#CC3232">
    {children}
  </Typography>
);

export const ExpirationDateHeader: React.FC<
  Pick<IncentiveDetailsBoxHeaderProps, 'i18n' | 'incentive'>
> = ({ i18n, incentive }) => {
  return (
    <Styled>
      <BoxTitle i18n={i18n} />
      {incentive?.expiration_date && (
        <StyledFlexEndElement>
          <CountdownDifference
            components={{ typography: CountdownTypography, typeSetting: TypeSetting }}
            {...differenceForOpenStatus(new Date(incentive.expiration_date), new Date())}
          />
        </StyledFlexEndElement>
      )}
    </Styled>
  );
};

export const IncentiveDetailsBoxHeader: React.FC<IncentiveDetailsBoxHeaderProps> = ({
  i18n,
  kind,
  components,
  incentive,
  isCommercial,
}) => {
  let icon = '';
  switch (incentive?.grantor_type) {
    case 'State':
      icon = stateIcon;
      break;
    case 'Federal':
    case 'Country':
      icon = federalIcon;
      break;
    case 'Utility':
      icon = utilityIcon;
      break;
    case 'Miscellaneous':
      icon = miscIcon;
      break;
    default:
      break;
  }

  if (kind === 'available' || kind === 'point_of_sale') {
    icon = '';
  }

  if (i18n.id === 'i18n.incentive.grantor_name') {
    switch (incentive?.grantor_type) {
      case 'State':
        i18n = {
          id: 'i18n.incentive.grantor_name',
          fallback: 'State',
        };
        break;
      case 'Federal':
      case 'Country':
        i18n = {
          id: 'i18n.incentive.grantor_name.federal',
          fallback: 'Federal',
        };
        break;
      case 'Utility':
        i18n = {
          id: 'i18n.incentive.grantor_name.utility',
          fallback: 'Utility',
        };
        break;
      case 'Miscellaneous':
        i18n = {
          id: 'i18n.incentive.grantor_name.miscellaneous',
          fallback: 'Miscellaneous',
        };
        break;
      default:
        break;
    }
  }

  switch (kind) {
    case 'expiration_date':
      return <ExpirationDateHeader i18n={i18n} incentive={incentive} />;
    case 'funding_cap_per_applicant':
      return <FundingCapHeader i18n={i18n} components={components} incentive={incentive} />;
    case 'grantor':
      return (
        <Styled>
          <GrantorBoxTitle>{incentive?.grantor_type?.toUpperCase()}</GrantorBoxTitle>
          <Icon src={consumerGrantorIcon} height="2.5rem" width="2.5rem" />
        </Styled>
      );
    case 'typical_amount':
      return (
        <Styled>
          <BoxTitle i18n={i18n} style={{ marginRight: 'auto' }} />
          <Icon
            src={isCommercial ? electricCar : consumerAmountIcon}
            height="2.5rem"
            width="2.5rem"
          />
        </Styled>
      );

    default:
      return (
        <Styled>
          <BoxTitle i18n={i18n} style={{ marginRight: 'auto' }} />
          <Icon src={icon} height="2.5rem" width="2.5rem" />
        </Styled>
      );
  }
};

IncentiveDetailsBoxHeader.displayName = 'IncentiveDetailsBoxHeader';
