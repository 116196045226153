import React from 'react';
import { StyledColorMarker } from './styled';

export interface ColorMarkerProps {
  color?: string;
}

export const ColorMarker: React.FC<ColorMarkerProps> = ({ color }) => {
  return <StyledColorMarker color={color} aria-hidden />;
};

ColorMarker.displayName = 'ColorMarker';
