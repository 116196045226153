import { useState } from 'react';

import RenderPrintForm, { PrintModalMetadataBuilder } from './PrintModalForm';

import { Modal } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';

function print(currentTab) {
  if (currentTab === null) {
    document.getElementById('printTabs').style.display = 'block';
    document.getElementById('tabPanels').style.display = 'none';
  }


  window.print();

  // reset after print
  if(document.getElementById('tabPanels')){
    document.getElementById('tabPanels').style.display = 'block';
  }
  if(document.getElementById('printTabs')){
    document.getElementById('printTabs').style.display = 'none';
  }
  if(document.getElementById('printModal')){
    document.getElementById('printModal').style.display = 'block';
  }
}

const SimpleModal = (props) => {
  const [open, setOpen] = useState(false);



  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="printSection">
      <div className="printButton" type="button" onClick={() => print()}>
        <PrintIcon />
      </div>
      <Modal
        ref={props.ref}
        open={open}
        onClose={handleClose}
        aria-labelledby="print-modal-title"
        aria-describedby="print-modal-description"
        id={props.id}
      >
        {props.children}
      </Modal>
    </div>
  );
};



const PrintModal = () => {
  return (
    <SimpleModal id="printModal">
      <RenderPrintForm
        name="print"
        className="printModal"
        componentMetadata={PrintModalMetadataBuilder()
          .withOnClickFunction((value) => {
            print(value);
          })
          .build()}
      />
    </SimpleModal>
  );
};
export default PrintModal;
