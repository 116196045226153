import React from 'react';
import { TypeSettingProps, TypeSetting } from '../TypeSetting';
import { Styled } from './styled';

export type TooltipParagraphProps = TypeSettingProps;

export const TooltipParagraph: React.FC<TooltipParagraphProps> = ({ i18n }) => {
  return (
    <Styled>
      <TypeSetting i18n={i18n} element="p" scale="2" fontWeight="400" />
    </Styled>
  );
};

TooltipParagraph.displayName = 'TooltipParagraph';
