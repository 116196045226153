import styled, { css } from 'styled-components';
import { resolveStyles } from '../../../utils/styled/resolveStyles';

const fallback = css`
  margin-bottom: 0.5rem;

  @media screen and (min-width: 768px) {
    margin-bottom: 1.125rem;
  }
`;

export const StyledTitleContainer = styled.div`
  ${resolveStyles({ fallback })}
`;
