import {
  Scale,
  ScalePosition,
  SCALE_POSITIONS,
  TypographyScaleFontSizesEntries,
} from '../interfaces/TypographyScale';
import {
  TypographyThemeInterface,
  TypographyThemeVariants,
} from '../interfaces/TypographyStylingProps';

export const SCALE_BASE = 8;

export const SCALE_MULTIPLIERS = [1, 1.5, 2, 2.25, 3, 4.5, 6, 7.5, 9];

export const getScaleFromBase = (
  multiplicand: number = SCALE_BASE,
  multipliers: number[] = SCALE_MULTIPLIERS
) => [
  multiplicand * multipliers[0] /* 8 */,
  multiplicand * multipliers[1] /* 12 */,
  multiplicand * multipliers[2] /* 16 */,
  multiplicand * multipliers[3] /* 18 */,
  multiplicand * multipliers[4] /* 24 */,
  multiplicand * multipliers[5] /* 36 */,
  multiplicand * multipliers[6] /* 48 */,
  multiplicand * multipliers[7] /* 60 */,
  multiplicand * multipliers[8] /* 72 */,
];

export const SCALE = getScaleFromBase().reduce((acc, curr, index) => {
  const key = `${index + 1}`;
  acc[key] = curr;

  return acc;
}, {} as { [key: string]: number });
/**
  1: 8
  2: 12
  3: 16
  4: 18
  5: 24
  6: 36
  7: 48
  8: 60
  9: 72
 */

export const getFontSizeFromVariant = (
  { base, multipliers }: Pick<Scale, 'base' | 'multipliers'> = {
    base: SCALE_BASE,
    multipliers: SCALE_MULTIPLIERS,
  },
  transform: (x: number) => string = (x) => String(x)
) => {
  return (scalePosition: ScalePosition) => {
    return transform(getScaleFromBase(base, multipliers)[parseInt(scalePosition, 10) - 1]);
  };
};

export const DEFAULT_DOCUMENT_BASE_FONT_SIZE = 16;

export const getFontSizeAsRem = (
  px: number,
  getComputedStyle: typeof window.getComputedStyle = window.getComputedStyle
) => {
  const sourceElement = document.querySelector('html');

  return `${
    sourceElement
      ? px /
        (parseInt(getComputedStyle(sourceElement).fontSize, 10) || DEFAULT_DOCUMENT_BASE_FONT_SIZE)
      : px / DEFAULT_DOCUMENT_BASE_FONT_SIZE
  }rem`;
};

export const composeScaleFontSize = (
  typographyScaleFontSizes: TypographyScaleFontSizesEntries,
  scalePosition: string
) => {
  const key = scalePosition as ScalePosition;

  typographyScaleFontSizes[key] = getFontSizeFromVariant(
    {
      base: SCALE_BASE,
      multipliers: SCALE_MULTIPLIERS,
    },
    getFontSizeAsRem
  )(key as keyof TypographyScaleFontSizesEntries);

  return typographyScaleFontSizes;
};

export const defaultFontSizes: TypographyScaleFontSizesEntries = Object.keys(
  SCALE_POSITIONS
).reduce(composeScaleFontSize, {} as TypographyScaleFontSizesEntries);

export const typography: TypographyThemeInterface = {
  variants: {},
  scale: {
    base: SCALE_BASE,
    multipliers: SCALE_MULTIPLIERS,
    fontSizes: defaultFontSizes,
  },
};

export const getTypographyTheme = ({
  scale: { base, multipliers } = {
    base: SCALE_BASE,
    multipliers: SCALE_MULTIPLIERS,
  },
  variants = {},
}: {
  variants?: TypographyThemeVariants;
  scale: Partial<Pick<Scale, 'base' | 'multipliers'>>;
}): TypographyThemeInterface => {
  if (typeof base !== 'number') {
    throw new Error('`base` is required to be a number');
  }

  if (!Array.isArray(multipliers) || multipliers.some((n) => typeof n !== 'number')) {
    throw new Error('`multipliers` should be an Array of numbers');
  }

  return {
    variants,
    scale: {
      base,
      multipliers,
      fontSizes: Object.keys(SCALE_POSITIONS).reduce((typographyScaleFontSizes, scalePosition) => {
        const key = scalePosition as ScalePosition;

        typographyScaleFontSizes[key] = getFontSizeFromVariant(
          { base, multipliers },
          getFontSizeAsRem
        )(key as ScalePosition);

        return typographyScaleFontSizes;
      }, {} as TypographyScaleFontSizesEntries),
    },
  };
};
