import React from 'react';
import { StyledInlineImage } from './styled';

export type InlineImageOwnProps = {
  src: string;
  alt: string;
  height?: string;
  width?: string;
  mr?: string;
  ml?: string;
};
export type InlineImageProps = InlineImageOwnProps;

export const InlineImage: React.FC<InlineImageProps> = ({ src, ...props }) => {
  return <StyledInlineImage src={src} {...props} />;
};

InlineImage.displayName = 'InlineImage';
