import { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import { TypeSetting } from '../TypeSetting';
import { Styled } from './styled';
import { Localizable } from '../../../types';

export type LabelOwnProps = Pick<HTMLLabelElement, 'htmlFor'>;
export type LabelProps = Localizable<LabelOwnProps>;

export const Label: ForwardRefExoticComponent<LabelProps & RefAttributes<HTMLLabelElement>> =
  forwardRef(({ i18n, ...htmlLabelProps }, ref) => {
    return (
      <Styled {...htmlLabelProps} ref={ref}>
        <TypeSetting i18n={i18n} />
      </Styled>
    );
  });

Label.displayName = 'Label';
