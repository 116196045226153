import React from 'react';
import { IncentiveDetailTabProps } from '../IncentiveDetailTab';
import {
  StyledContainer,
  StyledGrid,
  StyledMainColumn,
  StyledSidebar,
  StyledContentBlock,
} from './styled';
import { TabContentMainTitle } from '../../atoms/TabContentMainTitle';
import { TabContentTitle } from '../../atoms/TabContentTitle';
import { Typography } from '../../atoms/Typography';
import { FormattedDate } from '../../atoms/FormattedDate';
import { Box } from '../../molecules/Box';

export type IncentiveDetailOverviewTabProps = Pick<IncentiveDetailTabProps, 'data'>;

export const IncentiveDetailOverviewTab: React.FC<IncentiveDetailOverviewTabProps> = ({ data }) => {
  return (
    <Box>
      <StyledContainer>
        <TabContentMainTitle
          i18n={{ id: 'i18n.general-information', fallback: 'General Information' }}
        />
        <StyledGrid>
          <StyledMainColumn>
            {data?.incentive_name && (
              <StyledContentBlock>
                <TabContentTitle i18n={{ id: 'i18n.incentive-name', fallback: 'Incentive Name' }} />
                <Typography element="p" scale="3" fontWeight="400">
                  {data?.incentive_name}
                </Typography>
              </StyledContentBlock>
            )}
            {data?.incentive_description && (
              <StyledContentBlock>
                <TabContentTitle
                  i18n={{ id: 'i18n.incentive-description', fallback: 'Incentive Description' }}
                />
                <Typography element="p" scale="3" fontWeight="400">
                  {data?.incentive_description}
                </Typography>
              </StyledContentBlock>
            )}
            {data?.incentive_scope_description && (
              <StyledContentBlock>
                <TabContentTitle
                  i18n={{ id: 'i18n.incentive-scope', fallback: 'Incentive Scope' }}
                />
                <Typography element="p" scale="3" fontWeight="400">
                  {data?.incentive_scope_description}
                </Typography>
              </StyledContentBlock>
            )}
          </StyledMainColumn>
          <StyledSidebar>
            {data?.expiration_date && data?.start_date && (
              <StyledContentBlock>
                <TabContentTitle
                  i18n={{ id: 'i18n.application-window', fallback: 'Application Window' }}
                />
                <Typography element="p" scale="3" fontWeight="400">
                  {data?.start_date && (
                    <FormattedDate date={data.start_date} stringFormat="M/d/uuuu" />
                  )}{' '}
                  —{' '}
                  {data?.expiration_date && (
                    <FormattedDate date={data.expiration_date} stringFormat="M/d/uuuu" />
                  )}
                </Typography>
              </StyledContentBlock>
            )}
            {data?.grantor_name && (
              <StyledContentBlock>
                <TabContentTitle i18n={{ id: 'i18n.grantor-name', fallback: 'Grantor Name' }} />
                <Typography element="p" scale="3" fontWeight="400">
                  {data?.grantor_name}
                </Typography>
              </StyledContentBlock>
            )}
            {data?.grantor_type && (
              <StyledContentBlock>
                <TabContentTitle i18n={{ id: 'i18n.grantor-type', fallback: 'Grantor Type' }} />
                <Typography element="p" scale="3" fontWeight="400">
                  {data?.grantor_type}
                </Typography>
              </StyledContentBlock>
            )}
            {data?.incentive_program_contact_info && (
              <StyledContentBlock>
                <TabContentTitle
                  i18n={{ id: 'i18n.grantor-contact-info', fallback: 'Grantor Contact Info' }}
                />
                <Typography element="p" scale="3" fontWeight="400">
                  {data?.incentive_program_contact_info}
                </Typography>
              </StyledContentBlock>
            )}
          </StyledSidebar>
        </StyledGrid>
      </StyledContainer>
    </Box>
  );
};

IncentiveDetailOverviewTab.displayName = 'IncentiveDetailOverviewTab';
