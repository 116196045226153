import { NAMESPACE } from '../../hooks/useTranslationsResources';

export const translations = {
  en: {
    [NAMESPACE]: {
      'i18n.base.footer.disclaimer':
        'The potential incentives described on this site are provided by ZappyRide for informational and reference purposes only and may not be utilized for any advertising or marketing content. Information is updated on a monthly basis. Additional information regarding these potential incentives is available in the changelog section of this site. However, Dealers must consult individual incentive provider websites to confirm specific eligibility and application requirements and consult with their legal and/or tax professionals if they have questions. Incentive availability and eligibility requirements are subject to change at any time. For GM Dealer personnel use only. GM is not responsible for the administration of any incentive program, any required participation costs, or any restrictions, cancellations, or changes that are made to any potential incentives. GM makes no representation, warranty or guarantee with respect to: i) the availability of any potential incentives identified; ii) the actual amount of, or eligibility requirements for, any potential incentives; or iii) the completeness of any lists of potential incentives.',
      'i18n.base.error.zip': 'Invalid Location',
      'i18n.base.empty.currency': '{{value, currency}}',
      'i18n.language.en': 'English',
      'i18n.language.fr': 'French',
      'i18n.pages.incentives.title': 'Commercial Incentives',
      'i18n.pages.incentives.title_consumer': 'Consumer Incentives',
      'i18n.incentives.total_incentives': 'Available Potential Total Vehicle Incentives Up To',
      'i18n.incentives.total_applicable': 'Applicable Incentive Count',
      'i18n.incentives.filter_button.hide': 'Hide Filters',
      'i18n.incentives.filter_button.show': 'Show Filters',
      'i18n.incentives.total_sale': 'Available Potential Vehicle Incentives at Sale Up To',
      'i18n.pages.incentives.subtitle':
        'Search for incentives that may reduce the cost of electric vehicles and charging equipment.',
      'i18n.pages.splashscreen.subtitle':
        'By clicking “I Agree” and proceeding to the site, GM Dealer understands and agrees that the content provided is for informational and reference purposes only. Dealer acknowledges it will not utilize this content for any advertising or marketing content, and Dealer will conduct its own diligence related to potentially available incentives. For GM Dealer personnel use only. Dealer acknowledges that GM is not responsible for the administration of any incentive program, any required participation costs, or any restrictions, cancellations, or changes that are made to any potential incentives, and that GM makes no representation, warranty or guarantee with respect to: i) the availability of any potential incentives identified; ii) the actual amount of, or eligibility requirements for, any potential incentives; or iii) the completeness of any lists of potential incentives. Dealer further acknowledges and agrees that any use of the information contained on or elicited through this site in violation of the above may result in the suspension or termination of Dealer’s access, as determined by GM in its sole discretion.',
      'i18n.pages.splashscreen.agree': 'I Agree',
      'i18n.pages.splashscreen.dealer': 'GM Dealer Dashboard',
      'i18n.pages.splashscreen.modalButton': 'Incentive Type & Region',
      'i18n.pages.splashscreen.modal.update': 'Proceed',
      'i18n.pages.splashscreen.description':
        'How would you like to view incentives? First choose your region.',
      'i18n.pages.splashscreen.descriptionTwo':
        'Next, choose the type of incentives you would like to view.',
      'i18n.pages.splashscreen.btn': 'Proceed',
      'i18n.pages.splashscreen.us': 'USA',
      'i18n.pages.splashscreen.ca': 'Canada',
      'i18n.pages.splashscreen.consumer': 'Consumer',
      'i18n.pages.splashscreen.commercial': 'Commercial',
      'i18n.pages.splashscreen.consumer.description': 'Household incentives (1-2 vehicles)',
      'i18n.pages.splashscreen.commercial.description': 'Business/fleet incentives (2+ vehicles) ',
      'i18n.incentives.current_program_status': 'Status',
      'i18n.incentives.incentive_name': 'Name',
      'i18n.incentives.typical_amount': 'Potential Value',
      'i18n.incentives.equipment': 'Equipment',
      'i18n.incentives.grantor_name': 'Grantor',

      'i18n.incentives.incentive_nature': 'Description',
      'i18n.incentives.incentive_nature.grant': 'Grant',
      'i18n.incentives.incentive_nature.tax_credit': 'Tax credit',
      'i18n.incentives.incentive_nature.tax_exemption': 'Tax exemption',
      'i18n.incentives.incentive_nature.rebate': 'Rebate',
      'i18n.incentives.incentive_nature.voucher': 'Voucher',
      'i18n.incentives.incentive_nature.financing': 'Financing',

      'i18n.incentives.type': 'Type',

      'i18n.pages.heatmap.legend.highIntensity': 'High Intensity',
      'i18n.pages.heatmap.legend.mediumIntensity': 'Medium Intensity',
      'i18n.pages.heatmap.legend.lowIntensity': 'Low Intensity',
      'i18n.pages.heatmap.tooltip.federalIncentives': 'federal incentives',
      'i18n.pages.heatmap.tooltip.stateIncentives': 'state incentives',
      'i18n.pages.heatmap.tooltip.stateIncentives.canada': 'province incentives',
      'i18n.pages.heatmap.tooltip.utilityIncentives': 'utility incentives',
      'i18n.pages.heatmap.tooltip.municipalityIncentives':
        'municipality (city and county) incentives',
      'i18n.pages.heatmap.tooltip.organizationIncentives': 'other organization incentives',

      'i18n.incentives.status.consumer.open': 'Potentially Eligible',
      'i18n.incentives.status.consumer.closed': 'Potentially Ineligible',

      'i18n.incentives.results.current_program_status.current': 'Open',
      'i18n.incentives.results.current_program_status.hiatus': 'Hiatus',
      'i18n.incentives.results.current_program_status.waitlist': 'Waitlist',
      'i18n.incentives.results.current_program_status.closed': 'Closed',
      'i18n.incentives.not_available': 'Incentives are not available in the selected language',
      'i18n.incentives.warning_dialog':
        'For specific incentive evaluations, select the customer situational option from the toggle above.<br/> Note: Potential incentive totals above exclude non-monetary benefits and charger-based incentives which may appear in results below.',
      'i18n.more-details': 'More Details',
      'i18n.results.success.count': '{{count}} Incentives Found',
      'i18n.results.success.count_one': '{{count}} Incentive Found',
      'i18n.results.success.count_other': '{{count}} Incentives Found',
      'i18n.pagination.controls.previous': 'Prev',
      'i18n.pagination.controls.count.preposition': 'of',
      'i18n.pagination.controls.next': 'Next',

      'i18n.incentives.filters.brand.label': 'GM Brand',
      'i18n.incentives.filters.brand.chevrolet': 'Chevrolet',
      'i18n.incentives.filters.brand.gmc': 'GMC',
      'i18n.incentives.filters.brand.cadillac': 'Cadillac',

      'i18n.incentives.filters.model.label': 'Vehicle Model',
      'i18n.incentives.filters.trim.label': 'Vehicle Trim',
      'i18n.incentives.filters.utility.label': 'Utility',

      'i18n.incentives.filters.empty': '-',

      'i18n.incentives.filters.equipment.label': 'Equipment',
      'i18n.incentives.filters.equipment.options.all': 'All',
      'i18n.incentives.filters.equipment.options.car': 'Car',
      'i18n.incentives.filters.equipment.options.truck': 'Truck',
      'i18n.incentives.filters.equipment.options.charger': 'Charger',

      'i18n.incentives.filters.details.equipment':
        'Equipment: Show incentives covering just vehicles, or just charging equipment.',
      'i18n.incentives.filters.details.brand': 'GM Brand: Select a GM Brand to choose a vehicle.',
      'i18n.incentives.filters.details.model': 'Vehicle Model: Select a GM model. ',
      'i18n.incentives.filters.details.trim': 'Vehicle Trim: Select a vehicle trim. ',
      'i18n.incentives.filters.details.utility':
        'Utility: Select your local utility to view utility-specific incentives.',
      'i18n.incentives.filters.details.income':
        'Household income: What is your approximate household income? This is used to determine incentive eligibility.',
      'i18n.incentives.filters.details.size': 'Household Size: What is your household size?',
      'i18n.incentives.filters.details.tax':
        'Tax Status: What is your tax status? This is used to determine incentive eligibility.',
      'i18n.incentives.filters.details.retire':
        'Retire Vehicle: Will you turn in an old gasoline vehicle to buy a new EV or PHEV?',

      'i18n.incentives.filters.status.label': 'Status',
      'i18n.incentives.filters.status.options.hiatus': 'Hiatus',
      'i18n.incentives.filters.status.options.all': 'All',
      'i18n.incentives.filters.status.options.open': 'Open',
      'i18n.incentives.filters.status.options.waitlist': 'Waitlist',
      'i18n.incentives.filters.status.options.closed': 'Closed',

      'i18n.incentives.filters.location.input': 'Location',
      'i18n.incentives.filters.buttons.view-incentives': 'View Incentives',
      'i18n.incentive.typical_amount': 'Potential Value',
      'i18n.incentive.grantor_name': 'State',
      'i18n.incentive.grantor_name.federal': 'Federal',
      'i18n.incentive.grantor_name.utility': 'Utility',
      'i18n.incentive.grantor_name.miscellaneous': 'Miscellaneous',
      'i18n.incentive.expiration_date': 'Deadline',
      'i18n.incentive.tab.overview': 'Overview',
      'i18n.incentive.tab.eligibility': 'Eligibility',
      'i18n.incentive.tab.consumer.eligibility': 'Vehicle Eligibility',
      'i18n.incentive.tab.consumer.overview': 'General Information',
      'i18n.incentive.tab.funding': 'Funding',
      'i18n.incentive.tab.changelog': 'Changelog',
      'i18n.incentive.current_program_status': 'Status',
      'i18n.incentive.current_eligibility': 'Eligiblity',
      'i18n.incentive.current_utility': 'Utility',
      'i18n.incentive.potential_amount': 'Potential Amount',
      'i18n.incentive.current_applicability': 'Applicability',
      'i18n.current_program_status.open.expiration_countdown.noExp': 'NO EXP DATE',
      'i18n.current_program_status.open.expiration_countdown.noExpiration': 'No expiration date',
      'i18n.current_program_status.open.expiration_countdown.years': 'yr left',
      'i18n.current_program_status.open.expiration_countdown.years.plural': "yr's left",
      'i18n.apply': 'Visit Grantor Site',
      'i18n.general-information': 'General Information',
      'i18n.incentive-name': 'Incentive Name',
      'i18n.incentive-description': 'Incentive Description',
      'i18n.incentive-scope': 'Incentive Scope',
      'i18n.grantor-name': 'Grantor Name',
      'i18n.grantor-type': 'Grantor Type',
      'i18n.funding.title': 'Funding Details',
      'i18n.funding.totalfunding': 'Total Funding',
      'i18n.funding.currentfunding': 'Current Funding',
      'i18n.funding.fundingsituation': 'Funding Situation Description',
      'i18n.funding.methodologydescription': 'Methodology Description',
      'i18n.funding.capperproject': 'Funding Cap per Project',
      'i18n.funding.capperapplicant': 'Funding Cap per Applicant',
      'i18n.changelog.main.title':
        'Incentive details are updated as new information becomes available. This changelog captures those changes.',
      'i18n.changelog.date': 'Date',
      'i18n.changelog.changes': 'Changes',
      'i18n.changelog.nextupdate': 'Next Review By*',
      'i18n.changelog.footnote': '* Reviews occur within 7 days of the specified date',
      'i18n.current_program_status.open.expiration_countdown.months': 'mo left',
      'i18n.current_program_status.open.expiration_countdown.months.plural': "mo's left",
      'i18n.current_program_status.open.expiration_countdown.weeks': 'w left',
      'i18n.current_program_status.open.expiration_countdown.days': 'd left',
      'i18n.incentives.filters.status.tooltip.open.title': 'Open',
      'i18n.incentives.filters.location.tooltip.open.p.actively':
        'Actively accepting applications.',
      'i18n.incentives.filters.status.tooltip.hiatus.title': 'Hiatus',
      'i18n.incentives.filters.location.tooltip.hiatus.p.applications':
        'Applications are being accepted, but not processed. Processing will resume at some point in the future.',
      'i18n.incentives.filters.status.tooltip.waitlist.title': 'Waitlist',
      'i18n.incentives.filters.location.tooltip.waitlist.p.not-currently':
        'Not currently being accepting new applications, but are expected to resume upon additional funding.',
      'i18n.incentives.filters.status.tooltip.closed.title': 'Closed',
      'i18n.incentives.filters.location.tooltip.closed.p.no-longer':
        'No longer accepting new applications. Not expected to receive additional funding. For reference purposes only.',
      'i18n.incentives.filters.location.tooltip.eligibility.title': 'Eligibility',
      'i18n.incentives.filters.location.tooltip.eligibility.p.show':
        'Show incentives available in a specified US zip code or Canadian postal code.',
      'i18n.incentives.switch.options.usa': 'USA',
      'i18n.incentives.switch.options.canada': 'Canada',
      'i18n.incentives.switch.options.commercial': 'Commercial',
      'i18n.incentives.switch.options.consumer': 'Consumer',
      'i18n.incentives.switch.options.search': 'Incentive Search',
      'i18n.incentives.switch.options.heatmap': 'Incentive Heatmap',
      'i18n.incentives.switch.title.incentive': 'Incentive Type',
      'i18n.incentives.switch.title.view': 'Incentive View',
      'i18n.incentives.switch.title.region': 'Region',
      'i18n.funding.remaining': 'remaining',
      'i18n.application-window': 'Application Window',
      'i18n.eligibility.projecttype': 'Eligible Project Type',
      'i18n.eligibility.projectdescription': 'Eligible Project Description',
      'i18n.funding.titleDetails': 'Other Eligibility Details',
      'i18n.eligibility.organizations': 'Eligible Organizations',
      'i18n.eligibility.lease': 'Lease Eligible',
      'i18n.eligibility.beneficiary': 'Lease Beneficiary',
      'i18n.eligibility.assumptions': 'Eligibility Assumptions',

      'i18n.incentives.filters.form_factor.options.cargo_van': 'Cargo Van',
      'i18n.incentives.filters.form_factor.options.large_SUV': 'Large Luxury SUV',
      'i18n.incentives.filters.form_factor.options.pickup_truck': 'Large Pickup',
      'i18n.incentives.filters.form_factor.options.mid_SUV': 'Midsize SUV',
      'i18n.incentives.filters.form_factor.options.small_suv': 'Small SUV',
      'i18n.incentives.filters.form_factor.options.small_mpv': 'Small MPV',
      'i18n.incentives.filters.form_factor.options.all_segments': 'All Segments',
      'i18n.incentives.filters.form_factor.label': 'Vehicle Segment',
      'i18n.incentives.is_eligible_to_specified_form_factors_only': 'Eligible Equipment',
      'i18n.incentives.filters.location.input.placeholder': 'Zip Code',
      'i18n.incentives.filters.location.input.placeholder.canada': 'Postal Code',
      'i18n.incentives.filters.location.input.alert': 'Canada Format - A1A 1A1',
      'i18n.incentives.filters.location.input.alertUS': 'US Format - 12345',

      'i18n.incentives.filters.consumer.income': 'Household Income',
      'i18n.incentives.filters.consumer.size': 'Household Size',
      'i18n.incentives.filters.consumer.tax': 'Tax Status',
      'i18n.incentives.filters.consumer.retire': 'Retire Vehicle',
      'i18n.incentives.filters.consumer.purchase': 'Purchase Type',
      'i18n.incentives.filters.consumer.empty': '--',
      'i18n.incentives.filters.consumer.number.one': '1',
      'i18n.incentives.filters.consumer.number.two': '2',
      'i18n.incentives.filters.consumer.number.three': '3',
      'i18n.incentives.filters.consumer.number.four': '4',
      'i18n.incentives.filters.consumer.number.five': '5',
      'i18n.incentives.filters.consumer.number.six': '6',
      'i18n.incentives.filters.consumer.number.seven': '7',
      'i18n.incentives.filters.consumer.number.eight': '8',
      'i18n.incentives.filters.consumer.tax.single': 'Single',
      'i18n.incentives.filters.consumer.tax.head': 'Head of Household',
      'i18n.incentives.filters.consumer.tax.joint': 'Joint',
      'i18n.incentives.filters.consumer.retire.yes': 'Yes',
      'i18n.incentives.filters.consumer.retire.no': 'No',
      'i18n.incentives.filters.consumer.purchase.cash': 'Cash',
      'i18n.incentives.filters.consumer.purchase.lease': 'Lease',

      'i18n.results.idle': 'Search for incentives using the filters above.',
      'i18n.results.empty': 'No state or local incentives apply to this zip code',
      'i18n.results.empty.canada': 'No provincial or local incentives apply to this zip code',
      'i18n.results.error': 'Something went wrong.',
      'i18n.common.yes': 'Yes',
      'i18n.common.no': 'No',
      'i18n.common.na': 'N/A',
      'i18n.changelog.amountperrebate': 'Updated amount per rebate',
      'i18n.changelog.currentfunding': 'Updated current funding',
      'i18n.changelog.currentfundingsituationdescription':
        'Updated current funding situation description',
      'i18n.changelog.currentfundingstatus': 'Updated current funding status',
      'i18n.changelog.currentprogramstatus': 'Updated current program status',
      'i18n.changelog.currentprogramstatuscurrentfundingstatus':
        'Updated current program status, current funding status',
      'i18n.changelog.currentprogramstatuscurrentfundingstatusfundingsituationdescription':
        'Updated current program status, current funding status, funding situation description',
      'i18n.changelog.eligibilityassumptions': 'Updated eligibility assumptions',
      'i18n.changelog.eligiblechargingrequirements': 'Updated eligible charging requirements',
      'i18n.changelog.eligiblechargingtechnology': 'Updated eligible charging technology',
      'i18n.changelog.eligiblechargingtechnologydescription':
        'Updated eligible charging technology description',
      'i18n.changelog.eligiblechargingtechnologydescriptionj':
        'Updated eligible charging technology descriptionj',
      'i18n.changelog.eligiblefueltypes': 'Updated eligible fuel types',
      'i18n.changelog.eligibleorganizationclassificationdescription':
        'Updated eligible organization classification description',
      'i18n.changelog.eligibleorganizationotherdescription':
        'Updated eligible organization other description',
      'i18n.changelog.eligibleorganizations': 'Updated eligible organizations',
      'i18n.changelog.eligibleprojectaccessory': 'Updated eligible project accessory',
      'i18n.changelog.eligibleprojecttypedescription': 'Updated eligible project type description',
      'i18n.changelog.eligibleprojecttypes': 'Updated eligible project types',
      'i18n.changelog.eligiblescrappagerequirement': 'Updated eligible scrappage requirement',
      'i18n.changelog.eligiblescrappagerequirementdescription':
        'Updated eligible scrappage requirement description',
      'i18n.changelog.eligiblescrappagerequirementdescriptiont':
        'Updated eligible scrappage requirement descriptiont',
      'i18n.changelog.eligiblevehicleduties': 'Updated eligible vehicle duties',
      'i18n.changelog.eligiblevehicletype': 'Updated eligible vehicle type',
      'i18n.changelog.eligiblevehicletypedescription': 'Updated eligible vehicle type description',
      'i18n.changelog.eligiblevehicleweightclasses': 'Updated eligible vehicle weight classes',
      'i18n.changelog.expirationdate': 'Updated expiration date',
      'i18n.changelog.fundingcapperapplicant': 'Updated funding cap per applicant',
      'i18n.changelog.fundingcapperproject': 'Updated funding cap per project',
      'i18n.changelog.fundingsituationdescription': 'Updated funding situation description',
      'i18n.changelog.grantorname': 'Updated grantor name',
      'i18n.changelog.grantortype': 'Updated grantor type',
      'i18n.changelog.incentiveapplicationURL': 'Updated incentive application URL',
      'i18n.changelog.incentivecalculationmethodologydescription':
        'Updated incentive calculation methodology description',
      'i18n.changelog.incentivedescription': 'Updated incentive description',
      'i18n.changelog.incentivefocus': 'Updated incentive focus',
      'i18n.changelog.incentivename': 'Updated incentive name',
      'i18n.changelog.incentivenature': 'Updated incentive nature',
      'i18n.changelog.incentiveprogramcontactinfo': 'Updated incentive program contact info',
      'i18n.changelog.incentivescope': 'Updated incentive scope',
      'i18n.changelog.incentivescopedescription': 'Updated incentive scope description',
      'i18n.changelog.incentivescopeother': 'Updated incentive scope other',
      'i18n.changelog.incentiveURL': 'Updated incentive URL',
      'i18n.changelog.iseligibletospecifiedformfactorsonly':
        'Updated is eligible to specified form factors only',
      'i18n.changelog.isleaseeligible': 'Updated is lease eligible',
      'i18n.changelog.leasebeneficiary': 'Updated lease beneficiary',
      'i18n.changelog.mutuallyexclusiveprograms': 'Updated mutually exclusive programs',
      'i18n.changelog.mutuallyexclusiveprogramsoriginal':
        'Updated mutually exclusive programs original',
      'i18n.changelog.stackabilitynotes': 'Updated stackability notes',
      'i18n.changelog.startdate': 'Updated start date',
      'i18n.changelog.state': 'Updated state',
      'i18n.changelog.totalfunding': 'Updated total funding',
      'i18n.changelog.typicalamount': 'Updated typical amount',
      'i18n.changelog.typicalamountdescription': 'Updated typical amount description',
      'i18n.changelog.workplaceEVSERebate': 'Updated Workplace EVSE Rebate',
      'i18n.changelog.noChange': 'No change',
      'i18n.changelog.created': 'Created',
      'i18n.consumer.detail.name': 'Incentive name',
      'i18n.consumer.detail.type': 'Purchase type',
      'i18n.consumer.detail.description': 'Incentive description',
      'i18n.consumer.detail.details': 'More details',
      'i18n.consumer.detail.eligibility.type': 'Eligible Vehicle Type',
      'i18n.consumer.detail.eligibility.segments': 'Eligible Vehicle Segments',
      'i18n.consumer.detail.eligibility.fuel': 'Eligible Vehicle Fuel Types',
      'i18n.consumer.detail.eligibility.description': 'Eligible Vehicle Description',
      'i18n.consumer.detail.eligibility.new': 'New',
      'i18n.consumer.detail.eligibility.used': 'Used',
      'i18n.consumer.detail.eligibility.onroad': 'Onroad',
      'i18n.consumer.detail.eligibility.passenger': 'Passenger',
      'i18n.consumer.detail.cards.eligible': 'Potentially eligible',
      'i18n.consumer.detail.cards.ineligible': 'Potentially ineligible',
      'i18n.consumer.detail.cards.per_type': 'per vehicle or charger',
      'i18n.consumer.detail.cards.purchase_deducted': 'Deducted from purchase price',
      'i18n.consumer.detail.cards.purchase_reseived': 'Received after purchase',
      'i18n.incentives.switch.view':
        'The incentive heatmap shows the general availability of incentives across the country, while these incentive search view shows incentive amounts consumers may qualify for based on their vehicle of interest and buyer profile.',
      'i18n.incentives.labels.incentive_name': 'Name:',
      'i18n.incentives.labels.typical_amount': 'Potential Value:',
      'i18n.incentives.labels.grantor_name': 'Grantor:',
      'i18n.incentives.labels.Grant': 'Description:',
    },
  },
  fr: {
    [NAMESPACE]: {
      'i18n.pages.splashscreen.description':
        "Comment souhaiteriez-vous voir les incitatifs ? Choisissez d'abord votre région.",
      'i18n.pages.splashscreen.descriptionTwo':
        "Ensuite, choisissez le type d'incitatifs que vous souhaitez consulter.",
      'i18n.base.footer.disclaimer':
        'Les incitations potentielles décrites sur ce site sont fournies par ZappyRide à des fins d information et de référence uniquement et ne peuvent être utilisées pour aucun contenu publicitaire ou marketing. Les informations sont mises à jour mensuellement. Des informations supplémentaires concernant ces incitations potentielles sont disponibles dans la section changelog de ce site. Cependant, les concessionnaires doivent consulter les sites Web des fournisseurs d incitatifs individuels pour confirmer l admissibilité et les exigences de demande spécifiques et consulter leurs professionnels juridiques et/ou fiscaux sils ont des questions. La disponibilité des incitatifs et les conditions dadmissibilité sont susceptibles d être modifiées à tout moment. Réservé au personnel du concessionnaire GM. GM nest pas responsable de l administration de tout programme incitatif, des frais de participation requis ou de toute restriction, annulation ou modification apportée à tout incitatif potentiel. GM ne fait aucune représentation ou garantie concernant : i) la disponibilité de toute incitation potentielle identifiée ; ii) le montant réel ou les critères d éligibilité pour toute incitation potentielle ; ou iii) l exhaustivité de toute liste d incitatifs potentiels.',
      'i18n.base.error.zip': 'Location invalide',
      'i18n.base.empty.currency': '{{value, currency}}',
      'i18n.language.en': 'Anglaise',
      'i18n.language.fr': 'Français',
      'i18n.pages.incentives.title': 'Incitatifs commerciales',
      'i18n.pages.incentives.subtitle':
        'Rechercher des incitations susceptibles de réduire le coût des véhicules électriques et des équipements de recharge.',
      'i18n.pages.splashscreen.subtitle':
        "En cliquant sur « J'accepte » et en accédant au site Web, le concessionnaire GM comprend et accepte que le contenu fourni l'est à titre indicatif et informationnel seulement. Le concessionnaire reconnaît qu'il n'utilisera pas ce contenu à des fins de publicité ou de marketing de contenu et le concessionnaire mènera ses propres recherches par rapport aux incitatifs potentiellement disponibles. Uniquement à des fins d'usage personnel pour les concessionnaires GM. Le concessionnaire reconnaît que GM n'est pas responsable de l'administration d'un quelconque programme d'incitatifs, des coûts de participation requis ou de toute restriction, annulation ou changement qui sont apportées à un quelconque incitatif potentiel et que GM ne fait aucune représentation, aucun engagement formel ou garantie en regard de : i) la disponibilité d'un quelconque incitatif potentiel identifié; ii) du nombre actuel, ou des critères d'admissibilité, d'un quelconque incitatif potentiel; ou iii) la complétude de toute liste d'incitatifs potentiels. Le concessionnaire reconnaît et accepte également que toute utilisation de l'information contenue sur ou créée à travers ce site en violation de ce qui précède peut résulter en la suspension ou la cessation de l'accès du concessionnaire, tel que déterminé par GM selon sa propre discrétion.",
      'i18n.pages.splashscreen.agree': 'J’accepte',
      'i18n.pages.splashscreen.btn': 'Procéder',
      'i18n.incentives.current_program_status': 'Statut',
      'i18n.incentives.incentive_name': 'Nom',
      'i18n.incentives.typical_amount': 'Valeur potentielle',
      'i18n.incentives.grantor_name': 'Donateur',
      'i18n.incentives.results.current_program_status.current': 'Ouvert',
      'i18n.incentives.results.current_program_status.hiatus': 'Hiatus',
      'i18n.incentives.results.current_program_status.waitlist': "Liste d'attente",
      'i18n.incentives.results.current_program_status.closed': 'Fermé',
      'i18n.incentives.switch.title.view': "Vue d'incitatives",
      'i18n.more-details': 'Plus de détails',
      'i18n.results.success.count': '{{count}} Incitatifs Trouvé',
      'i18n.results.success.count_one': '{{count}} Incitatif Trouvé',
      'i18n.results.success.count_other': '{{count}} Incitatifs Trouvé',
      'i18n.pagination.controls.previous': 'Pré',
      'i18n.pagination.controls.count.preposition': 'de',
      'i18n.pagination.controls.next': 'Suivant',
      'i18n.incentives.filters.status.label': 'Statut',
      'i18n.incentives.filters.status.options.hiatus': 'Hiatus',
      'i18n.incentives.filters.status.options.all': 'Tous',
      'i18n.incentives.filters.status.options.open': 'Ouvert',
      'i18n.incentives.filters.status.options.waitlist': "Liste d'attente",
      'i18n.incentives.filters.status.options.closed': 'Fermé',
      'i18n.incentives.filters.consumer.tax.single': 'Célibataire',
      'i18n.incentives.filters.consumer.tax.head': 'Tête de Famille',
      'i18n.incentives.filters.consumer.tax.joint': 'Conjointe',
      'i18n.incentives.filters.consumer.retire.yes': 'Oui',
      'i18n.incentives.filters.consumer.retire.no': 'Non',
      'i18n.incentives.filters.consumer.purchase.cash': 'Comptant',
      'i18n.incentives.filters.consumer.purchase.lease': 'Location',
      'i18n.incentives.filters.equipment.options.truck': 'Camion',
      'i18n.incentives.not_available':
        'Les incitations ne sont pas disponibles dans la langue sélectionnée',
      'i18n.incentives.filters.location.input': 'Emplacement',
      'i18n.incentives.filters.buttons.view-incentives': 'Voir les incitatifs',
      'i18n.incentive.typical_amount': 'Valeur potentielle',
      'i18n.incentive.grantor_name': 'État',
      'i18n.incentive.grantor_name.federal': 'Fédéral',
      'i18n.incentive.grantor_name.utility': 'Utilitaire',
      'i18n.incentive.grantor_name.miscellaneous': 'Divers',
      'i18n.incentive.expiration_date': 'Date butoir',
      'i18n.incentive.tab.overview': 'Aperçu',
      'i18n.incentive.tab.eligibility': 'Admissibilité',
      'i18n.incentive.tab.funding': 'Financement',
      'i18n.incentive.tab.changelog': 'Journal des modifications',
      'i18n.incentive.current_program_status': 'Statut',
      'i18n.current_program_status.open.expiration_countdown.noExp': 'PAS DE DATE EXP',
      'i18n.current_program_status.open.expiration_countdown.noExpiration':
        "Pas de date d'expiration",
      'i18n.current_program_status.open.expiration_countdown.years': ' année restante',
      'i18n.current_program_status.open.expiration_countdown.years.plural': ' années restantes',
      'i18n.apply': 'Postuler',
      'i18n.general-information': 'Informations générales',
      'i18n.incentive-name': "Nom de l'incitatif",
      'i18n.incentive-description': "Description de l'incitatif",
      'i18n.incentive-scope': "Paramètres de l'incitatif",
      'i18n.grantor-name': 'Nom du donateur',
      'i18n.grantor-type': 'Type de donateur',
      'i18n.funding.title': 'Détails du financement',
      'i18n.funding.totalfunding': 'Total du financement',
      'i18n.funding.currentfunding': 'Financement actuel',
      'i18n.funding.fundingsituation': 'Description de la situation de financement',
      'i18n.funding.methodologydescription': 'Description de la méthodologie',
      'i18n.funding.capperproject': 'Limite de financement par projet',
      'i18n.funding.capperapplicant': 'Limite de financement par candidature',
      'i18n.changelog.main.title':
        'Les détails des incitatifs sont mis à jour au fur et à mesure que de nouvelles informations sont disponibles. Ce journal des modifications affiche ces changements.',
      'i18n.changelog.date': 'Date',
      'i18n.changelog.changes': 'Modifications',
      'i18n.changelog.nextupdate': 'Prochaine examen par*',
      'i18n.changelog.footnote':
        '* Les révisions ont lieu dans les 7 jours suivant la date spécifiée',
      'i18n.current_program_status.open.expiration_countdown.months': 'mois restant',
      'i18n.current_program_status.open.expiration_countdown.months.plural': 'mois restants',
      'i18n.current_program_status.open.expiration_countdown.weeks': ' semaines restantes',
      'i18n.current_program_status.open.expiration_countdown.days': ' jours restants',
      'i18n.incentives.filters.status.tooltip.open.title': 'Ouvert',
      'i18n.incentives.filters.location.tooltip.open.p.actively':
        'Accepte activement les demandes.',
      'i18n.incentives.filters.status.tooltip.hiatus.title': 'Hiatus',
      'i18n.incentives.filters.location.tooltip.hiatus.p.applications':
        "Les demandes sont acceptées, mais non traitées. Le traitement reprendra à un certain moment dans l'avenir.",
      'i18n.incentives.filters.status.tooltip.waitlist.title': "Liste d'attente",
      'i18n.incentives.filters.location.tooltip.waitlist.p.not-currently':
        "N'accepte pas de nouvelles demandes pour le moment, mais est censé reprendre une fois le financement disponible.",
      'i18n.incentives.filters.status.tooltip.closed.title': 'Fermé',
      'i18n.incentives.filters.location.tooltip.closed.p.no-longer':
        "N'accepte plus de nouvelles demandes. N'est pas censé recevoir de financement additionnel. En guise de référence seulement.",
      'i18n.incentives.filters.location.tooltip.eligibility.title': 'Admissibilité',
      'i18n.incentives.filters.location.tooltip.eligibility.p.show':
        'Montrer les incitatifs disponibles pour un code postal canadien ou américain.',
      'i18n.funding.remaining': 'restant',
      'i18n.application-window': 'Fenêtre de candidature',
      'i18n.eligibility.projecttype': 'Type de projet admissible',
      'i18n.eligibility.projectdescription': 'Description du projet admissible',
      'i18n.funding.titleDetails': "Autres détails sur l'admissibilité",
      'i18n.eligibility.organizations': 'Organismes admissibles',
      'i18n.eligibility.lease': 'Admissible à la location',
      'i18n.eligibility.beneficiary': 'Bénéficiaire de la location',
      'i18n.eligibility.assumptions': "Critères d'admissibilité",
      'i18n.incentives.filters.form_factor.options.cargo_van': 'Fourgonnette cargo',
      'i18n.incentives.filters.form_factor.options.large_SUV': 'Grand haut de gamme',
      'i18n.incentives.filters.form_factor.options.pickup_truck': 'Grand Pickup',
      'i18n.incentives.filters.form_factor.options.mid_SUV': 'Moyen SUV',
      'i18n.incentives.filters.form_factor.options.small_suv': 'Petit SUV',
      'i18n.incentives.filters.form_factor.options.small_mpv': 'Petit monospace',
      'i18n.incentives.filters.form_factor.options.all_segments': 'Tous les segments',
      'i18n.incentives.filters.form_factor.label': 'Segment de véhicules',
      'i18n.incentives.is_eligible_to_specified_form_factors_only': 'Équipement admissible',
      'i18n.incentives.filters.location.input.placeholder': 'Code Postal',
      'i18n.incentives.filters.location.input.placeholder.canada': 'Code Postal',
      'i18n.results.idle': 'Rechercher des incitatifs en utilisant les filtres ci-dessus.',
      'i18n.results.empty': "Aucune incitation nationale ou locale ne s'applique à ce code postal",
      'i18n.results.empty.canada':
        "Aucune incitation provinciale ou locale ne s'applique à ce code postal",

      'i18n.incentives.filters.consumer.income': 'Revenu du ménage',
      'i18n.incentives.filters.consumer.size': 'La taille du ménage',
      'i18n.incentives.filters.consumer.tax': 'Statut fiscal',
      'i18n.incentives.filters.consumer.retire': 'Véhicule à la retraite',
      'i18n.incentives.filters.consumer.purchase': 'Type d’Achat',

      'i18n.results.error': "Une erreur s'est produite.",
      'i18n.common.yes': 'Oui',
      'i18n.common.no': 'Non',
      'i18n.common.na': 'Indisponible',
      'i18n.changelog.amountperrebate': 'Montant mis à jour par remise',
      'i18n.changelog.currentfunding': 'Financement actuel mis à jour',
      'i18n.changelog.currentfundingsituationdescription':
        'Description mise à jour de la situation actuelle de financement',
      'i18n.changelog.currentfundingstatus': 'État actuel du financement mis à jour',
      'i18n.changelog.currentprogramstatus': 'État actuel du régime mis à jour',
      'i18n.changelog.currentprogramstatuscurrentfundingstatus':
        'État actuel du régime mis à jour, état actuel du financement',
      'i18n.changelog.currentprogramstatuscurrentfundingstatusfundingsituationdescription':
        'État actuel du régime mis à jour, état actuel du financement, description de la situation de financement',
      'i18n.changelog.eligibilityassumptions': "Hypothèses d'admissibilité mises à jour",
      'i18n.changelog.eligiblechargingrequirements': 'Critères de recharge admissible mis à jour',
      'i18n.changelog.eligiblechargingtechnology': 'Technologie de recharge admissible mise à jour',
      'i18n.changelog.eligiblechargingtechnologydescription':
        'Description de la technologie de recharge admissible mise à jour',
      'i18n.changelog.eligiblechargingtechnologydescriptionj':
        'Descriptionj de la technologie de recharge admissible mise à jour',
      'i18n.changelog.eligiblefueltypes': 'Types de carburant admissibles mis à jour',
      'i18n.changelog.eligibleorganizationclassificationdescription':
        'Description de la classification des organisatons admissibles mise à jour',
      'i18n.changelog.eligibleorganizationotherdescription':
        'Autre description des organisations admissibles mise à jour',
      'i18n.changelog.eligibleorganizations': 'Organisations admissibles mises à jour',
      'i18n.changelog.eligibleprojectaccessory': 'Accessoire de projet admissible mis à jour',
      'i18n.changelog.eligibleprojecttypedescription':
        'Description du type de projet admissible mise à jour',
      'i18n.changelog.eligibleprojecttypes': 'Types de projet admissibles mis à jour',
      'i18n.changelog.eligiblescrappagerequirement':
        'Critères admissibles de mise à la casse mis à jour',
      'i18n.changelog.eligiblescrappagerequirementdescription':
        'Description des critères admissibles de mise à la casse mise à jour',
      'i18n.changelog.eligiblescrappagerequirementdescriptiont':
        'Descriptionj des critères admissibles de mise à la casse mise à jour',
      'i18n.changelog.eligiblevehicleduties': 'Fonctions des véhicules admissibles mises à jour',
      'i18n.changelog.eligiblevehicletype': 'Type de véhicule admissible mis à jour',
      'i18n.changelog.eligiblevehicletypedescription': 'Description du type de véhicule mis à jour',
      'i18n.changelog.eligiblevehicleweightclasses':
        'Catégories de poids des véhicules mises à jour',
      'i18n.changelog.expirationdate': "Date d'expiration mise à jour",
      'i18n.changelog.fundingcapperapplicant': 'Plafond de financement par candidature mis à jour',
      'i18n.changelog.fundingcapperproject': 'Plafond de financement par projet mis à jour',
      'i18n.changelog.fundingsituationdescription':
        'Description de la situation de financement mise à jour',
      'i18n.changelog.grantorname': 'Nom du donateur mis à jour',
      'i18n.changelog.grantortype': 'Type de donateur mis à jour',
      'i18n.changelog.incentiveapplicationURL': "URL de la demande d'incitatif mise à jour",
      'i18n.changelog.incentivecalculationmethodologydescription':
        "Description de la méthodologie de calcul de l'incitatif mise à jour",
      'i18n.changelog.incentivedescription': "Description de l'incitatif mis à jour",
      'i18n.changelog.incentivefocus': "Concentration de l'incitatif mise à jour",
      'i18n.changelog.incentivename': "Nom de l'incitatif mis à jour",
      'i18n.changelog.incentivenature': "Nature de l'incitatif mise à jour",
      'i18n.changelog.incentiveprogramcontactinfo':
        "Informations de contact du régime d'incitatif mises à jour",
      'i18n.changelog.incentivescope': "Portée de l'incitatif mise à jour",
      'i18n.changelog.incentivescopedescription':
        "Description de la portée de l'incitatif mise à jour",
      'i18n.changelog.incentivescopeother': "Autre portée de l'incitatif mise à jour",
      'i18n.changelog.incentiveURL': "URL de l'incitatif mis à jour",
      'i18n.changelog.iseligibletospecifiedformfactorsonly':
        "La mise à jour n'est admissible que pour certaines formes de facteurs seulement",
      'i18n.changelog.isleaseeligible': 'La mise à jour est admissible à la location',
      'i18n.changelog.leasebeneficiary': 'Bénéficiaire de la location mis à jour',
      'i18n.changelog.mutuallyexclusiveprograms': 'Régimes mutuellement exclusifs mis à jour',
      'i18n.changelog.mutuallyexclusiveprogramsoriginal':
        'Régimes originaux mutuellement exclusifs mis à jour',
      'i18n.changelog.stackabilitynotes': "Notes d'empillement mises à jour",
      'i18n.changelog.startdate': 'Date de commencement mise à jour',
      'i18n.changelog.state': 'État mis à jour',
      'i18n.changelog.totalfunding': 'Financement total mis à jour',
      'i18n.changelog.typicalamount': 'Montant typique mis à jour',
      'i18n.changelog.typicalamountdescription': 'Description du montant typique mise à jour',
      'i18n.changelog.workplaceEVSERebate': 'Rabais pour lieux de travail EVSE mis à jour',
      'i18n.changelog.noChange': 'Pas de changement',
      'i18n.changelog.created': 'Créé',
      'i18n.pages.heatmap.legend.highIntensity': 'Intensité haute',
      'i18n.pages.heatmap.legend.mediumIntensity': 'Intensité moyenne',
      'i18n.pages.heatmap.legend.lowIntensity': 'Intensité faible',
      'i18n.pages.heatmap.tooltip.federalIncentives': 'incitatifs fédérales',
      'i18n.pages.heatmap.tooltip.stateIncentives': "incitatifs de l'État",
      'i18n.pages.heatmap.tooltip.stateIncentives.canada': 'incitatifs provinciaux',
      'i18n.pages.heatmap.tooltip.utilityIncentives':
        "incitatifs des services publics d'électricité",
      'i18n.pages.heatmap.tooltip.municipalityIncentives':
        'incitatifs municipales (ville et comté)',
      'i18n.pages.heatmap.tooltip.organizationIncentives': 'incitatifs des autres organisations',
      'i18n.pages.splashscreen.modalButton': "Type d'incitatifs et région",
      'i18n.pages.splashscreen.dealer': 'Vue incitatif',
      'i18n.pages.splashscreen.us': 'Etats-Unis',
      'i18n.pages.splashscreen.consumer': 'Consommateur',
      'i18n.pages.splashscreen.commercial': 'Commerciaux',
      'i18n.pages.splashscreen.modal.update': 'Réactualiser les incitatifs',
      'i18n.incentives.switch.options.search': "Recherche d'incitatifs",
      'i18n.incentives.switch.options.heatmap': "Carte thermique d'incitatifs",
      'i18n.pages.incentives.title_consumer': 'Incitatifs consommateur',
      'i18n.incentives.total_incentives': "Incitatifs totales potentielles disponibles jusqu'à",
      'i18n.incentives.total_applicable': "Nombre d'incitations applicables",
      'i18n.incentives.total_sale': "Incitatifs potentiels disponibles en vente jusqu'à",
      'i18n.incentives.filters.location.input.alertUS': 'Format américain - 12345',
      'i18n.incentives.filters.location.input.alert': 'Format Canada - A1A 1A1',
      'i18n.incentives.equipment': 'Équipement',
      'i18n.incentives.filters.equipment.label': 'Équipement',
      'i18n.incentives.filters.equipment.options.all': 'Tous',
      'i18n.incentives.filters.equipment.options.car': 'Voiture',
      'i18n.incentives.filters.equipment.options.charger': 'Chargeur',
      'i18n.incentives.filters.brand.label': 'Marque GM',
      'i18n.incentives.filters.model.label': 'Modèle de véhicule',
      'i18n.incentives.filters.trim.label': 'Garniture du véhicule',
      'i18n.incentives.filters.utility.label': 'Utilité publique',
      'i18n.incentives.warning_dialog':
        "Pour des évaluations d'Incitatifs spécifiques, sélectionnez l'option d'affichage à partir de la bascule ci-dessus.",
      'i18n.incentives.filters.location.tooltip.high.intensity':
        "Incitations de l'État avec des incitations supérieures à 1 000 $ par véhicule ou chargeur.",
      'i18n.incentives.filters.location.tooltip.medium.intensity':
        "Incitatifs de l'État inférieurs à 1 000 $ par véhicule ou chargeur. Incitatifs des services publics, des municipalités et d'autres organisations d'au moins 1 000 $ par véhicule ou chargeur.",
      'i18n.incentives.filters.location.tooltip.low.intensity':
        "Incitatifs des services publics, des municipalités et d'autres organisations inférieurs à 1 000 $ par véhicule ou chargeur.",

      'i18n.incentives.filters.details.equipment':
        'Équipement : affichez des incitatifs couvrant uniquement les véhicules ou uniquement les équipements de recharge.',
      'i18n.incentives.filters.details.brand':
        'Marque GM : Sélectionnez une marque GM pour choisir un véhicule.',
      'i18n.incentives.filters.details.model': 'Modèle de véhicule : sélectionnez un modèle GM.',
      'i18n.incentives.filters.details.trim':
        'Garniture du véhicule : sélectionnez une garniture de véhicule.',
      'i18n.incentives.filters.details.utility':
        'Utilitaire : sélectionnez votre service public local pour afficher les incitatifs spécifiques au service public.',
      'i18n.incentives.filters.details.income':
        'Revenu du ménage : quel est le revenu approximatif de votre ménage ? Ceci est utilisé pour déterminer l’éligibilité aux incitatifs.',
      'i18n.incentives.filters.details.size':
        'Taille du ménage : Quelle est la taille de votre ménage ?',
      'i18n.incentives.filters.details.tax':
        'Statut fiscal : Quel est votre statut fiscal ? Ceci est utilisé pour déterminer l’éligibilité aux incitatifs.',
      'i18n.incentives.filters.details.retire':
        'Véhicule à la retraite : Allez-vous remettre un vieux véhicule à essence pour acheter un nouveau véhicule électrique ou rechargeable ?',
      'i18n.printed_format': 'Imprimé le',
      'i18n.incentives.switch.view':
        "La carte thermique des incitatifs montre la disponibilité générale des incitations à travers le pays, tandis que cette vue de recherche d'incitations montre les montants d'incitation auxquels les consommateurs peuvent avoir droit en fonction du véhicule qui les intéresse et de leur profil d'acheteur.",
      'i18n.incentives.labels.incentive_name': 'Nom:',
      'i18n.incentives.labels.typical_amount': 'Valeur potentielle:',
      'i18n.incentives.labels.grantor_name': 'Concédant:',
      'i18n.incentives.labels.Grant': 'Description:',
    },
  },
};
