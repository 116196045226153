import isEmpty from 'lodash.isempty';
import React, { useMemo } from 'react';
import { Design, DesignKey } from '../../../contexts';
import { useExtendedConfig } from '../../../hooks/useExtendedConfig';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

const defaultConfigs: { [key: string]: unknown } = {};

interface ConfigOptions {
  defaultCommercialConfig?: Design;
  defaultConsumerConfig?: Design;
}
type ConfigType = Design | ConfigOptions;

export function selectConfig(key: DesignKey, defaultConfig: Design, config?: Design) {
  if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_LOG_DEFAULT_CONFIGS) {
    defaultConfigs[key] = defaultConfig;
  }

  return isEmpty(config) ? defaultConfig : config;
}

export function WithConfig<P>(
  Component: React.JSXElementConstructor<P>,
  key: DesignKey,
  defaultConfig: ConfigType
) {
  return function RenderWithConfig(
    props: Omit<P, '_extends' | 'components' | 'config' | 'schema'>
  ) {
    const state = useSelector((state: any) => state.userSlice);
    const { config, isLoading } = useExtendedConfig(key, state);
    const location = useLocation();

    const pageConfig = useMemo(() => {
      if ('defaultConsumerConfig' in defaultConfig || 'defaultCommercialConfig' in defaultConfig) {
        // @ts-ignore
        return location.pathname.includes('/consumer')
          ? defaultConfig.defaultConsumerConfig
          : defaultConfig.defaultCommercialConfig;
      } else {
        return defaultConfig;
      }
    }, [location.pathname, defaultConfig]);

    return isLoading ? null : (
      // @ts-ignore
      <Component {...({ ...props, ...selectConfig(key, pageConfig, config) } as P)} />
    );
  };
}
