import styled, { css } from 'styled-components';
import { resolveStyles } from '../../../utils/styled/resolveStyles';
import { WarningOwnProps } from './Warning';
import { StyledIcon } from '../Icon/styled';
import { StyledTypography } from '../TypeSetting/styled';

const fallback = css<WarningOwnProps>`
  padding: 0.5rem;
  border-radius: 4px;

  ${StyledIcon} {
    margin-right: 0.25rem;
  }

  ${StyledTypography} {
    font-size: 0.875rem;
    font-weight: bold;
  }

  ${({ kind }) =>
    kind === 'error' &&
    css`
      background-color: #feeceb;
    `}
`;

export const StyledWarning = styled.aside<WarningOwnProps>`
  ${resolveStyles({ fallback })}
`;
