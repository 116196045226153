import styled from 'styled-components';
import MaskedInput from 'react-text-mask';
import { resolveStyles } from '../../../utils/styled/resolveStyles';
import { fallback } from '../../atoms/Input/styled';

export const StyledMaskedInput = styled(MaskedInput)`
  ${resolveStyles({ fallback })}
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  width: 120px;
  font-size: 14px;
`;
