import styled, { css } from 'styled-components';
import { StyledTypography } from '../TypeSetting/styled';
import { resolveStyles } from '../../../utils/styled/resolveStyles';

const fallback = css`
  ${StyledTypography} {
    font-size: 1.1rem;
    color: #777777;
    margin-top: 1.125rem;
  }
`;

export const Styled = styled.div`
  ${resolveStyles({ fallback })}
`;
