import get from 'lodash.get';
import { useQuery } from 'react-query';
import React, { useState, useLayoutEffect, createContext, PropsWithChildren } from 'react';
import { PropsWithQueryState } from '../../types';
import { isConsumerConfig, resolveConfig } from '../../utils/config/index';
import { useLocation } from 'react-router-dom';

export type GenericConfig = Record<string, unknown> & { [key: string]: unknown };
export type ContentEntry = [string, DesignKey];
export type DesignKey<T extends string = string> = T;
export type BaseDesignConfig<T extends GenericConfig = GenericConfig> = T;

export type DesignComponents = Record<string, DesignKey>;
export type ContentList = Array<[string, DesignKey]>;

export interface Design<C extends BaseDesignConfig = BaseDesignConfig> {
  _extends?: DesignKey;
  content?: ContentList;
  components?: DesignComponents;
  config?: C;
  schema?: unknown;
}

export interface DesignWithRequiredConfig<C extends BaseDesignConfig = BaseDesignConfig>
  extends Design<C> {
  config: C;
}

export type Designs = Record<DesignKey, Design>;

export interface Config {
  product: string;
  getConfigs: () => Promise<Designs>;
}

export type GetConfigFn = <
  C extends BaseDesignConfig = BaseDesignConfig,
  K extends DesignKey = DesignKey
>(
  path: K
) => Design<C> | undefined;

export interface ConfigContextProps {
  configs: Designs | undefined;
}

export const ConfigContext = createContext<PropsWithQueryState<ConfigContextProps>>({
  status: 'idle',
  isIdle: true,
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: null,
  configs: undefined,
});

export const getConfig: (configs?: Designs) => GetConfigFn = (configs) => (key) => {
  if (!key || !configs) {
    return undefined;
  }

  return get(configs, key, undefined) as Designs;
};

export const ConfigProvider: React.FC<PropsWithChildren<Config>> = ({ children, getConfigs }) => {
  const location = useLocation();
  const isConsumer = isConsumerConfig();
  const [configs, setConfigs] = useState<Designs>();
  const { status, isIdle, isLoading, isError, isSuccess, error, data } = useQuery<Designs>(
    ['getConfigs', location.pathname],
    () => Promise.resolve(resolveConfig() as unknown as Designs),
    { enabled: true }
  );

  useLayoutEffect(() => {
    if (data) {
      setConfigs(data);
    }
  }, [getConfigs, data, isConsumer]);

  return (
    <ConfigContext.Provider
      value={{
        status,
        isIdle,
        isLoading,
        isError,
        isSuccess,
        error,
        configs,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};
