import styled, { css } from 'styled-components';
import { resolveStyles } from '../../../utils/styled/resolveStyles';
import { SpacerProps } from './Spacer';

const fallback = css<SpacerProps>`
  ${({ p, px, py, pt, pb, pl, pr, m, mx, my, mt, mb, ml, mr }) => css`
    ${p &&
    css`
      padding: ${p / 16}rem;
    `}
    ${px &&
    css`
      padding-left: ${px / 16}rem;
      padding-right: ${px / 16}rem;
    `}
  ${py &&
    css`
      padding-top: ${py / 16}rem;
      padding-bottom: ${py / 16}rem;
    `}
  ${pt &&
    css`
      padding-top: ${pt / 16}rem;
    `}
  ${pb &&
    css`
      padding-bottom: ${pb / 16}rem;
    `}
  ${pl &&
    css`
      padding-left: ${pl / 16}rem;
    `}
  ${pr &&
    css`
      padding-right: ${pr / 16}rem;
    `}

 ${m &&
    css`
      margin: ${m / 16}rem;
    `}
  ${mx &&
    css`
      margin-left: ${mx / 16}rem;
      margin-right: ${mx / 16}rem;
    `}
  ${my &&
    css`
      margin-tom: ${my / 16}rem;
      margin-bottom: ${my / 16}rem;
    `}
  ${mt &&
    css`
      margin-tom: ${mt / 16}rem;
    `}
  ${mb &&
    css`
      margin-bottom: ${mb / 16}rem;
    `}
  ${ml &&
    css`
      margin-left: ${ml / 16}rem;
    `}
  ${mr &&
    css`
      margin-right: ${mr / 16}rem;
    `}
  `}
`;

export const Styled = styled.div<SpacerProps>`
  ${resolveStyles({ fallback })}
`;
