import { Routes, Route } from 'react-router-dom';
import { store } from './store';
import { Provider } from 'react-redux';

import Root from './components/pages/Root/Root';
import { Incentives } from './components/pages/Incentives';
import { Incentive } from './components/pages/Incentive';
import { Layout } from './components/templates/Layout';
import { useAuthOauth2GrantsConfig } from './components/AwsAmplifyHelper/AuthOauth2Grants';
import { federatedOauth } from './constants';

import './App.css';

function App() {
  useAuthOauth2GrantsConfig(federatedOauth);

  return (
    <Provider store={store}>
      <Routes>
        <Route path="/" element={<Root />} />
        <Route path="/" element={<Layout />}>
          <Route path="incentives/:country/:type" element={<Incentives />} />
          <Route path="incentives/:country/:type/:id" element={<Incentive />} />
        </Route>
      </Routes>
    </Provider>
  );
}

export default App;
