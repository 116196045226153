import React from 'react';

//assets
import LogoGm from '../../../assets/GM_Gradient_Brandmark.png';

//styles
import { Container, Wrapper, CompanyLogo, RightWrapper } from './styled';

//components
import { LanguageSelect } from '../../organisms/LanguageSelect';
import { ModalButton } from '../../atoms/ModalButton';

export const Header: React.FC = () => {
  return (
    <Container>
      <Wrapper>
        <CompanyLogo src={LogoGm} alt="Gm logo" />
        <RightWrapper>
          <LanguageSelect />
          <ModalButton />
        </RightWrapper>
      </Wrapper>
    </Container>
  );
};

Header.displayName = 'Header';
