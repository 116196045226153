import React, { ChangeEvent, useEffect, useState } from 'react';
import { InputProps } from '../../atoms/Input';
import { useCopy } from '../../../hooks/useCopy';
import { StyledMaskedInput } from './styled';
import { useURLQuery } from '../../../hooks';
import { useParams } from 'react-router-dom';

export type PostcodeInputProps = InputProps;

/* ***** */
export const INITIAL_MASK = [
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
];
/* 11111 */
export const US_ZIPCODE_MASK = [/\d/, /\d/, /\d/, /\d/, /\d/];

/* ***** */
export const US_STATE_MASK = [
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
  /[a-zA-Z0-9]/,
];

/* A1A 1A1 */
export const CANADA_POSTALCODE_MASK_SPACED = [
  /[a-zA-Z]/,
  /\d/,
  /[a-zA-Z]/,
  /[\s]/,
  /\d/,
  /[a-zA-Z]/,
  /\d/,
];

export const CANADA_PROVINCE_MASK = [/[A-Za-z]/, /[A-Za-z]/];

export type RenderedInputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

export const sanitizeInputValue = (conformedValue: string) => {
  return /[a-zA-Z]/.test(conformedValue) ? conformedValue.toUpperCase() : conformedValue;
};

export const PostcodeInput: React.FC<PostcodeInputProps> = ({
  placeholder: { i18n },
  defaultValue,
  ...props
}) => {
  const placeholder = useCopy(i18n);
  const [value, setValue] = useState<any>(defaultValue);
  const [mask, setMask] = useState(INITIAL_MASK);
  const [lastParams, setLastParams] = useState<string>('');
  const { country } = useParams();
  const { paramsRecord } = useURLQuery();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    setValue(value);
  };

  useEffect(() => {
    if (lastParams === paramsRecord.postcode) return;

    setLastParams(paramsRecord.postcode);

    if (country === 'CA') {
      if (paramsRecord?.postcode?.length === 2) {
        if (
          /[a-zA-Z]/.test(paramsRecord.postcode[0]) &&
          /[a-zA-Z]/.test(paramsRecord.postcode[1])
        ) {
          setMask(CANADA_PROVINCE_MASK);
          setValue(paramsRecord.postcode);
        }
      } else {
        setMask(CANADA_POSTALCODE_MASK_SPACED);
        setValue(paramsRecord.postcode);
      }
    } else if (country === 'US') {
      if (paramsRecord.postcode) {
        if (paramsRecord?.postcode?.length === 2) {
          if (
            /[a-zA-Z]/.test(paramsRecord.postcode[0]) &&
            /[a-zA-Z]/.test(paramsRecord.postcode[1])
          ) {
            setMask(US_STATE_MASK);
            setValue(paramsRecord.postcode);
          }
        } else {
          if (!value) {
            return setMask(US_ZIPCODE_MASK);
          }
          setMask(US_ZIPCODE_MASK);
          setValue(paramsRecord.postcode);
        }
      }
    }
  }, [paramsRecord, value, lastParams]);

  return (
    <StyledMaskedInput
      mask={mask}
      value={value}
      guide={false}
      {...props}
      placeholder={placeholder}
      onChange={handleChange}
      pipe={sanitizeInputValue}
    />
  );
};

PostcodeInput.displayName = 'PostcodeInput';
