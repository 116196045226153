import React, { useMemo, useState, useEffect } from 'react';
import { Paper, Typography, Grid, Button } from '@material-ui/core';

import { useDispatch, useSelector } from 'react-redux';

import GM_homepage from '../assets/GM_homepage.png';
import zaLogo from '../assets/powered-by-jdp-logo.svg';
import GM_Blue_Horizontal_RGB from '../assets/GM_Blue_Horizontal.svg';

import { useFederatedUserAuthUrl } from '../components/AwsAmplifyHelper/FederatedUser';
import { LanguageSelect } from '../components/organisms/LanguageSelect';
import { SubTitle } from '../components/atoms/SubTitle';
import { RadioInput } from '../components/atoms/RadioInput';
import { TypeSetting } from '../components/atoms/TypeSetting';

import { updaters } from '../components/AwsAmplifyHelper/userAuthenticationSlice';
import { useNavigate } from 'react-router-dom';
import { useURLQuery } from '../hooks';

const GM_Home = () => new _GM_Home();

export class _GM_Home {
  constructor() {
    this.inputs = {
      homepageImage: '',
      title: '',
      buttonText: '',
    };

    //dynamically generating setters for inputs in constructor
    Object.keys(this.inputs).forEach((input) => {
      // if booleanValue, it will have the property name became has
      let functionNameValue =
        typeof this.inputs[input] == 'boolean'
          ? `is${input.charAt(0).toUpperCase() + input.slice(1)}`
          : `with${input.charAt(0).toUpperCase() + input.slice(1)}`;

      this[`${functionNameValue}`] = (value) => {
        this.inputs[`${input}`] = value;
        return this;
      };
    });
  }

  build() {
    return {
      sections: [
        {
          uiComponent: Grid,
          props: {
            id: 'GmHomeImage',
            className: 'GmHomeImage',
          },
          elements: [
            {
              gridItemProps: {
                item: true,
                xs: 12,
              },
              uiComponent: Typography,
              props: {
                className: 'zappyride_logo',
                children: [<img src={zaLogo} alt="ZappyRide" id="logo" />],
              },
            },
            {
              gridItemProps: {
                item: true,
                xs: 12,
              },
              uiComponent: LoginUI,
              props: {
                className: 'LoginContainer',
                title: this.inputs.title,
                buttonText: this.inputs.buttonText,
              },
            },
            {
              uiComponent: React.Fragment,
              props: {
                className: 'GmHomeImage',
                children: (
                  <img
                    src={GM_homepage}
                    alt="two cars parked on desert sand"
                    className="gm-hero-image"
                  />
                ),
              },
            },
          ],
        },
      ],
    };
  }
}

export default GM_Home;

const AgreementUI = () => {
  const [accepted, setAccepted] = useState(false);
  const authUrl = useFederatedUserAuthUrl();
  const isDev = window.location.hostname === 'localhost';
  const dispatch = useDispatch();

  const handleClick = () => {
    if (!isDev) {
      window.open(authUrl, '_self');
    } else {
      dispatch(updaters.userStep(2));
    }
  };

  return (
    <Paper className="LoginContainer">
      <div className="languageSelectorCotnainer">
        <LanguageSelect />
      </div>
      <img src={GM_Blue_Horizontal_RGB} alt="GM fleets logo" className="GMLogo" />
      <div className="textContainer">
        <SubTitle
          i18n={{
            id: 'i18n.pages.splashscreen.subtitle',
            fallback:
              'By clicking “I Agree” and proceeding to the site, GM Dealer understands and agrees that the content provided is for informational and reference purposes only. Dealer acknowledges it will not utilize this content for any advertising or marketing content, and Dealer will conduct its own diligence related to potentially available incentives. For GM Dealer personnel use only. Dealer acknowledges that GM is not responsible for the administration of any incentive program, any required participation costs, or any restrictions, cancellations, or changes that are made to any potential incentives, and that GM makes no representation, warranty or guarantee with respect to: i) the availability of any potential incentives identified; ii) the actual amount of, or eligibility requirements for, any potential incentives; or iii) the completeness of any lists of potential incentives. Dealer further acknowledges and agrees that any use of the information contained on or elicited through this site in violation of the above may result in the suspension or termination of Dealer’s access, as determined by GM in its sole discretion.',
          }}
        />
      </div>
      <div className="inputContainer">
        <input type="checkbox" value={accepted} onChange={() => setAccepted((s) => !s)} />
        <TypeSetting
          element="label"
          i18n={{
            id: 'i18n.pages.splashscreen.agree',
            fallback: 'I Agree',
          }}
        />
      </div>
      <Button disabled={!accepted} id="LoginButton" onClick={handleClick}>
        <TypeSetting
          element="label"
          i18n={{
            id: 'i18n.pages.splashscreen.btn',
            fallback: 'Proceed',
          }}
        />
      </Button>
    </Paper>
  );
};

const WizardOnboarding = () => {
  const [country, setCountry] = useState('US');
  const [type, setType] = useState('consumer');
  const history = useNavigate();
  const dispatch = useDispatch();
  const { setParam } = useURLQuery();

  useEffect(() => {
    dispatch(updaters.userCountry(country));
    dispatch(updaters.userType(type));
  }, [country, type]);

  const handleClick = () => {
    if (country === 'CA') {
      setParam({ name: 'postcode', value: 'M5V 1J1' });
    } else {
      setParam({ name: 'postcode', value: '95026' });
    }
    history(`/incentives/${country}/${type}`);
  };

  return (
    <Paper className="LoginContainer">
      <div className="languageSelectorCotnainer">
        <LanguageSelect />
      </div>
      <img src={GM_Blue_Horizontal_RGB} alt="GM fleets logo" className="GMLogo" />
      <div className="logoTextContainer">
        <h1 className="title">
          <TypeSetting
            element="label"
            i18n={{
              id: 'i18n.pages.splashscreen.dealer',
              fallback: 'GM Dealer Dashboard',
            }}
          />
        </h1>
        <p className="description">
          <TypeSetting
            element="label"
            i18n={{
              id: 'i18n.pages.splashscreen.description',
              fallback: 'How would you like to view incentives? First choose your region.',
            }}
          />
        </p>
      </div>
      <div className="textContainerWizard">
        <div className="row">
          <RadioInput
            onClick={() => setCountry('US')}
            isSelected={country === 'US'}
            i18n={{ id: 'i18n.pages.splashscreen.us', fallback: 'USA' }}
          />
          <RadioInput
            onClick={() => setCountry('CA')}
            isSelected={country === 'CA'}
            i18n={{ id: 'i18n.pages.splashscreen.ca', fallback: 'Canada' }}
          />
        </div>
        <p className="description middle-row">
          <TypeSetting
            element="label"
            i18n={{
              id: 'i18n.pages.splashscreen.descriptionTwo',
              fallback: 'Next, choose the type of incentives you would like to view.',
            }}
          />
        </p>
        <div className="row">
          <RadioInput
            onClick={() => setType('consumer')}
            isSelected={type === 'consumer'}
            i18n={{ id: 'i18n.pages.splashscreen.consumer', fallback: 'Consumer' }}
            type={'consumer'}
          />
          <RadioInput
            onClick={() => setType('commercial')}
            isSelected={type === 'commercial'}
            i18n={{ id: 'i18n.pages.splashscreen.commercial', fallback: 'Commercial' }}
            type={'commercial'}
          />
        </div>
      </div>
      <Button id="LoginButton" onClick={handleClick}>
        <TypeSetting
          element="label"
          i18n={{
            id: 'i18n.pages.splashscreen.modal.update',
            fallback: 'Proceed',
          }}
        />
      </Button>
    </Paper>
  );
};

const LoginUI = () => {
  const state = useSelector((state) => state.userSlice);

  const renderUI = useMemo(() => {
    switch (state.userStep) {
      case 1:
        return <AgreementUI />;
      case 2:
        return <WizardOnboarding />;
      default:
        return null;
    }
  }, [state.userStep]);

  return <>{renderUI}</>;
};
