import React from 'react';
import { IncentiveDetailOverviewTab } from '../IncentiveDetailOverviewTab';
import { IncentiveDetailEligibilityTab } from '../IncentiveDetailEligibilityTab';
import { IncentiveDetailFundingTab } from '../IncentiveDetailFundingTab';
import { IncentiveDetailChangelogTab } from '../IncentiveDetailChangelogTab';
import { IncentiveDetails } from '../../../types';

export type IncentiveDetailTabKind = 'overview' | 'eligibility' | 'funding' | 'changelog';
export type IncentiveDetailTabProps = {
  kind: IncentiveDetailTabKind;
  data: IncentiveDetails;
};

export const IncentiveDetailTab: React.FC<IncentiveDetailTabProps> = ({ kind, data }) => {
  switch (kind) {
    case 'overview':
      return <IncentiveDetailOverviewTab data={data} />;
    case 'eligibility':
      return <IncentiveDetailEligibilityTab data={data} />;
    case 'funding':
      return <IncentiveDetailFundingTab data={data} />;
    case 'changelog':
      return <IncentiveDetailChangelogTab data={data} />;
    default:
      throw new Error(
        `The kind ${kind} has no mapped component to render for an Incentive details page`
      );
  }
};

IncentiveDetailTab.displayName = 'IncentiveDetailTab';
