import { useState, useEffect } from 'react';
import isString from 'lodash.isstring';

export function useLocalStorageState(key: string, defaultValue: unknown) {
  const [state, setState] = useState(() => {
    try {
      return isString(localStorage.getItem(key))
        ? JSON.parse(localStorage.getItem(key) as string)
        : defaultValue;
    } catch {
      return defaultValue;
    }
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}
