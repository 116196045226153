import React from 'react';
import { TypeSetting, TypeSettingProps } from '../TypeSetting';
import { StyledTitleContainer } from './styled';

export type TabContentTitleProps = TypeSettingProps;

export const TabContentTitle: React.FC<TabContentTitleProps> = ({ i18n, ...props }) => {
  return (
    <StyledTitleContainer>
      <TypeSetting i18n={i18n} {...props} scale="3" fontWeight="500" />
    </StyledTitleContainer>
  );
};

TabContentTitle.displayName = 'TabContentTitle';
