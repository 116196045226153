import React, { useState } from 'react';
import { Wrapper, Row, Column, RowWrap } from './styled';
import { IncentiveDetails } from '../../../types';
import { TypeSetting } from '../../atoms/TypeSetting';
import OpenIcon from '../../../assets/icons/open_in_new.svg';
import { LinkButton } from '../../atoms/LinkButton';
import ExitModal from '../ExitModal';

interface ConsumerDetailType {
  id: string;
  incentive: IncentiveDetails;
}

export const ConsumerDetail: React.FC<ConsumerDetailType> = ({ id, incentive }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const link = incentive?.details_url;

  const handleLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();
    setModalOpen(true);
  };

  const handleConfirmNavigation = () => {
    setModalOpen(false);
    window.open(link, '_blank', 'noopener,noreferrer');
  };

  const handleCancelNavigation = () => {
    setModalOpen(false);
  };

  return (
    <div>
      {id === 'overview' ? (
        <Wrapper>
          <Row>
            <Column gap="13px">
              <TypeSetting
                i18n={{
                  id: 'i18n.consumer.detail.name',
                  fallback: 'Incentive name',
                }}
              />
              <h3>{incentive.name}</h3>
            </Column>
            <Column gap="13px">
              <TypeSetting
                i18n={{
                  id: 'i18n.consumer.detail.type',
                  fallback: 'Purchase type',
                }}
              />
              <h3>{incentive.type}</h3>
            </Column>
          </Row>

          <Row>
            <Column gap="21px">
              <TypeSetting
                i18n={{
                  id: 'i18n.consumer.detail.description',
                  fallback: 'Incentive description',
                }}
              />
              <p>{incentive.description}</p>
            </Column>
          </Row>

          <Row>
            <LinkButton
              href={link}
              onClick={handleLinkClick}
              target="_blank"
              rel="noopener noreferrer"
              i18n={{ id: 'i18n.apply', fallback: 'Apply' }}
              icon={OpenIcon}
            />
          </Row>
        </Wrapper>
      ) : (
        <Wrapper>
          <RowWrap>
            <Column gap="11px">
              <TypeSetting
                i18n={{
                  id: 'i18n.consumer.detail.eligibility.type',
                  fallback: 'Eligible Vehicle Type',
                }}
              />
              <h3>
                <TypeSetting
                  i18n={{
                    id: 'i18n.consumer.detail.eligibility.onroad',
                    fallback: 'Onroad',
                  }}
                />
              </h3>
            </Column>
            <Column gap="11px">
              <TypeSetting
                i18n={{
                  id: 'i18n.consumer.detail.eligibility.segments',
                  fallback: 'Eligible Vehicle Segments',
                }}
              />
              <h3>
                <TypeSetting
                  i18n={{
                    id: 'i18n.consumer.detail.eligibility.passenger',
                    fallback: 'Passenger',
                  }}
                />
              </h3>
            </Column>
            <Column gap="11px">
              <TypeSetting
                i18n={{
                  id: 'i18n.consumer.detail.eligibility.fuel',
                  fallback: 'Eligible Vehicle Fuel Types',
                }}
              />
              <h3>
                Battery-Electric, Plug-in Hybrid
                {incentive?.applicable_to_fcev && ', Fuel-Cell Vehicles'}
              </h3>
            </Column>
            <Column gap="11px">
              <TypeSetting
                i18n={{
                  id: 'i18n.consumer.detail.eligibility.description',
                  fallback: 'Eligible Vehicle Description',
                }}
              />
              <h3>
                {incentive?.applicable_to_new_vehicles && (
                  <TypeSetting
                    i18n={{
                      id: 'i18n.consumer.detail.eligibility.new',
                      fallback: 'New',
                    }}
                  />
                )}
                {incentive?.applicable_to_used_vehicles &&
                  incentive?.applicable_to_new_vehicles &&
                  ', '}
                {incentive?.applicable_to_used_vehicles && (
                  <TypeSetting
                    i18n={{
                      id: 'i18n.consumer.detail.eligibility.used',
                      fallback: 'Used',
                    }}
                  />
                )}
              </h3>
            </Column>
          </RowWrap>
        </Wrapper>
      )}
      <ExitModal
        isOpen={modalOpen}
        onConfirm={handleConfirmNavigation}
        onCancel={handleCancelNavigation}
      />
    </div>
  );
};

ConsumerDetail.displayName = 'ConsumerDetail';
