import styled from 'styled-components';

export const Container = styled.div`
  margin-left: 32px;

  @media (max-width: 768px) {
    margin-left: 0px;
  }
`;

export const ButtonContainer = styled.div`
  width: 265px;
  height: 48px;
  padding: 12px, 40px, 12px, 40px;
  border: 1px solid #dbdbdb;
  gap: 8px;
  color: #007dbe;
  background: #ffff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media (max-width: 768px) {
    width: 200px;
  }
`;

export const Icon = styled.img`
  width: 18px;
  height: 18px;
  margin-right: 8px;
  top: 0 !important;
`;

export const Typography = styled.p`
  font-family: Overpass;
  font-size: 14px;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;

  @media (max-width: 768px) {
    font-size: 12px;
  }
`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;

  .LoginContainer {
    height: 644px !important;
  }

  .title {
    margin-top: 40px;
  }

  .textContainerWizard {
    margin-top: 12px !important;
  }
`;

export const ModalContent = styled.div`
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  width: 300px;
  max-height: 80vh;
  overflow-y: auto;
  z-index: 10000;
`;

export const Button = styled.button`
  width: 374px;
  height: 56px;
  padding: 16px, 10px, 16px, 10px;
  gap: 8px;
  background: #007dbe;
  color: #fff;
  bottom: 24px;
  position: absolute;

  @media (max-width: 768px) {
    position: relative;
    bottom: 0;
    padding: 20px;
    width: 100%;
  }
`;

export const CloseIcon = styled.div`
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
`;
