import styled, { css } from 'styled-components';
import { resolveStyles } from '../../../utils/styled/resolveStyles';
import { StyledTypography } from '../../atoms/TypeSetting/styled';
import { StyledIcon } from '../../atoms/Icon/styled';
import { Table } from '../Table';
import { StyledTableContainerProps } from '../Table/Table.types';

const fallback = css<StyledTableContainerProps>`
  td label {
    padding-right: 5px;
    font-weight: bold;
    display: none;
  }

  td[data-key='open'] {
    width: 6%;
  }

  td[data-key='incentive_name'] {
    width: 24%;
  }

  td[data-key='typical_amount'] {
    width: 12%;
  }

  td[data-key='is_eligible_to_specified_form_factors_only'] {
    width: 18%;
  }

  td[data-key='grantor_name'] {
    width: 10%;
  }

  td[data-key='grant'] {
    width: 10%;
  }

  td[data-key='more-details'] {
    width: 20%;
  }

  @media screen and (max-width: 1000px) {
    td[data-key='is_eligible_to_specified_form_factors_only'] span {
      flex-wrap: wrap;
      justify-content: center;
      gap: 30px;
    }
  }

  @media screen and (max-width: 767px) {
    td[data-key='typical_amount'] {
      grid-area: 1/2;
      text-align: right;
    }

    td[data-key='incentive_name'] {
      grid-area: 2/1;
      grid-column: 1 / span 2;
    }

    td[data-key='more-details'] {
      grid-row-start: ${({ cols }) => cols};
      grid-column-start: 2;
    }

    td[data-key='open'],
    td[data-key='incentive_name'],
    td[data-key='typical_amount'] {
      width: auto;
    }

    @media (max-width: 991px) {
      td {
        margin-bottom: 15px;
      }

      td label {
        display: block;
      }

      td[data-key='is_eligible_to_specified_form_factors_only'] span {
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 10px;
      }

      td[data-key='open'],
      td[data-key='incentive_name'],
      td[data-key='typical_amount'] {
        width: 100%;
        text-align: left;
      }

      td[data-key='is_eligible_to_specified_form_factors_only'] {
        width: 100%;
      }

      td[data-key='grantor_name'] {
        width: 100%;
      }

      td[data-key='grant'] {
        width: 100%;
      }

      td[data-key='more-details'] {
        width: 100%;
      }
    }
  }
`;

export const StyledTable = styled(Table)<StyledTableContainerProps>`
  ${resolveStyles({ fallback })}
`;

export const StyledLoadingTd = styled.td`
  text-align: center;
`;

const navSectionDefaultStyles = css`
  ${StyledTypography} {
    font-size: 0.875rem;
    font-weight: normal;
  }
`;

const styledNavButtonFallback = css`
  ${navSectionDefaultStyles}

  display: inline-flex;
  align-items: center;

  span + ${StyledIcon}, ${StyledIcon} + span {
    margin-left: 0.8rem;
  }

  opacity: 0.25;
`;

export const StyledNavButton = styled.button`
  ${resolveStyles({ fallback: styledNavButtonFallback })}
  opacity: 1;
`;

const styledNavLocationFallback = css`
  ${navSectionDefaultStyles}

  margin: 0 0.75rem;
  word-spacing: 0.4em;

  @media (max-width: 767px) {
    word-spacing: 0.2em;
  }
`;

export const StyledNavLocation = styled.span`
  ${resolveStyles({ fallback: styledNavLocationFallback })}

  white-space: nowrap;
`;

const resultsCountFallback = css`
  ${navSectionDefaultStyles}

  text-align: left;
`;

export const StyledResultsCount = styled.th`
  ${resolveStyles({ fallback: resultsCountFallback })}
`;

const navColFallback = css`
  text-align: right;
`;

export const StyledNavCol = styled.td`
  ${resolveStyles({ fallback: navColFallback })}
`;

const navColContainerFallback = css`
  display: inline-flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 768px) {
    justify-content: flex-end;
  }
`;

export const StyledNavColContainer = styled.span`
  ${resolveStyles({ fallback: navColContainerFallback })}
`;
