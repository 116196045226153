import React from 'react';
import { StyledSpinner } from './styled';
import defaultSpinner from '../../../assets/loaders/spinner.png';

export type SpinnerOwnProps = {
  src?: string;
  height?: number;
  width?: number;
};
export type SpinnerProps = SpinnerOwnProps;

export const Spinner: React.FC<SpinnerProps> = ({
  src = defaultSpinner,
  height = 100,
  width = 100,
}) => {
  return <StyledSpinner src={src} height={height} width={width} aria-hidden />;
};

Spinner.displayName = 'Spinner';
