import styled, { css } from 'styled-components';
import { resolveStyles } from '../../../utils/styled/resolveStyles';

const fallback = css`
  padding: 0 2rem;
  background-color: #fff;
  border: 1px solid #dfe0eb;
`;

export const StyledBox = styled.div`
  ${resolveStyles({ fallback })}

  @media(max-width:768px) {
    width: 100%;
  }
`;

export const headerFallback = css`
  border-bottom: 1px solid #dfe0eb;
  padding: 1.125rem 0 0.385rem 0;
`;

export const StyledBoxHeader = styled.div`
  ${resolveStyles({ fallback: headerFallback })}
`;

const bodyFallback = css`
  padding: 1rem 0 1.5rem 0;
`;

export const StyledBoxBody = styled.div`
  ${resolveStyles({ fallback: bodyFallback })}
`;

StyledBoxBody.displayName = 'BoxBody';
