import React from 'react';
import { TypeSetting } from '../../atoms/TypeSetting';
import { Typography } from '../../atoms/Typography';
import { IncentiveDetailTabProps } from '../IncentiveDetailTab';
import { Table } from '../Table';
import { Row, Title, Column } from './styled';
import { useTranslations } from '../../../contexts/Translations';

export type IncentiveDetailEligibilityTabProps = Pick<IncentiveDetailTabProps, 'data'>;

export const IncentiveDetailEligibilityTab: React.FC<IncentiveDetailEligibilityTabProps> = ({
  data,
}) => {
  const { language } = useTranslations();
  const getProjectTypes = (arr: string[]) => {
    let result = '';
    if (!arr) {
      return result;
    }
    arr.forEach((item, index) => {
      if (index) {
        result = result + ', ' + item;
      } else {
        result = item;
      }
    });

    return result;
  };

  const getAssumptions = (str: string): JSX.Element[] => {
    const result: JSX.Element[] = [];
    const arr = str ? str.split('* ') : [];

    const markdownLinkRegex = /\[([^\]]+)\]\(([^)]+)\)/;

    arr.forEach((text) => {
      if (text.length) {
        const match = text.match(markdownLinkRegex);
        if (match && match.index !== undefined) {
          const linkText = match[1];
          const linkUrl = match[2];
          const textBeforeLink = text.substring(0, match.index);
          const textAfterLink = text.substring(match.index + match[0].length);
          result.push(
            <li key={text}>
              {textBeforeLink}
              <a href={linkUrl} target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>
                {linkText}
              </a>
              {textAfterLink}
            </li>
          );
        } else {
          result.push(<li key={text}>{text}</li>);
        }
      }
    });

    return result;
  };

  return (
    <>
      <Table>
        <Row borderBottom margin="22px 0 0">
          <Title>
            <TypeSetting
              i18n={{ id: 'i18n.funding.title', fallback: 'PROJECT TYPE ELIGIBILITY' }}
              scale="3"
              fontWeight="600"
            />
          </Title>
        </Row>

        <Row margin="48px 0 0" mobileMargin="16px 0 0">
          <Column width="35%" margin="0 38px 0 0">
            <TypeSetting
              i18n={{ id: 'i18n.eligibility.projecttype', fallback: 'Eligible Project Type' }}
              scale="4"
              fontWeight="600"
              element="h5"
            />
            <Typography element="p" scale="4" fontWeight="400">
              {getProjectTypes(
                language === 'en'
                  ? data.eligible_project_types
                  : data.translated_eligible_project_types
              ) || (
                <TypeSetting
                  i18n={{
                    id: 'i18n.common.na',
                    fallback: 'N/A',
                  }}
                />
              )}
            </Typography>
          </Column>

          <Column width="60%" margin="0 0 0 auto" mobileMargin="16px 0 0">
            <TypeSetting
              i18n={{
                id: 'i18n.eligibility.projectdescription',
                fallback: 'Eligible Project Description',
              }}
              scale="4"
              fontWeight="600"
              element="h5"
            />
            <Typography element="p" scale="4" fontWeight="400">
              {data.eligible_project_type_description || (
                <TypeSetting
                  i18n={{
                    id: 'i18n.common.na',
                    fallback: 'N/A',
                  }}
                />
              )}
            </Typography>
          </Column>
        </Row>
      </Table>

      <Row margin="30px 0" mobileMargin="16px 0 0">
        <Table>
          <Row borderBottom margin="22px 0 0">
            <Title>
              <TypeSetting
                i18n={{ id: 'i18n.funding.titleDetails', fallback: 'Other Eligibility Details' }}
                scale="3"
                fontWeight="600"
              />
            </Title>
          </Row>

          <Row margin="48px 0 0" mobileMargin="16px 0 0">
            <Column width="35%" margin="0 38px 0 0">
              <Column margin="0 0 0">
                <TypeSetting
                  i18n={{
                    id: 'i18n.eligibility.organizations',
                    fallback: 'Eligible Organizations',
                  }}
                  scale="4"
                  fontWeight="600"
                  element="h5"
                />
                <Typography element="p" scale="4" fontWeight="400">
                  {getProjectTypes(
                    language === 'en'
                      ? data.eligible_organizations
                      : data.translated_eligible_organizations
                  ) || (
                    <TypeSetting
                      i18n={{
                        id: 'i18n.common.na',
                        fallback: 'N/A',
                      }}
                    />
                  )}
                </Typography>
              </Column>

              <Column margin="40px 0 0" mobileMargin="16px 0 0">
                <TypeSetting
                  i18n={{
                    id: 'i18n.eligibility.lease',
                    fallback: 'Lease Eligible',
                  }}
                  scale="4"
                  fontWeight="600"
                  element="h5"
                />
                <Typography element="p" scale="4" fontWeight="400">
                  {data.is_lease_eligible ? (
                    <TypeSetting
                      i18n={{
                        id: 'i18n.common.yes',
                        fallback: 'Yes',
                      }}
                    />
                  ) : (
                    <TypeSetting
                      i18n={{
                        id: 'i18n.common.no',
                        fallback: 'No',
                      }}
                    />
                  )}
                </Typography>
              </Column>

              <Column margin="40px 0 0" mobileMargin="16px 0 0">
                <TypeSetting
                  i18n={{
                    id: 'i18n.eligibility.beneficiary',
                    fallback: 'Lease Beneficiary',
                  }}
                  scale="4"
                  fontWeight="600"
                  element="h5"
                />
                <Typography element="p" scale="4" fontWeight="400">
                  {data.lease_beneficiary.length ? (
                    data.lease_beneficiary.join(', ')
                  ) : (
                    <TypeSetting
                      i18n={{
                        id: 'i18n.common.na',
                        fallback: 'N/A',
                      }}
                    />
                  )}
                </Typography>
              </Column>
            </Column>

            <Column width="60%" margin="0 0 0 auto" mobileMargin="16px 0 0">
              <TypeSetting
                i18n={{
                  id: 'i18n.eligibility.assumptions',
                  fallback: 'Eligibility Assumptions',
                }}
                scale="4"
                fontWeight="600"
                element="h5"
              />
              <Typography element="p" scale="4" fontWeight="400">
                {getAssumptions(data.eligibility_assumptions)}
              </Typography>
            </Column>
          </Row>
        </Table>
      </Row>
    </>
  );
};

IncentiveDetailEligibilityTab.displayName = 'IncentiveDetailEligibilityTab';
