import React, { PropsWithChildren } from 'react';
import uniq from 'lodash.uniq';
import get from 'lodash.get';
import isEmpty from 'lodash.isempty';
import isString from 'lodash.isstring';
import {
  Styled,
  StyledTdContent,
  StyledColWithDate,
  StyledEquipmentIconImage,
  StyledIconsContainer,
} from './styled';
import {
  IncentiveData,
  IncentiveDataKeys,
} from '../../organisms/IncentivesResults/IncentivesResults.types';
import { WithConfig } from '../../HOCs/WithConfig';
import { TypeSetting, TypeSettingProps } from '../TypeSetting';
import { useExtendedConfig } from '../../../hooks/useExtendedConfig';
import { ColorMarkerConfig, ColorMarker } from '../ColorMarker';
import { DifferenceKind, differenceForOpenStatus } from '../../../utils/dates';
import {
  IncentiveCurrentProgramStatusDefaultMappingConfig,
  IncentiveEligibleEquipmentDefaultMappingConfig,
  IncentiveDescriptionDefaultMappingConfig,
  IncentiveCurrentProgramStatusConsumerMappingConfig,
  IncentiveEligibleEquipmentConsumerMappingConfig,
} from '../../pages/Incentives/Incentives.defaults';
import { Typography, TypographyProps } from '../Typography';
import { getImageSrc } from '../../../utils/images';
import { useParams } from 'react-router-dom';
import { Copy } from '../Copy';

export type IncentiveResultProps = {
  col: keyof typeof IncentiveDataKeys;
  data: IncentiveData;
  startDate?: string | null;
  expirationDate?: string | null;
};

function formatValues(number: number | undefined, data: any) {
  const amount = number?.toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const matchType = data.support_for === 'Charging station' ? 'charger' : 'vehicle';

  return ['Up to ', <br key="linebreak" />, amount, ' / ', matchType];
}

function extractValues(str: any, data: any) {
  const valueRegex = /\$\d+(,\d{3})*(\.\d+)?/g;

  const matches = str.match(valueRegex);
  const matchType = data.support_for === 'Charging station' ? 'charger' : 'vehicle';

  if (matches && matches.length) {
    return ['Up to ', <br key="linebreak" />, matches[0], ' / ', matchType];
  } else {
    return str;
  }
}

export const DefaultResultColumn: React.FC<
  PropsWithChildren<Pick<IncentiveResultProps, 'col'>>
> = ({ children, col }) => {
  return (
    <Styled data-key={col}>
      <label>
        <Copy
          i18n={{
            id: 'i18n.incentives.labels.' + col,
            fallback: 'Incentive Label:',
          }}
        />
      </label>
      <span>{children}</span>
    </Styled>
  );
};

export const ConsumerValueResultColumn: React.FC<Pick<IncentiveResultProps, 'col' | 'data'>> = ({
  data,
  col,
}) => {
  return (
    <Styled data-key={col}>
      <span>&nbsp;{data.typical_amount}</span>
    </Styled>
  );
};

export type CurrentProgramStatusMapKey = 'open' | 'hiatus' | 'waitlist' | 'closed';

export interface CurrentProgramStatusMapItem {
  withColorMarker:
    | 'designs.color-marker.open'
    | 'designs.color-marker.hiatus'
    | 'designs.color-marker.waitlist'
    | 'designs.color-marker.closed';
  key: CurrentProgramStatusMapKey;
  i18n: {
    id: string;
    fallback: string;
  };
}

export interface CurrentProgramStatusColumnConfig {
  config: {
    map: {
      current: CurrentProgramStatusMapItem;
      hiatus: CurrentProgramStatusMapItem;
      upcoming: CurrentProgramStatusMapItem;
      waitlist: CurrentProgramStatusMapItem;
      expired: CurrentProgramStatusMapItem;
      suspended: CurrentProgramStatusMapItem;
      'n/a': CurrentProgramStatusMapItem;
    };
  };
}

export const CurrentProgramStatusValueResults = {
  upcoming: 'upcoming' as const,
  current: 'current' as const,
  expired: 'expired' as const,
  suspended: 'suspended' as const,
  waitlist: 'waitlist' as const,
  hiatus: 'hiatus' as const,
  'n/a': 'n/a' as const,
} as const;

export type CountdownProps = {
  status: CurrentProgramStatusMapKey;
} & Pick<IncentiveResultProps, 'startDate' | 'expirationDate'>;

export const DefaultTypography: React.FC<TypographyProps> = ({ children, ...props }) => (
  <Typography {...props} scale="2">
    {children}
  </Typography>
);
export const DefaultTypeSetting: React.FC<TypeSettingProps> = ({ children, ...props }) => (
  <TypeSetting {...props} scale="2" />
);

export type CountdownDifferenceProps = {
  kind: DifferenceKind;
  count: number;
  components?: {
    typography?: typeof DefaultTypography;
    typeSetting?: typeof DefaultTypeSetting;
  };
};

export const CountdownDifference: React.FC<CountdownDifferenceProps> = ({
  kind,
  count,
  components: { typography, typeSetting } = {
    typography: DefaultTypography,
    typeSetting: DefaultTypeSetting,
  },
}) => {
  const TypographyComponent = typography as typeof Typography;
  const TypeSettingComponent = typeSetting as typeof TypeSetting;
  switch (kind) {
    case DifferenceKind.YEARS:
      if (count === 15) {
        return (
          <TypographyComponent fontWeight="400" fontSize="12px">
            <TypeSettingComponent
              i18n={{
                id: 'i18n.current_program_status.open.expiration_countdown.noExp',
                fallback: 'No exp date',
              }}
            />
          </TypographyComponent>
        );
      }

      return (
        <TypographyComponent fontWeight="400" fontSize="12px">
          {count + ' '}
          {count > 1 ? (
            <TypeSettingComponent
              i18n={{
                id: 'i18n.current_program_status.open.expiration_countdown.years.plural',
                fallback: "yr's left",
              }}
            />
          ) : (
            <TypeSettingComponent
              i18n={{
                id: 'i18n.current_program_status.open.expiration_countdown.years',
                fallback: 'yr left',
              }}
            />
          )}
        </TypographyComponent>
      );
    case DifferenceKind.MONTHS:
      return (
        <TypographyComponent fontWeight="400" fontSize="12px">
          {count + ' '}
          {count > 1 ? (
            <TypeSettingComponent
              i18n={{
                id: 'i18n.current_program_status.open.expiration_countdown.months.plural',
                fallback: "mo's left",
              }}
            />
          ) : (
            <TypeSettingComponent
              i18n={{
                id: 'i18n.current_program_status.open.expiration_countdown.months',
                fallback: 'mo left',
              }}
            />
          )}
        </TypographyComponent>
      );
    case DifferenceKind.WEEKS:
      return (
        <TypographyComponent fontWeight="400" fontSize="12px">
          {count + ' '}
          <TypeSettingComponent
            i18n={{
              id: 'i18n.current_program_status.open.expiration_countdown.weeks',
              fallback: 'w left',
            }}
          />
        </TypographyComponent>
      );
    case DifferenceKind.DAYS:
      return (
        <TypographyComponent fontWeight="400" fontSize="12px" color="#CC3232">
          {count + ' '}
          <TypeSettingComponent
            i18n={{
              id: 'i18n.current_program_status.open.expiration_countdown.days',
              fallback: 'd left',
            }}
          />
        </TypographyComponent>
      );
  }
};

CountdownDifference.displayName = 'CountdownDifference';

export const Countdown: React.FC<CountdownProps> = ({ status, startDate, expirationDate }) => {
  if (isEmpty(startDate) && isEmpty(expirationDate)) {
    return null;
  }

  switch (status) {
    case 'open':
      if (isEmpty(expirationDate) || !isString(expirationDate)) {
        return null;
      }

      return isString(expirationDate) ? (
        <CountdownDifference {...differenceForOpenStatus(new Date(expirationDate), new Date())} />
      ) : null;
    default:
      return null;
  }
};

Countdown.displayName = 'Countdown';

export const CurrentProgramStatusResultColumn: React.FC<
  {
    status: string;
  } & Pick<IncentiveResultProps, 'startDate' | 'expirationDate'> &
    CurrentProgramStatusColumnConfig
> = ({ status: originalStatus, startDate, expirationDate, config: { map } }) => {
  const status = originalStatus as keyof typeof CurrentProgramStatusValueResults;
  const { i18n, key, withColorMarker } = map[status];
  const { config: colorMarkerConfig, isLoading } =
    useExtendedConfig<ColorMarkerConfig>(withColorMarker);

  return isLoading ? null : (
    <Styled data-key={key}>
      <StyledTdContent>
        <ColorMarker color={colorMarkerConfig?.config?.color} />
        <StyledColWithDate>
          <TypeSetting i18n={i18n} />
          <Countdown status={key} startDate={startDate} expirationDate={expirationDate} />
        </StyledColWithDate>
      </StyledTdContent>
    </Styled>
  );
};

export const CurrentProgramStatusConsumerResultColumn: React.FC = ({
  col,
  data,
  config: { map },
}: any) => {
  const status = data[col] as keyof typeof CurrentProgramStatusValueResults;
  const { i18n, key, withColorMarker } = map[status ? 'open' : 'closed'];
  const { config: colorMarkerConfig } = useExtendedConfig<ColorMarkerConfig>(withColorMarker);

  return (
    <Styled data-key={key}>
      <StyledTdContent>
        <ColorMarker color={colorMarkerConfig?.config?.color} />
        <StyledColWithDate>
          <TypeSetting i18n={i18n} />
        </StyledColWithDate>
      </StyledTdContent>
    </Styled>
  );
};

export const CurrentProgramStatus = WithConfig(
  CurrentProgramStatusResultColumn,
  'designs.incentives.results.table.current_program_status',
  IncentiveCurrentProgramStatusDefaultMappingConfig
);

export const CurrentProgramStatusConsumer = WithConfig(
  CurrentProgramStatusConsumerResultColumn,
  'designs.incentives.results.table.available',
  IncentiveCurrentProgramStatusConsumerMappingConfig
);

export type EligibleEquipmentColumnConfig = typeof IncentiveEligibleEquipmentDefaultMappingConfig;

const getMappedIconForChargers = (
  config: typeof IncentiveEligibleEquipmentDefaultMappingConfig.config
) => {
  return getImageSrc(get(config, ['chargers', 'icon', 'src'])) || undefined;
};

export const EligibleEquipmentResultColumn: React.FC<
  Pick<IncentiveResultProps, 'col' | 'data'> & EligibleEquipmentColumnConfig
> = ({ col, data, config }) => {
  // @ts-ignore
  const vehicles = ((data[col] as string[]) || []).map((v) => v.toLowerCase());
  const key = vehicles.join(',');
  const chargerIcon = data?.incentive_focus
    ?.filter((f) => f === 'chargers')
    ?.map(() => getMappedIconForChargers(config));
  const icons = chargerIcon?.concat(
    uniq(
      config.vehicles
        .filter(({ keys }) =>
          keys.some((key) => {
            return vehicles.includes(key.toLowerCase());
          })
        )
        .map(({ icon: { src } }) => src)
    )
  );

  return (
    <Styled data-key={col}>
      <StyledIconsContainer>
        {icons?.map((src, i) => {
          return (
            <StyledEquipmentIconImage
              key={`${i}-${key}`}
              src={getImageSrc(src) || undefined}
              alt={key}
            />
          );
        })}
      </StyledIconsContainer>
    </Styled>
  );
};

export const EligibleEquipmentResultConsumerColumn: React.FC = ({
  col,
  data,
  config: { map },
}: any) => {
  // @ts-ignore
  const support = data[col] as string;
  const isCharger = support === 'Charging station' ? 'charger' : 'default';
  const { icon, key } = map[isCharger];

  return (
    <Styled data-key={col}>
      <StyledIconsContainer>
        <StyledEquipmentIconImage src={getImageSrc(icon.src) || undefined} alt={key} />
      </StyledIconsContainer>
    </Styled>
  );
};

export const IncentiveDescriptionResultColumn: React.FC<
  Pick<IncentiveResultProps, 'col' | 'data'> & CurrentProgramStatusColumnConfig
> = ({ col, data, config: { map } }) => {
  function underscoreToTitleCase(str: string) {
    return str
      ?.toLowerCase()
      ?.split('_')
      ?.map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
      ?.join(' ');
  }

  // @ts-ignore
  const desc = underscoreToTitleCase(data[col]) as keyof typeof map;

  const { i18n, key } = map[desc] || { i18n: { fallback: underscoreToTitleCase(desc) }, key: desc };

  return (
    <Styled data-key={key}>
      <StyledTdContent>
        <label>
          <Copy
            i18n={{
              id: 'i18n.incentives.labels.' + key,
              fallback: 'Incentive Label:',
            }}
          />
        </label>
        <StyledColWithDate>{map[desc] ? <TypeSetting i18n={i18n} /> : desc}</StyledColWithDate>
      </StyledTdContent>
    </Styled>
  );
};

export const EligibleEquipment = WithConfig(
  EligibleEquipmentResultColumn,
  'designs.incentives.results.table.is_eligible_to_specified_form_factors_only',
  IncentiveEligibleEquipmentDefaultMappingConfig
);

export const EligibleEquipmentConsumer = WithConfig(
  EligibleEquipmentResultConsumerColumn,
  'designs.incentives.results.table.is_eligible_to_specified_form_factors_only',
  IncentiveEligibleEquipmentConsumerMappingConfig
);

export const IncentiveDescription = WithConfig(
  IncentiveDescriptionResultColumn,
  'designs.incentives.results.table.incentive_nature',
  IncentiveDescriptionDefaultMappingConfig
);

export const IncentiveResultTd: React.FC<PropsWithChildren<IncentiveResultProps>> = ({
  children,
  col,
  startDate,
  expirationDate,
  data,
}) => {
  const { type } = useParams();

  const commercialResult = () => {
    switch (col) {
      case IncentiveDataKeys.current_program_status:
        return (
          <CurrentProgramStatus
            status={
              typeof children === 'string' ? children : CurrentProgramStatusValueResults['n/a']
            }
            startDate={startDate}
            expirationDate={expirationDate}
          />
        );

      case IncentiveDataKeys.is_eligible_to_specified_form_factors_only:
        return <EligibleEquipment col={col} data={data} />;

      case IncentiveDataKeys.incentive_nature:
        return <IncentiveDescription col={col} data={data} />;

      default:
        return <DefaultResultColumn col={col}>{children}</DefaultResultColumn>;
    }
  };

  const consumerResult = () => {
    switch (col) {
      case IncentiveDataKeys.available:
        return (
          <CurrentProgramStatusConsumer
            // @ts-ignore
            col={col}
            data={data}
          />
        );

      case IncentiveDataKeys.support_for:
        // @ts-ignore
        return <EligibleEquipmentConsumer col={col} data={data} />;

      case IncentiveDataKeys.typical_amount:
        return <ConsumerValueResultColumn col={col} data={data} />;

      case IncentiveDataKeys.amount_in_lease:
        return (
          <DefaultResultColumn col={col}>
            {data.support_for === 'Vehicle purchase'
              ? formatValues(data?.evaluation?.amount_in_lease, data)
              : extractValues(data?.typical_amount, data)}
          </DefaultResultColumn>
        );

      case IncentiveDataKeys.amount_in_purchase:
        return (
          <DefaultResultColumn col={col}>
            {data.support_for === 'Vehicle purchase'
              ? formatValues(data?.evaluation?.amount_in_purchase, data)
              : extractValues(data?.typical_amount, data)}
          </DefaultResultColumn>
        );

      default:
        return <DefaultResultColumn col={col}>{children}</DefaultResultColumn>;
    }
  };

  const result = type === 'commercial' ? commercialResult() : consumerResult();

  return result;
};

IncentiveResultTd.displayName = 'IncentiveResultTd';
