import styled, { css } from 'styled-components';
import { resolveStyles } from '../../../utils/styled/resolveStyles';
import { StyledTypography } from '../../atoms/TypeSetting/styled';

const fallback = css``;

export const Styled = styled.div`
  ${resolveStyles({ fallback })}
`;

const headerFallback = css`
  display: flex;
  align-items: center;
  width: 100%;

  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 0.875rem;
    margin-right: 2rem;
  }
`;

export const PrintTimeStamp = styled.div`
  display: none;

  position: fixed;
  top: 10px;
  left: 0;
  width: 100%;
  text-align: center;

  z-index: 1000;
`;

export const FooterLogoContainer = styled.div`
  width: 100%;
  justify-content: flex-end;
  display: flex;
  margin: 25px 0 0;
`;

export const IconContainer = styled.div`
  margin: 0 0 0 8px;

  color: gray;

  &:hover {
    cursor: pointer;
  }

  .printModal {
    justify-content: center;
    height: 100%;
    display: flex;
    align-items: center;

    form {
      padding: 20px;
    }

    #printTab {
      p {
        color: gray;
      }
    }
  }
`;

export const PrintTabs = styled.div`
  display: none;
`;

export const StyledHeader = styled.header`
  ${resolveStyles({ fallback: headerFallback })}

  @media(max-width:768px) {
    padding-bottom: 25px;
    flex-wrap: wrap;
  }
`;

export const StyledHeaderTitle = styled.header`
  @media (max-width: 768px) {
    order: 3;
  }
`;

const boxesRowFallback = css`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;

  @media screen and (min-width: 728px) {
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 2.625rem;
  }

  @media (max-width: 991px) {
    display: flex;
    flex-wrap: wrap;
  }

  @media print {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr !important;
    column-gap: 1.5rem;
  }
`;

interface styledBoxesInterface {
  isConsumer?: boolean;
}

export const StyledBoxesRow = styled.div<styledBoxesInterface>`
  ${resolveStyles({ fallback: boxesRowFallback })}
  ${(p) => p.isConsumer && `    grid-template-columns: 1fr 1fr 1fr 1fr !important;`}
`;

const topRowFallback = css`
  width: 100%;
  margin-top: 1rem;
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1rem;

  @media screen and (min-width: 728px) {
    margin-top: 3.875rem;
    row-gap: 1.25rem;
  }
`;

export const StyledTopRow = styled.div`
  ${resolveStyles({ fallback: topRowFallback })}
`;

const tabsFallback = css`
  width: 100%;
  margin-top: 2.5rem;
`;

export const StyledTabs = styled.div`
  ${resolveStyles({ fallback: tabsFallback })}
`;

const tabListFallback = css`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-around;
  margin-bottom: 1.875rem;
`;

export const StyledTabList = styled.menu`
  ${resolveStyles({ fallback: tabListFallback })}
`;

const tabFallback = css`
  flex: 1;
  border-bottom: 2px solid #dfe0eb;
  padding-bottom: 1rem;
  color: #777777;

  &[data-selected] {
    color: #007dbe;
    border-color: #007dbe;

    ${StyledTypography} {
      color: #007dbe;
    }
  }
`;

export const StyledTab = styled.button`
  ${resolveStyles({ fallback: tabFallback })}
`;

const headerIconsContainer = css`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

export const StyledHeaderIconsContainer = styled.div`
  ${resolveStyles({ fallback: headerIconsContainer })}
`;

export const ContainerBox = styled.div`
  border: 1px solid #dfe0eb;
  background: #fff;
  padding: 24px;
  width: 100%;
  height: auto;
  margin-top: 32px;
`;
