import React from 'react';
import { CopyProps } from '../Copy';
import { StyledLink, IconWrapper } from './styled';
import { TypeSetting } from '../TypeSetting';

export type LinkButtonProps = React.HTMLAttributes<HTMLAnchorElement> &
  CopyProps & { href: string; target?: string; rel?: string; icon?: string };

export const LinkButton: React.FC<LinkButtonProps> = ({ i18n, icon, ...props }) => {
  return (
    <StyledLink {...props}>
      <TypeSetting i18n={i18n} lineHeight={1} color="#ffffff" fontWeight="500" scale="3" />
      {icon && (
        <IconWrapper>
          <img src={icon} alt="GM Dashboard" />
        </IconWrapper>
      )}
    </StyledLink>
  );
};

LinkButton.displayName = 'LinkButton';
