import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';
import './index.css';
import './typography.css';
import './main.css';
import { theme } from './theme';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ConfigProvider, Designs } from './contexts';
import { TranslationsProvider } from './contexts/Translations/Translations';
import { translations as defaultTranslations } from './data/translations';
import { initI18n } from './hooks/useTranslationsResources';
import { resolveConfig } from './utils/config';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
initI18n();

ReactDOM.createRoot(document.getElementById('root') as Element).render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ConfigProvider
          product="incentives"
          getConfigs={() => Promise.resolve(resolveConfig() as unknown as Designs)}
        >
          <TranslationsProvider getResources={() => Promise.resolve(defaultTranslations)}>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </TranslationsProvider>
        </ConfigProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
