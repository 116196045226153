import {
  IncentiveData,
  IncentiveDataKeys,
} from '../../components/organisms/IncentivesResults/IncentivesResults.types';
import { isConsumerConfig } from '../config';

export const currentProgramStatusFilter = (data: IncentiveData[], value: string) => {
  const statuses = value.split(',');

  return data.filter((incentive) =>
    incentive?.current_program_status?.split(',').some((status) => statuses.includes(status))
  );
};

export const vehicleSegment = (data: IncentiveData[], value: string) => {
  const segments = value.split(',');

  return data.filter((incentive) => {
    const hasFormFactors = incentive.is_eligible_to_specified_form_factors_only.some((segment) =>
      segments.includes(segment)
    );

    if (segments.includes('other')) {
      return incentive.incentive_focus.every((f) => f === 'chargers') || hasFormFactors;
    }

    return hasFormFactors;
  });
};

export const incentiveFocus = (data: IncentiveData[], value: string) => {
  const focuses = value.split(',');

  return data.filter((incentive) => {
    return incentive.incentive_focus.some((f) => focuses.includes(f));
  });
};

export const incentivesFilters = {
  [IncentiveDataKeys.current_program_status]: currentProgramStatusFilter,
  [IncentiveDataKeys.is_eligible_to_specified_form_factors_only]: vehicleSegment,
  [IncentiveDataKeys.incentive_focus]: !isConsumerConfig && incentiveFocus,
};
