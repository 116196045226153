import { AuthOauth2GrantsConfig, useAuthOauth2GrantsConfig } from '../AuthOauth2Grants';

export function useFederatedUserAuthUrl() {
  const authConfig: AuthOauth2GrantsConfig | undefined = useAuthOauth2GrantsConfig();

  if (!authConfig) {
    throw new Error('AuthOauth2GrantsConfig has not been configured');
  }

  return (
    authConfig.cognitoUrl +
    '/oauth2/authorize?identity_provider=' +
    authConfig.identityProvider +
    '&redirect_uri=' +
    encodeURIComponent(authConfig.redirectUri) +
    '&response_type=' +
    authConfig.responseType +
    '&client_id=' +
    authConfig.clientId +
    '&scope=' +
    authConfig.scope
  );
}

export default useFederatedUserAuthUrl;
