import { FlattenSimpleInterpolation, FlattenInterpolation, StyledProps } from 'styled-components';

export function resolveStyles<T>(
  { fallback }: { fallback: FlattenSimpleInterpolation | FlattenInterpolation<T> },
  _?: (props: StyledProps<T>) => FlattenSimpleInterpolation | FlattenInterpolation<T>
): (_?: StyledProps<T>) => FlattenSimpleInterpolation | FlattenInterpolation<T> {
  /**
   * This function does nothing for now but return the default style
   * the idea is to have this as an interface to integrate with the lib
   * functions that get styles from the client configurations
   */
  return () => fallback;
}
