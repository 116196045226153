import React, { PropsWithChildren } from 'react';
import { StyledBox, StyledBoxHeader, StyledBoxBody } from './styled';
import { TypeSetting, TypeSettingProps } from '../../atoms/TypeSetting';
import { Typography, TypographyProps } from '../../atoms/Typography';

export type BoxProps = {
  slots?: {
    header?: JSX.Element;
  };
};

export const Box: React.FC<PropsWithChildren<BoxProps>> = ({ children, slots }) => {
  return (
    <StyledBox>
      {slots?.header && <StyledBoxHeader>{slots.header}</StyledBoxHeader>}
      {children}
    </StyledBox>
  );
};

Box.displayName = 'Box';

export const BoxBody = StyledBoxBody;

export const BoxTitle: React.FC<TypeSettingProps> = ({ i18n, ...props }) => (
  <TypeSetting
    element="h2"
    textTransform="uppercase"
    scale="2"
    color="#777777"
    i18n={i18n}
    {...props}
  />
);

BoxTitle.displayName = 'BoxTitle';

export const BoxHighlightedText: React.FC<TypographyProps> = ({ children, ...props }) => (
  <Typography scale="5" fontWeight="500" {...props}>
    {children}
  </Typography>
);

BoxHighlightedText.displayName = 'BoxHighlightedText';
