import styled from 'styled-components';

export const StyledOption = styled.option`
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0px;
  text-align: left;
  color: #1a1a1a;
`;
