export const DEFAULT_CONFIG = {
  config: {
    topRow: {
      slots: {
        highlight: {
          key: 'typical_amount',
          i18n: {
            id: 'i18n.incentive.typical_amount',
            fallback: 'Potential Value',
          },
        },
        boxes: [
          {
            key: 'available',
            components: undefined,
            i18n: {
              id: 'i18n.incentive.current_eligibility',
              fallback: 'Eligiblity',
            },
          },
          {
            key: 'grantor',
            components: undefined,
            i18n: {
              id: 'i18n.incentive.current_utility',
              fallback: 'Utility',
            },
          },
          {
            key: 'typical_amount',
            components: undefined,
            i18n: {
              id: 'i18n.incentive.potential_amount',
              fallback: 'Potential Amount',
            },
          },
          {
            key: 'point_of_sale',
            components: undefined,
            i18n: {
              id: 'i18n.incentive.current_applicability',
              fallback: 'Applicability',
            },
          },
        ],
      },
    },
    tabs: [
      {
        id: 'overview',
        i18n: {
          id: 'i18n.incentive.tab.consumer.overview',
          fallback: 'General Information',
        },
      },
      {
        id: 'eligibility',
        i18n: {
          id: 'i18n.incentive.tab.consumer.eligibility',
          fallback: 'Vehicle Eligibility',
        },
      },
    ],
  },
};
