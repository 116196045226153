import React from 'react';
import { IncentiveDetailsBoxProps } from '../IncentiveDetailsBox';
import { BoxBody, BoxHighlightedText } from '../../molecules/Box';

export type IncentiveDetailsBoxFundingCapProps = Pick<IncentiveDetailsBoxProps, 'data'>;

export const IncentiveDetailsBoxGrantor: React.FC<IncentiveDetailsBoxFundingCapProps> = ({
  data,
}) => {
  return (
    <BoxBody>
      {data && (
        <div>
          <BoxHighlightedText>{data?.grantor}</BoxHighlightedText>
        </div>
      )}
    </BoxBody>
  );
};

IncentiveDetailsBoxGrantor.displayName = 'IncentiveDetailsBoxGrantor';
