import {
  createContext,
  useContext,
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { QueryStatus } from 'react-query';
import { useNavigate, useLocation } from 'react-router-dom';
import { EN, GetResourcesFN, useTranslationsResources } from '../../hooks/useTranslationsResources';
import { locationSearchAsRecord, useExtendedConfig, useLocalStorageState } from '../../hooks';
import { LANGUAGES_DEFAULT_CONFIG } from '../../components/organisms/LanguageSelect';
import { LANGUAGES_CONFIG_KEY } from '../../constants';

const initialValue = {
  language: EN,
  changeLanguage: (_: string) => console.warn('Please implement `changeLanguage`'),
  status: 'idle' as QueryStatus,
  isIdle: true,
  isLoading: false,
  isError: false,
  isSuccess: false,
};

export const TranslationsContext = createContext(initialValue);

export interface TranslationsProviderProps {
  children: JSX.Element;
  getResources?: GetResourcesFN;
}

export const URL_QUERY_NAME = 'language';
const languageSearchParamRegex = new RegExp(`${URL_QUERY_NAME}=\\w+`);

export const TranslationsProvider: React.FC<TranslationsProviderProps> = ({
  children,
  getResources = () => Promise.resolve({}),
}) => {
  const location = useLocation();
  const previousLocation = useRef(location);
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  const { status, isIdle, isLoading, isError, isSuccess, error } =
    useTranslationsResources(getResources);
  const { config: languagesConfig } =
    useExtendedConfig<typeof LANGUAGES_DEFAULT_CONFIG.config>(LANGUAGES_CONFIG_KEY);
  const languagesOptions = useMemo(
    () => (languagesConfig?.config?.options || []).map(({ id }) => id),
    [languagesConfig]
  );
  const queryParams = locationSearchAsRecord();
  const languageInitialValue =
    queryParams[URL_QUERY_NAME] && languagesOptions.includes(queryParams[URL_QUERY_NAME])
      ? queryParams[URL_QUERY_NAME]
      : initialValue.language;
  const [localStorageLanguageState, setLocalStorageLanguageState] = useLocalStorageState(
    URL_QUERY_NAME,
    { [URL_QUERY_NAME]: languageInitialValue }
  );
  const [language, setLanguage] = useState(localStorageLanguageState[URL_QUERY_NAME]);
  const changeLanguage = useCallback(
    (lang: string) => {
      if (lang !== language) {
        setLanguage(lang);
      }
      if (lang !== i18n.language) {
        i18n.changeLanguage(lang);
      }
      if (lang !== localStorageLanguageState[URL_QUERY_NAME]) {
        setLocalStorageLanguageState({ [URL_QUERY_NAME]: lang });
      }

      navigate(
        window.location.pathname.concat(
          window.location.search.replace(languageSearchParamRegex, `${URL_QUERY_NAME}=${lang}`)
        ),
        { replace: true }
      );
    },
    [language, localStorageLanguageState, i18n, setLocalStorageLanguageState, navigate, setLanguage]
  );
  const value = { language, changeLanguage, status, isIdle, isLoading, isError, isSuccess, error };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (languagesOptions.length === 0) return;

      const queryParams = locationSearchAsRecord();
      const queryParamsLanguage = queryParams[URL_QUERY_NAME];

      if (
        localStorageLanguageState[URL_QUERY_NAME] === queryParamsLanguage &&
        localStorageLanguageState[URL_QUERY_NAME] === i18n.language
      ) {
        return;
      }

      /**
       * if the language in the URL query params is one of the language options
       * it takes precedence over the language defined in localStorage
       */
      const startupLanguageChoice =
        queryParamsLanguage && languagesOptions.includes(queryParamsLanguage)
          ? queryParamsLanguage
          : localStorageLanguageState[URL_QUERY_NAME];

      if (
        startupLanguageChoice === i18n.language &&
        startupLanguageChoice === language &&
        previousLocation.current === location
      )
        return;

      changeLanguage(startupLanguageChoice);
      clearTimeout(timeout);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [
    languagesOptions,
    changeLanguage,
    i18n.language,
    language,
    localStorageLanguageState,
    location,
  ]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (location.pathname === previousLocation.current.pathname) return;

      previousLocation.current = location;

      const queryParams = locationSearchAsRecord();
      const queryParamsLanguage = queryParams[URL_QUERY_NAME];

      if (localStorageLanguageState.language === queryParamsLanguage) return;

      /**
       * This forces the language set on localStorage to be reflected on the URL
       * as well thus causing the language change on the other `useEffect` when
       * navigating back after changing the language on the current page
       */
      navigate(
        window.location.pathname.concat(
          window.location.search.replace(
            languageSearchParamRegex,
            `${URL_QUERY_NAME}=${localStorageLanguageState[URL_QUERY_NAME]}`
          )
        ),
        { replace: true }
      );
      clearTimeout(timeout);
    }, 300);

    return () => {
      clearTimeout(timeout);
    };
  }, [location, localStorageLanguageState, navigate]);

  return <TranslationsContext.Provider value={value}>{children}</TranslationsContext.Provider>;
};

export function useTranslations() {
  return useContext(TranslationsContext);
}
