import { useState, useEffect } from 'react';
/* global HTMLOrSVGElement */
type ReturnProps = {
  hooveredState: string;
  statesCustomConfig: Record<string, any>;
};

type Incentives = {
  country: number;
  miscellaneous: number;
  Municipality: number;
  utility: number;
  state: number;
};

type MapDataItem = {
  score: number;
  state: string;
  state_name: string;
  number_of_incentives: Incentives;
};

type UseHeatMapConfigProps = {
  mapData: MapDataItem[];
  handleStateClick: (state: string, event: React.MouseEvent<HTMLOrSVGElement>) => void;
  country?: string;
};

interface CountryLimits {
  firstLimit: number;
  secondLimit: number;
}

const countryThresholds: Record<string, CountryLimits> = {
  US: { firstLimit: 17, secondLimit: 34 },
  CA: { firstLimit: 4, secondLimit: 8 },
};

const getStateColor = (i: number, country: string = 'US') => {
  const { firstLimit, secondLimit } = countryThresholds[country] || countryThresholds.US;

  if (i < firstLimit) return '#BFC4C7';
  if (i < secondLimit) return '#00B4D8';
  return '#005091';
};

const useHeatMapConfig = ({
  mapData,
  handleStateClick,
  country = 'US',
}: UseHeatMapConfigProps): ReturnProps => {
  const [hooveredState, setHooveredState] = useState('');
  const [statesCustomConfig, setStatesCustomConfig] = useState({});

  useEffect(() => {
    if (!mapData.length) return;

    let _statesCustomConfig = {};

    mapData.forEach((stateObj, i) => {
      // @ts-ignore
      _statesCustomConfig[stateObj.state] = {
        fill: getStateColor(i, country),

        clickHandler: (event: React.MouseEvent<HTMLOrSVGElement>) => {
          // @ts-ignore
          const stateName = event.target.dataset.name;
          handleStateClick(stateName, event);
        },
        mouseEnter: (event: React.MouseEvent<HTMLOrSVGElement>) => {
          // @ts-ignore
          setHooveredState(event.target.dataset.name);
        },
        mouseLeave: (event: React.MouseEvent<HTMLOrSVGElement>) => {
          setHooveredState('');
        },
      };
    });

    if (JSON.stringify(_statesCustomConfig) !== JSON.stringify(statesCustomConfig)) {
      setStatesCustomConfig(_statesCustomConfig);
    }
  }, [mapData, handleStateClick]);

  return {
    hooveredState,
    statesCustomConfig,
  };
};

export default useHeatMapConfig;
