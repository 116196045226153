import React, { PropsWithChildren } from 'react';
import { Styled } from './styled';

export type ContentWrapperOwnProps = { flex?: boolean };

export const ContentWrapper: React.FC<PropsWithChildren<ContentWrapperOwnProps>> = ({
  children,
  flex = true,
}) => {
  return <Styled flex={flex}>{children}</Styled>;
};

ContentWrapper.displayName = 'ContentWrapper';
