import styled, { css } from 'styled-components';
import { StyledTypography } from '../TypeSetting/styled';
import { resolveStyles } from '../../../utils/styled/resolveStyles';

const fallback = css`
  ${StyledTypography} {
    font-size: 1.5rem;
    font-weight: 500;
  }
`;

export const Styled = styled.div`
  ${resolveStyles({ fallback })}
`;
