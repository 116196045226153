import isEmpty from 'lodash.isempty';
import isString from 'lodash.isstring';
import omit from 'lodash.omit';
import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Api } from '../../services/api';
import { useSecret, Secrets } from '../useSecret';
import { DEFAULT_RESPONSE_TRANSFORMATIONS } from '../../utils/api/transformations';
import {
  SingleIncentiveData,
  IncentiveDataKey,
  isValidData,
} from '../../utils/api/transformations';
import { IncentiveDetails, IncentiveList, VehiclesListItem } from '../../types';

export const transformationsReducer = (
  acc: unknown,
  fn: (x: { [key: string]: SingleIncentiveData[IncentiveDataKey] }) => {
    [key: string]: SingleIncentiveData[IncentiveDataKey];
  }
) => {
  return isValidData(acc) ? fn(acc) : acc;
};

export type ResponseData = {
  incentive?: IncentiveDetails;
  incentives?: IncentiveList;
  vehicles?: VehiclesListItem;
};

export const select =
  (transformationFns: typeof DEFAULT_RESPONSE_TRANSFORMATIONS) =>
  (data: AxiosResponse<ResponseData, unknown> | undefined) => {
    if (isEmpty(data) || isEmpty(data?.data)) return data;

    const response = omit(data?.data, ['code', 'message']);

    if (response?.incentive) {
      const incentive = transformationFns.reduce(
        (
          acc: unknown,
          fn: (x: unknown) =>
            | {
                [key: string]: SingleIncentiveData[IncentiveDataKey];
              }
            | unknown
        ) => {
          return isValidData(acc) ? fn(acc) : acc;
        },
        response?.incentive
      );

      return {
        data: { incentive },
      };
    }

    if (response?.incentives) {
      const incentives = (response?.incentives || [])?.map((incentive: unknown) => {
        return transformationFns.reduce(
          (
            acc: unknown,
            fn: (x: unknown) =>
              | {
                  [key: string]: SingleIncentiveData[IncentiveDataKey];
                }
              | unknown
          ) => {
            return isValidData(acc) ? fn(acc) : acc;
          },
          incentive
        );
      });

      return {
        data: { incentives },
      };
    }

    if (response?.vehicles) {
      const vehicleData = response.vehicles;
      return {
        data: vehicleData as any,
      };
    }

    return { data: response };
  };

export function useUnifiedAPI(
  path: string,
  params: Array<{ name: string; value: string }> = [],
  { runDataTransformations }: { runDataTransformations: boolean } = {
    runDataTransformations: false,
  },
  { enabled }: { enabled: boolean } = { enabled: false },
  transformationFns = DEFAULT_RESPONSE_TRANSFORMATIONS
) {
  const { data: baseURL, isLoading: isLoadingBaseURL } = useSecret(Secrets.UNIFIED_API_URL);
  const { data: authorization, isLoading: isLoadingToken } = useSecret(Secrets.UNIFIED_API_TOKEN);
  const [api, setApi] = useState<ReturnType<typeof Api.getInstance> | undefined>(undefined);
  const fetchFunc = useCallback(
    () => (api ? api.get(path, params) : undefined),
    [api, path, params]
  );
  const { data, isLoading, ...queryProps } = useQuery(
    path.concat(JSON.stringify(params)),
    fetchFunc,
    {
      enabled: api !== undefined && typeof api?.get === 'function' && !isEmpty(path) && enabled,
      select: runDataTransformations ? select(transformationFns) : (x) => x,
    }
  );

  useEffect(() => {
    if (
      !isString(baseURL) ||
      isEmpty(baseURL) ||
      !isString(authorization) ||
      isEmpty(authorization)
    ) {
      return;
    }

    setApi(Api.getInstance(baseURL, authorization));
  }, [baseURL, authorization]);

  return { isLoading: isLoading || isLoadingBaseURL || isLoadingToken, data, ...queryProps };
}
