import { useEffect, useLayoutEffect, useState } from 'react';
import merge from 'lodash.merge';
import { useConfig } from '../useConfig';
import { getConfig } from '../../contexts';
import { BaseDesignConfig, Design } from '../../contexts';
import { LEASE, PURCHASE, CONSUMER } from '../../constants/utils';
import consumerTableLease from '../../config/consumerTableLease.json';
import consumerTablePurchase from '../../config/consumerTablePurchase.json';
import defaultConsumer from '../../config/defaultConsumer.json';
import { useParams } from 'react-router-dom';

export function useExtendedConfig<C extends BaseDesignConfig = BaseDesignConfig>(
  key: string,
  state?: any
) {
  const { configs, config: design, ...queryProps } = useConfig(key);
  const [config, setConfig] = useState(design);
  const { type } = useParams();
  useEffect(() => {
    if (
      key === 'designs.incentives.results.table' &&
      state?.selectedVehicle?.handle &&
      type === CONSUMER
    ) {
      if (state?.consumerTableView === LEASE) {
        const leaseColumns = getConfig(consumerTableLease)('designs.incentives.results.table');

        setConfig((prevConfig) => ({
          ...prevConfig,
          config: {
            ...prevConfig.config,
            columns: leaseColumns?.config?.columns,
          },
        }));
      } else if (state?.consumerTableView === PURCHASE) {
        const purchaseColumns = getConfig(consumerTablePurchase)(
          'designs.incentives.results.table'
        );

        setConfig((prevConfig) => ({
          ...prevConfig,
          config: {
            ...prevConfig.config,
            columns: purchaseColumns?.config?.columns,
          },
        }));
      } else {
        // @ts-ignore
        const defaultColumns = getConfig(defaultConsumer)('designs.incentives.results.table');
        setConfig((prevConfig) => ({
          ...prevConfig,
          config: {
            ...prevConfig.config,
            columns: defaultColumns?.config?.columns,
          },
        }));
      }
    }
  }, [state?.consumerTableView, state?.selectedVehicle]);

  useLayoutEffect(() => {
    const extensionKey = design?._extends;
    const extensionConfig = extensionKey ? getConfig(configs)(extensionKey) : {};

    setConfig(extensionKey ? merge({}, design, extensionConfig) : (design as Design<C>));
  }, [key, design, configs]);

  return { configs, config: config as Design<C>, ...queryProps };
}
