import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { resolveStyles } from '../../../utils/styled/resolveStyles';
import { StyledColorMarker } from '../../atoms/ColorMarker/styled';

const fallback = css`
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 0.875rem;
  z-index: 1;
`;

export const StyledTooltip = styled.div`
  ${resolveStyles({ fallback })}
`;

const buttonFallback = css`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 1rem;
  height: 0.875rem;
`;

export const StyledButton = styled.button`
  z-index: 1;
  ${resolveStyles({ fallback: buttonFallback })};
`;

const fallbackWidth = 20.875;

const modalFallback = css`
  margin-top: 1.25rem;
  top: 1.25rem;
  left: 0;
  width: ${fallbackWidth}rem;
  background: #333333;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  padding: 1.25rem 1.375rem;
  color: #fff;

  h2 {
    color: #fff;
  }
`;

export const StyledModal = styled(motion.aside)`
  position: absolute;
  z-index: 1;

  ${resolveStyles({ fallback: modalFallback })}
`;

const tooltipContentItemContainerFallback = css`
  & + & {
    margin-top: 1.25rem;
  }
`;

export const StyledTooltipContentItemContainer = styled.div`
  ${resolveStyles({ fallback: tooltipContentItemContainerFallback })}
`;

const tooltipTitleContainerFallback = css`
  display: flex;
  align-items: center;

  ${StyledColorMarker} {
    margin-right: 0.25rem;
  }
`;

export const StyledTooltipTitleContainer = styled.div`
  ${resolveStyles({ fallback: tooltipTitleContainerFallback })}
`;

const paragraphsContainerFallback = css`
  display: flex;
  align-items: center;
`;

export const StyledParagraphsContainer = styled.div`
  ${resolveStyles({ fallback: paragraphsContainerFallback })}
`;
