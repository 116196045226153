import React, { PropsWithChildren } from 'react';
import { Styled, StyledChildrenContainer, StyledLabelTooltipContainer } from './styled';
import { Label, LabelProps } from '../../atoms/Label';
import { useBoundingClientRect } from '../../../hooks';

export type LabeledInputOwnProps = LabelProps & {
  components?: {
    tooltip?: (xPosition: number) => React.ReactElement | undefined;
  };
};
export type LabeledInputProps = LabeledInputOwnProps & { required?: boolean };

export const DEFAULT_STYLED_TOOLTIP_MARGIN = 8;

export const LabeledInput: React.FC<PropsWithChildren<LabeledInputProps>> = ({
  children,
  i18n,
  htmlFor,
  components,
  required,
}) => {
  const { ref, rect } = useBoundingClientRect();

  return (
    <Styled>
      <StyledLabelTooltipContainer>
        <Label htmlFor={htmlFor} i18n={i18n} ref={ref} />
        {required && <span style={{ color: '#CC3232' }}>*</span>}
        {components?.tooltip && (
          <>{components.tooltip(-Math.ceil(rect.width + DEFAULT_STYLED_TOOLTIP_MARGIN))}</>
        )}
      </StyledLabelTooltipContainer>
      <StyledChildrenContainer>{children}</StyledChildrenContainer>
    </Styled>
  );
};

LabeledInput.displayName = 'LabeledInput';
