import React, { forwardRef, ForwardRefExoticComponent, RefAttributes } from 'react';
import { ScalePosition } from '../../../interfaces/TypographyScale';
import { TypographyStylingProps } from '../../../interfaces/TypographyStylingProps';
import { Styled } from './styled';

export const TEXT_HTML_ELEMENTS = {
  a: 'a',
  abbr: 'abbr',
  address: 'address',
  article: 'article',
  aside: 'aside',
  b: 'b',
  bdi: 'bdi',
  bdo: 'bdo',
  blockquote: 'blockquote',
  button: 'button',
  caption: 'caption',
  cite: 'cite',
  code: 'code',
  dd: 'dd',
  del: 'del',
  details: 'details',
  dfn: 'dfn',
  dt: 'dt',
  em: 'em',
  figcaption: 'figcaption',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  i: 'i',
  ins: 'ins',
  kbd: 'kbd',
  label: 'label',
  legend: 'legend',
  li: 'li',
  mark: 'mark',
  p: 'p',
  pre: 'pre',
  q: 'q',
  s: 's',
  samp: 'samp',
  small: 'small',
  span: 'span',
  strong: 'strong',
  sub: 'sub',
  summary: 'summary',
  sup: 'sup',
  td: 'td',
  th: 'th',
  time: 'time',
  u: 'u',
  var: 'var',
} as const;

export type ValidHTMLElements = keyof typeof TEXT_HTML_ELEMENTS;
export type ValidJSXIntrinsicElements = Pick<JSX.IntrinsicElements, ValidHTMLElements>;

export interface TypographyProps
  extends TypographyStylingProps,
    React.HTMLAttributes<ValidJSXIntrinsicElements> {
  element?: keyof ValidJSXIntrinsicElements;
  scale?: string | ScalePosition;
  variant?: string;
}

export const HTML_ELEMENTS_LIST = Object.keys(TEXT_HTML_ELEMENTS);
const HTML_ELEMENTS_LIST_AS_TAGS = HTML_ELEMENTS_LIST.map((x) => `<${x}>`);

const getInvalidElementValueErrorMessage = (element: string) =>
  `Invalid HTML element value <${element}> for the Typography component.
      Only these elements ${HTML_ELEMENTS_LIST_AS_TAGS.join(
        ', '
      )} can be used with the Typography component.`;

export const Typography: ForwardRefExoticComponent<TypographyProps & RefAttributes<unknown>> =
  forwardRef(({ element = 'span', scale = '1', variant = 's1', children, ...props }, ref) => {
    if (element === undefined || !TEXT_HTML_ELEMENTS[element]) {
      throw new Error(getInvalidElementValueErrorMessage(String(element)));
    }

    return (
      <Styled element={element} variant={variant} scale={scale} ref={ref} {...props}>
        {children}
      </Styled>
    );
  });

Typography.displayName = 'Typography';
