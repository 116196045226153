import React, { useState, ReactNode, ReactElement } from 'react';
import { CopyProps } from '../Copy';
import { SelectOptionProps } from '../SelectOption';
import {
  SelectContainer,
  StyledSelect,
  SelectedValueTypeSettingContainer,
  SelectedValueTypeSetting,
} from './styled';
import { Icon } from '../Icon';
import { Designable } from '../../../types';
import chevronDown from '../../../assets/icons/chevron-down.svg';

export type SelectOwnProps = Partial<
  Pick<HTMLSelectElement, 'name' | 'id' | 'autofocus' | 'required' | 'disabled'>
> & {
  defaultValue?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  size?: string;
} & CopyProps;
export type SelectProps = React.PropsWithChildren<Designable<SelectOwnProps>>;

export type OptionsCopyMap = Record<string, Pick<CopyProps, 'i18n'>>;

export function isChildWithProps(x: ReactNode): x is ReactElement {
  return React.isValidElement(x);
}

export function isValidChild(x: ReactNode): boolean {
  return isChildWithProps(x) && x?.props?.i18n?.id && x?.props?.i18n?.fallback;
}

export const validateChildren = (children: React.ReactNode) =>
  React.Children.forEach(children, (child) => {
    if (!isValidChild(child))
      throw new Error(
        'The `<Select>` component children must conform to the `CopyProps` interface.'
      );
  });

export const Select: React.FC<SelectProps> = ({
  children,
  onChange,
  defaultValue,
  i18n,
  size,
  ...htmlSelectElementProps
}) => {
  validateChildren(children);
  const [selectedValue, setSelectedValue] = useState<string | undefined>(defaultValue);
  const isMobile = window.matchMedia('(max-width: 768px)').matches;

  const optionsCopyMap = React.Children.toArray(children).reduce((optionsCopyMap, c) => {
    const child = c as unknown as ReactElement<SelectOptionProps>;
    optionsCopyMap[child.props.value] = { i18n: child.props.i18n };

    return optionsCopyMap;
  }, {} as OptionsCopyMap);

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (typeof onChange === 'function') onChange(e);

    setSelectedValue(e.target.value);
  };

  const selectedValueTypeSettingI18n = selectedValue
    ? optionsCopyMap[selectedValue]?.i18n
      ? optionsCopyMap[selectedValue]?.i18n
      : i18n
    : i18n;

  return (
    <SelectContainer data-component="Select" size={size} isMobile={isMobile}>
      <SelectedValueTypeSettingContainer>
        <SelectedValueTypeSetting i18n={selectedValueTypeSettingI18n} />
        <Icon src={chevronDown} />
      </SelectedValueTypeSettingContainer>
      <StyledSelect
        value={selectedValue}
        onChange={handleChange}
        {...htmlSelectElementProps}
        isMobile={isMobile}
      >
        {children}
      </StyledSelect>
    </SelectContainer>
  );
};

Select.displayName = 'Select';
