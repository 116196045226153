import styled from 'styled-components';

export const Title = styled.h3`
  margin: 25px auto 26px;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;
  text-align: center;

  color: #777777;
`;

export const TableHeader = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 3fr 1fr;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 13px;
  text-transform: uppercase;

  color: #777777;

  border-bottom: 1px solid #dfe0eb;

  padding-bottom: 15px;

  p {
    margin: 0;
  }
`;

export const TableRow = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 1fr 3fr 1fr;

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 15px;

  color: #000000;

  border-bottom: 1px solid #dfe0eb;

  padding: 23px 0;

  p {
    margin: 0;
  }

  span {
    display: none;
  }

  @media screen and (max-width: 767px) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    padding: 12px 0;

    p {
      margin: 4px 0;
    }

    span {
      margin: 0;
      display: initial;
    }
  }
`;
