import styled, { css } from 'styled-components';
import { resolveStyles } from '../../../utils/styled/resolveStyles';
import { StyledTableContainerProps } from './Table.types';

const fallback = css<StyledTableContainerProps>`
  background-color: #fff;
  border: 1px solid #dfe0eb;
  padding: 2.5rem 2.5rem 2rem 2.5rem;

  table {
    width: 100%;
  }

  thead,
  tr {
    border-bottom: 1px solid #dfe0eb;
  }

  tr:last-of-type {
    border-bottom: 0;
  }

  tr td {
    padding: 1.5rem 1rem;

    &:first-child {
      padding-left: 0;
    }
  }

  thead th {
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;

    &:first-child {
      padding-left: 0;
    }
  }

  @media screen and (max-width: 767px) {
    padding: 1rem;

    thead {
      display: none;
    }

    table tr {
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 0.5rem;
      row-gap: 0.5rem;

      @media (max-width: 991px) {
        display: flex;
        flex-wrap: wrap;
      }
    }

    tr td {
      padding: 0;
    }

    tr {
      padding-bottom: 0.625rem;
    }

    tr + tr {
      padding: 0.625rem 0;
    }

    td[colspan='4'] {
      grid-column: 1 / span 2;
    }
  }

  ${({ dataStatus }) =>
    dataStatus !== 'success' &&
    css`
      tr {
        border-bottom: 0;
      }
    `}
`;

export const StyledTableContainer = styled.div<StyledTableContainerProps>`
  ${resolveStyles({ fallback })}
  width: 100%;
`;

export const StyledTable = styled.table``;
