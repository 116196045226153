import { IncentiveDataKeys } from '../../organisms/IncentivesResults/IncentivesResults.types';

export const DEFAULT_COLUMNS = [
  {
    key: IncentiveDataKeys.current_program_status,
    i18n: { id: `i18n.incentives.${IncentiveDataKeys.current_program_status}`, fallback: 'Status' },
  },
  {
    key: IncentiveDataKeys.incentive_name,
    i18n: { id: `i18n.incentives.${IncentiveDataKeys.incentive_name}`, fallback: 'Name' },
  },
  {
    key: IncentiveDataKeys.typical_amount,
    i18n: {
      id: `i18n.incentives.${IncentiveDataKeys.typical_amount}`,
      fallback: 'Potential Value',
    },
  },
  {
    key: IncentiveDataKeys.grantor_name,
    i18n: { id: `i18n.incentives.${IncentiveDataKeys.grantor_name}`, fallback: 'Grantor' },
  },
  {
    key: IncentiveDataKeys.incentive_nature,
    i18n: { id: `i18n.incentives.${IncentiveDataKeys.incentive_nature}`, fallback: 'Description' },
  },
  {
    key: IncentiveDataKeys.is_eligible_to_specified_form_factors_only,
    i18n: {
      id: `i18n.incentives.${IncentiveDataKeys.is_eligible_to_specified_form_factors_only}`,
      fallback: 'Equipment',
    },
  },
];

export const DEFAULT_CONFIG = {
  config: {
    pagination: {
      itemsPerPage: 20,
      controls: {
        preposition: {
          i18n: {
            id: 'i18n.pagination.controls.count.preposition',
            fallback: 'of',
          },
        },
        previous: {
          i18n: {
            id: 'i18n.pagination.controls.previous',
            fallback: 'Prev',
          },
        },
        next: {
          i18n: {
            id: 'i18n.pagination.controls.next',
            fallback: 'Next',
          },
        },
      },
    },
    states: {
      idle: {
        i18n: {
          id: 'i18n.results.idle',
          fallback: 'Search for incentives using the filters above.',
        },
      },
      empty: {
        i18n: {
          id: 'i18n.results.empty',
          fallback: 'No state or local incentives apply to this zip code',
        },
      },
      error: {
        i18n: {
          id: 'i18n.results.error',
          fallback: 'Something went wrong.',
        },
      },
      success: {
        i18n: {
          id: 'i18n.results.success.count',
          fallback: '{{ count }} Incentives Found.',
        },
        messages: [
          {
            i18n: {
              id: 'i18n.results.success.count_one',
              fallback: '{{ count }} Incentive Found',
            },
          },
          {
            i18n: {
              id: 'i18n.results.success.count_other',
              fallback: '{{ count }} Incentives Found',
            },
          },
        ],
      },
    },
    columns: DEFAULT_COLUMNS,
  },
};

export const IncentiveCurrentProgramStatusDefaultMappingConfig = {
  config: {
    map: {
      current: {
        withColorMarker: 'designs.color-marker.open',
        key: 'open',
        i18n: {
          id: 'i18n.incentives.results.current_program_status.current',
          fallback: 'Open',
        },
      },
      hiatus: {
        withColorMarker: 'designs.color-marker.hiatus',
        key: 'hiatus',
        i18n: {
          id: 'i18n.incentives.results.current_program_status.hiatus',
          fallback: 'Hiatus',
        },
      },
      upcoming: {
        withColorMarker: 'designs.color-marker.hiatus',
        key: 'hiatus',
        i18n: {
          id: 'i18n.incentives.results.current_program_status.hiatus',
          fallback: 'Hiatus',
        },
      },
      waitlist: {
        withColorMarker: 'designs.color-marker.waitlist',
        key: 'waitlist',
        i18n: {
          id: 'i18n.incentives.results.current_program_status.waitlist',
          fallback: 'Waitlist',
        },
      },
      expired: {
        withColorMarker: 'designs.color-marker.closed',
        key: 'closed',
        i18n: {
          id: 'i18n.incentives.results.current_program_status.closed',
          fallback: 'Closed',
        },
      },
      suspended: {
        withColorMarker: 'designs.color-marker.closed',
        key: 'closed',
        i18n: {
          id: 'i18n.incentives.results.current_program_status.closed',
          fallback: 'Closed',
        },
      },
      'n/a': {
        withColorMarker: 'designs.color-marker.closed',
        key: 'closed',
        i18n: {
          id: 'i18n.incentives.results.current_program_status.closed',
          fallback: 'Closed',
        },
      },
    },
  },
};

export const IncentiveCurrentProgramStatusConsumerMappingConfig = {
  config: {
    map: {
      open: {
        withColorMarker: 'designs.color-marker.open',
        key: 'open',
        i18n: {
          id: 'i18n.incentives.status.consumer.open',
          fallback: 'Potentially Eligible',
        },
      },
      closed: {
        withColorMarker: 'designs.color-marker.closed',
        key: 'closed',
        i18n: {
          id: 'i18n.incentives.status.consumer.closed',
          fallback: 'Potentially Ineligible',
        },
      },
    },
  },
};

export const IncentiveDescriptionDefaultMappingConfig = {
  config: {
    map: {
      grant: {
        key: 'grant',
        i18n: {
          id: 'i18n.incentives.incentive_nature.grant',
          fallback: 'Grant',
        },
      },
      tax_credit: {
        key: 'tax_credit',
        i18n: {
          id: 'i18n.incentives.incentive_nature.tax_credit',
          fallback: 'Tax credit',
        },
      },
      tax_exemption: {
        key: 'tax_exemption',
        i18n: {
          id: 'i18n.incentives.incentive_nature.tax_exemption',
          fallback: 'Tax exemption',
        },
      },
      rebate: {
        key: 'rebate',
        i18n: {
          id: 'i18n.incentives.incentive_nature.rebate',
          fallback: 'Rebate',
        },
      },
      voucher: {
        key: 'voucher',
        i18n: {
          id: 'i18n.incentives.incentive_nature.voucher',
          fallback: 'Voucher',
        },
      },
      financing: {
        key: 'financing',
        i18n: {
          id: 'i18n.incentives.incentive_nature.financing',
          fallback: 'Financing',
        },
      },
    },
  },
};

export const IncentiveEligibleEquipmentDefaultMappingConfig = {
  config: {
    chargers: {
      icon: {
        src: '$public/icons/charger.svg',
      },
    },
    vehicles: [
      {
        keys: [
          'agricultural_vehicle',
          'airport_vehicle',
          'bev_drive_system',
          'box_truck',
          'cargo_handling_equipment',
          'cargo_van',
          'concrete_mixer',
          'dozer',
          'drayage_truck',
          'dump_truck',
          'excavator',
          'expedition_vehicle',
          'flatbed_truck',
          'forklift',
          'ground_support_equipment',
          'loader',
          'Minivan',
          'motor_coach',
          'panel_van',
          'paratransit',
          'phev_drive_system',
          'pickup_truck',
          'refuse_truck',
          'school_bus',
          'scraper',
          'shuttle_bus',
          'specialty',
          'station_wagon',
          'step_van',
          'straight_truck',
          'terminal_tractor',
          'trailer_truck',
          'transit_bus',
          'tru',
          'utility_truck',
        ],
        icon: { src: '$public/icons/truck.svg' },
      },
      {
        keys: ['coupe', 'cutaway_chassis', 'Hatchback', 'Sedan', 'SUV', 'utility_vehicle', 'other'],
        icon: { src: '$public/icons/car.svg' },
      },
    ],
  },
};

export const IncentiveEligibleEquipmentConsumerMappingConfig = {
  config: {
    map: {
      charger: {
        key: 'charger',
        icon: { src: '$public/icons/charger.svg' },
      },
      default: {
        key: 'vehicle',
        icon: { src: '$public/icons/car.svg' },
      },
    },
  },
};
