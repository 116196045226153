import styled, { css } from 'styled-components';
import { resolveStyles } from '../../../utils/styled/resolveStyles';
import { StyledIcon } from '../../atoms/Icon/styled';
import { StyledTooltip } from '../Tooltip/styled';

const fallback = css`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;

  ${StyledIcon} {
    margin-left: 0.5rem;
  }

  ${StyledTooltip} {
    position: relative;
    top: -3px;
  }
`;

export const Styled = styled.div`
  ${resolveStyles({ fallback })}
`;

const flexEndFallback = css`
  align-self: flex-end;
  flex-grow: 1;
  text-align: right;
`;

export const StyledFlexEndElement = styled.div`
  ${resolveStyles({ fallback: flexEndFallback })}
`;

export const GrantorBoxTitle = styled.h2`
  color: rgb(119, 119, 119);
  font-family: Overpass, sans-serif;
  font-size: 12px;
  margin-right: auto;
`;
