import styled from 'styled-components';

export const Styled = styled.div``;

export const StyledHeaderIconsContainer = styled.div`
  text-align: right;
  margin: 0 0 25px;
`;

export const IconContainer = styled.div`
  margin: 0 0 0 8px;

  color: gray;

  &:hover {
    cursor: pointer;
  }

  .printModal {
    justify-content: center;
    height: 100%;
    display: flex;
    align-items: center;

    form {
      padding: 20px;
    }

    #printTab {
      p {
        color: gray;
      }
    }
  }
`;

export const PrintTimeStamp = styled.div`
  display: none;

  position: fixed;
  top: 10px;
  left: 0;
  width: 100%;
  text-align: center;

  z-index: 1000;
`;

export const FooterLogoContainer = styled.div`
  width: 100%;
  justify-content: flex-end;
  display: flex;
  margin: 25px 0 0;
`;

export const LineWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Line = styled.div`
  width: 91.5%;
  height: 1px;
  background-color: #dfe0eb;
  margin: 32px 0;
`;

export const IncentiveContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 25px;
  }
`;

export const WarningContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SmallWarningContainer = styled.div`
  max-width: 30%;
  min-width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SmallWarningWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const SmallWarningDialog = styled.div`
  width: 100%;
  height: 84px;
  background-color: #e0f2fc;
  margin: 32px 0;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 26px;

  .subtitle {
    font-family: Overpass;
    font-size: 24px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
  }

  .title {
    font-family: Overpass;
    font-size: 14px;
    font-weight: 500;
    line-height: 14px;
    letter-spacing: 0em;
    text-align: left;
    color: #777777;
  }

  .column {
    margin: 0 0 0 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
`;

export const WarningDialog = styled.div`
  width: 91.5%;
  height: 64px;
  background-color: #e0f2fc;
  margin: 32px 0;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 26px;

  p {
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    margin-left: 26px;
  }
`;
